<template>
<div style="width:100%; height:100%; min-height:1000px; text-align: center; margin-top:2%">
    <CircularChart ></CircularChart>
    <CircularChartAnticipi ></CircularChartAnticipi>
    <CircularChartSuper ></CircularChartSuper>
</div>

</template>

<script>
import CircularChart from "@/components/CircularChart.vue";
import CircularChartAnticipi from "@/components/CircularChartAnticipi.vue";
import CircularChartSuper from "@/components/CircularChartSuper.vue";

export default ({
    components: {
    CircularChart,
    CircularChartAnticipi,
    CircularChartSuper
},
})
</script>
<style >
.btnBackStyle{
    background-color: transparent !important;
    margin: 30px 10px 20px 10px;
}
</style>