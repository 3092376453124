var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "backdrop",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "modalDoc" },
        [
          _c("h4", [
            _vm._v(
              "Nome Documento: " +
                _vm._s(_vm.nomeFileOriginale) +
                " ID Documento: " +
                _vm._s(_vm.idDoc)
            ),
          ]),
          _vm.isPdf
            ? _c("embed", {
                attrs: {
                  src: _vm.url,
                  type: "application/pdf",
                  width: "100%",
                  height: "100%",
                  download: _vm.fileName,
                },
              })
            : _vm._e(),
          _vm.isHTML
            ? _c("div", {
                staticStyle: { overflow: "scroll" },
                domProps: { innerHTML: _vm._s(_vm.myTable) },
              })
            : _vm._e(),
          _vm.isText
            ? _c("v-textarea", {
                attrs: { "auto-grow": "", color: "teal" },
                model: {
                  value: _vm.myText,
                  callback: function ($$v) {
                    _vm.myText = $$v
                  },
                  expression: "myText",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }