var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("AevoSnackbar"),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "" },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "v-container",
            {
              staticStyle: { "background-color": "rgba(255, 255, 255, 0.5)" },
              attrs: { fluid: "", "fill-height": "" },
            },
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "align-center": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Topbar", { attrs: { title: "Home" } }),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }