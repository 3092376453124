// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/img-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n#app{\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.CellWithAevoTooltip{\n  position:relative;\n}\n.AevoTooltip{\n  display:none;\n  position:absolute; \n  z-index:1000;\n  border:1px;\n  background-color:white; \n  border-style:solid;\n  border-width:1px;\n  padding:3px;\n  top:20px; \n  left:20px;\n  width: 30em;\n}\n.CellWithAevoTooltip:hover span.AevoTooltip{\n  display:block;\n  word-wrap: break-word;\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
