var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "box",
          on: {
            drop: function ($event) {
              return _vm.onDrop($event)
            },
            dragenter: function ($event) {
              $event.preventDefault()
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
          },
        },
        _vm._l(_vm.movimenti, function (movimento, index) {
          return _c("div", { key: movimento.artificial_id_movimento }, [
            _c(
              "div",
              {
                class: _vm.idSelected === index ? "cardselected" : "card",
                attrs: {
                  draggable: "true",
                  id: movimento.artificial_id_movimento,
                },
                on: {
                  click: function ($event) {
                    return _vm.populateDataT(movimento)
                  },
                  dragstart: function ($event) {
                    return _vm.dragStart($event, movimento)
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tooltip",
                      staticStyle: {
                        "padding-top": "10px",
                        "text-align": "center",
                        "min-height": "40px",
                        width: "100%",
                      },
                    },
                    [
                      _c("h5", [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatTruncate")(
                                movimento.ragionesociale,
                                10,
                                "..."
                              )
                            )
                          ),
                        ]),
                      ]),
                      movimento.ragionesociale
                        ? _c("span", { staticClass: "tooltiptext" }, [
                            _vm._v(_vm._s(movimento.ragionesociale)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "min-height": "33px",
                      },
                    },
                    [
                      _c("div", { staticClass: "importoStyle" }, [
                        _c("p", { staticClass: "dataStyle" }, [
                          _vm._v(_vm._s(movimento.valuta_importo)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "min-height": "20px",
                      },
                    },
                    [
                      _c("p", { staticClass: "dataStyle" }, [
                        _vm._v(
                          "Data: " +
                            _vm._s(
                              _vm._f("formatDateShort")(
                                movimento.dataoperazione
                              )
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "min-height": "20px",
                      },
                    },
                    [
                      _c("p", { staticClass: "dataStyle" }, [
                        _vm._v("Valuta: " + _vm._s(movimento.valuta)),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
      _c("p", { staticClass: "countStyle" }, [
        _vm._v(
          " Selezionati: " +
            _vm._s(_vm.numero_movimenti) +
            " / Scaricati: " +
            _vm._s(_vm.num_scaricati_movimenti) +
            " / Totali: " +
            _vm._s(_vm.num_tot_movimenti)
        ),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "pagination_button",
          attrs: {
            disabled: _vm.num_scaricati_movimenti === _vm.num_tot_movimenti,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.loadMoreMovimenti()
            },
          },
        },
        [_vm._v("Carica altri Movimenti")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }