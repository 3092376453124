var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ml-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [_c("SelectSuper", { attrs: { chiusi: false } })],
            1
          ),
          _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }),
        ],
        1
      ),
      _c("v-container", { staticClass: "containerStyle" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "table",
            { staticStyle: { display: "table", width: "100%" } },
            [
              _vm.matchesVisualizzati.length == 0
                ? _c("div", [
                    _c("p", [
                      _c("i", [
                        _vm._v(
                          "Nessun match trovato per i parametri di ricerca."
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.matchesVisualizzati, function (match) {
                return _c("div", { key: match.idmatch, staticClass: "match" }, [
                  _c(
                    "tr",
                    { staticStyle: { display: "table", width: "100%" } },
                    [
                      _c(
                        "td",
                        { staticStyle: { float: "left", width: "35%" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { float: "left", width: "100%" } },
                            _vm._l(match.matches, function (sottomatch) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "sottomatcho" +
                                    String(
                                      sottomatch.movimenti[0].iddbmovimento
                                    ) +
                                    String(
                                      sottomatch.movimenti[0]
                                        .iddbdettagliomovimento
                                    ),
                                  staticStyle: { float: "left" },
                                },
                                _vm._l(
                                  sottomatch.movimenti,
                                  function (movimento) {
                                    return _c(
                                      "div",
                                      {
                                        key:
                                          String(movimento.iddbmovimento) +
                                          String(
                                            movimento.iddbdettagliomovimento
                                          ),
                                        staticClass: "datiStyle",
                                        attrs: { id: "datiMovimento" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "datiStyle",
                                            staticStyle: {
                                              width: "100%",
                                              "overflow-wrap": "break-word",
                                              "margin-left": "10px",
                                            },
                                            attrs: { id: "datiMovimento" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "26" } },
                                                  [_vm._v("mdi-file")]
                                                ),
                                                _c("strong", [
                                                  _c("p", [
                                                    _vm._v("importo: "),
                                                    _c("i", [
                                                      _vm._v(
                                                        _vm._s(
                                                          movimento.valuta_importo
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v("data op: "),
                                                    _c("i", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatDateShort"
                                                          )(
                                                            movimento.dataoperazione
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v("valuta: "),
                                                    _c("i", [
                                                      _vm._v(
                                                        _vm._s(movimento.valuta)
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-row", [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "overflow-wrap":
                                                      "break-word",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _vm._v("note: "),
                                                  _c(
                                                    "i",
                                                    {
                                                      attrs: {
                                                        title:
                                                          movimento.notemovimento,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatTruncate"
                                                          )(
                                                            movimento.notemovimento,
                                                            250,
                                                            "..."
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            float: "left",
                            "vertical-align": "middle",
                            width: "30%",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "supermatchassociatedoverall",
                                  params: {
                                    id: match.idmatch,
                                    saldo: match.saldo,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btnImgStyle",
                                  attrs: {
                                    title:
                                      "ID: " +
                                      match.idmatch +
                                      " \nscore: " +
                                      match.score +
                                      " \nNote match: " +
                                      match.scorepartite,
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "imgMatchListStyle",
                                    attrs: {
                                      src: require("../assets/ImgMatchAssociatiLista.png"),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("p", { staticStyle: { float: "none" } }, [
                                    _vm._v(
                                      " Saldo Cond.Pag.: " +
                                        _vm._s(
                                          match.differenzamonetizzatainvalutaconimportoscontato
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { float: "right", width: "30%" } },
                        _vm._l(match.matches, function (sottomatch) {
                          return _c(
                            "div",
                            {
                              key:
                                "altro" +
                                String(sottomatch.movimenti[0].iddbmovimento) +
                                String(
                                  sottomatch.movimenti[0].iddbdettagliomovimento
                                ),
                              staticStyle: { float: "left" },
                            },
                            [
                              _vm._l(sottomatch.partite, function (partita) {
                                return _c(
                                  "div",
                                  {
                                    key: String(partita.iddb),
                                    staticClass: "datiStyle",
                                    attrs: { id: "datiMovimento" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "26" } },
                                          [_vm._v("mdi-playlist-check")]
                                        ),
                                        _c(
                                          "strong",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatTruncate")(
                                                    partita.ragionesociale,
                                                    30,
                                                    "..."
                                                  )
                                                ) +
                                                " - " +
                                                _vm._s(partita.sapid)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-row", [
                                      _c("strong", [
                                        _c("p", [
                                          _vm._v("importo "),
                                          _c("i", [
                                            _vm._v(_vm._s(partita.valutalordo)),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("data doc. "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateShort")(
                                                  partita.datadocumento
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("valuta "),
                                          _c("i", [
                                            _vm._v(_vm._s(partita.valuta)),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("ID Partita "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(partita.numerodocumento)
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("Stato "),
                                          _c("i", [
                                            _vm._v(_vm._s(partita.stato)),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              _vm._l(sottomatch.anticipi, function (anticipo) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      String(sottomatch.idmatch) +
                                      String(anticipo.id),
                                    staticClass: "datiStyle",
                                    attrs: { id: "datiMovimento" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "26" } },
                                          [_vm._v("mdi-cash-multiple")]
                                        ),
                                        _c(
                                          "strong",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatTruncate")(
                                                    anticipo.ragionesociale,
                                                    30,
                                                    "..."
                                                  )
                                                ) +
                                                " - " +
                                                _vm._s(anticipo.sapid)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-row", [
                                      _c("strong", [
                                        _c("p", [
                                          _vm._v("Importo "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                sottomatch.movimenti[0]
                                                  .valuta_importo
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("dir.commerciale "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                anticipo.code_direzione_commerciale_anticipo
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("profit center "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                anticipo.code_profit_center_anticipo
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("hr"),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "90%", margin: "auto" } },
        [
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Numero saldi 0: " +
                _vm._s(_vm.numero_zeri) +
                " / Numero saldi cond.pag 0: " +
                _vm._s(_vm.numero_zeri_scontati)
            ),
          ]),
          _c("br"),
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Selezionati: " +
                _vm._s(_vm.numero_match) +
                " / Scaricati: " +
                _vm._s(_vm.num_scaricati_match) +
                " / Totali: " +
                _vm._s(_vm.num_tot_match)
            ),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "pagination_button",
              attrs: {
                disabled: _vm.num_scaricati_match === _vm.num_tot_match,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadMoreMatches()
                },
              },
            },
            [_vm._v("Carica altri Match")]
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }