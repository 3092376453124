<template>
    <div style="width:100%; height:100%; text-align: center; margin-top:10%">
                <img class="imgStyle" src="../assets/Home.png">
    </div>
</template>

<script>
export default ({
    name: 'RobotHome-item',
})
</script>

<style scoped>
.imgStyle{
    max-width:60%;
    margin: auto;
}

.btnStartStyle{
    float: right ;
    background-color: black !important;
    color: white !important;
    border-radius: 30px;
    width: 150px;
}

</style>
