var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ml-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [_c("SelectAnticipi", { attrs: { chiusi: false } })],
            1
          ),
          _c("v-col", { staticClass: "d-flex", attrs: { cols: "2" } }),
        ],
        1
      ),
      _c("v-container", { staticClass: "containerStyle" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "table",
            { staticStyle: { display: "table", width: "100%" } },
            [
              _vm.matchesVisualizzati.length == 0
                ? _c("div", [
                    _c("p", [
                      _c("i", [
                        _vm._v(
                          "Nessun match trovato per i parametri di ricerca."
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.matchesVisualizzati, function (match) {
                return _c(
                  "div",
                  { key: match.id_anticipo, staticClass: "match" },
                  [
                    _c(
                      "tr",
                      { staticStyle: { display: "table", width: "100%" } },
                      [
                        _c(
                          "td",
                          { staticStyle: { float: "left", width: "35%" } },
                          [
                            _c("v-icon", { attrs: { size: "26" } }, [
                              _vm._v("mdi-file"),
                            ]),
                            _c("strong", {
                              staticStyle: { "font-size": "15px" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "datiStyle",
                                staticStyle: {
                                  width: "100%",
                                  "overflow-wrap": "break-word",
                                },
                                attrs: { id: "datiMovimento" },
                              },
                              [
                                _c("strong", [
                                  _c("p", [
                                    _vm._v("importo: "),
                                    _c("i", [_vm._v(_vm._s(match.importo))]),
                                  ]),
                                  _c("p", [
                                    _vm._v("data op: "),
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateShort")(
                                            match.dataoperazione
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _vm._v("valuta: "),
                                    _c("i", [_vm._v(_vm._s(match.valuta))]),
                                  ]),
                                ]),
                                match.is_sottomovimento_con_supermovimento_aggregato
                                  ? _c("p", [
                                      _vm._v(
                                        " Importo supermovimento: " +
                                          _vm._s(
                                            _vm.importoSuperMovimento[
                                              match.iddbmovimento
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "overflow-wrap": "break-word",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _vm._v("note: "),
                                    _c(
                                      "i",
                                      { attrs: { title: match.notemovimento } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatTruncate")(
                                              match.note_movimento,
                                              250,
                                              "..."
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              float: "left",
                              "vertical-align": "middle",
                              width: "30%",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      align: "center",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { float: "none" },
                                        attrs: { title: match.note_scoring },
                                      },
                                      [
                                        _c("input", {
                                          staticStyle: {
                                            transform: "scale(1.4)",
                                            margin: "10px",
                                          },
                                          attrs: {
                                            type: "checkbox",
                                            id: match.id_anticipo,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.check(
                                                match.id_anticipo
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "Scoring: " +
                                            _vm._s(match.scoring) +
                                            " " +
                                            _vm._s(
                                              _vm._f("formatTruncate")(
                                                match.note_scoring,
                                                20,
                                                "..."
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "btnChiudiStyle",
                                        attrs: {
                                          title: match.id_anticipo,
                                          elevation: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.chiudiAnticipo(
                                              match.id_anticipo
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#D91A21" } },
                                          [_vm._v("Conferma")]
                                        ),
                                      ]
                                    ),
                                    _vm._v("    "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "btnChiudiStyle",
                                        attrs: {
                                          title: match.id_anticipo,
                                          elevation: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.dissociaAnticipo(
                                              match.id_anticipo
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#D91A21" } },
                                          [_vm._v("Dissocia")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticStyle: { float: "right", width: "35%" } },
                          [
                            _c("v-icon", { attrs: { size: "26" } }, [
                              _vm._v("mdi-file"),
                            ]),
                            _c(
                              "strong",
                              { staticStyle: { "font-size": "15px" } },
                              [
                                _vm._v(
                                  " REGISTRAZIONE ANTICIPO SU CLIENTE: " +
                                    _vm._s(
                                      _vm._f("formatTruncate")(
                                        match.ragionesociale,
                                        30,
                                        "..."
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(match.sapid)
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "datiStyle",
                                attrs: { id: "datiMovimento" },
                              },
                              [
                                _c("strong", [
                                  _c("p", [
                                    _vm._v("importo "),
                                    _c("i", [_vm._v(_vm._s(match.importo))]),
                                  ]),
                                  _c("p", [
                                    _vm._v("valuta "),
                                    _c("i", [_vm._v(_vm._s(match.valuta))]),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("hr"),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "90%", margin: "auto" } },
        [
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Selezionati: " +
                _vm._s(_vm.numero_match) +
                " / Scaricati: " +
                _vm._s(_vm.num_scaricati_match) +
                " / Totali: " +
                _vm._s(_vm.num_tot_match)
            ),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "pagination_button",
              attrs: {
                disabled: _vm.num_scaricati_match === _vm.num_tot_match,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadMoreMatches()
                },
              },
            },
            [_vm._v("Carica altri Match")]
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }