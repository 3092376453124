<template>
<div>
      <v-row class="ml-5">
        <v-col cols="9">
        <SelectAnticipi :chiusi="false"></SelectAnticipi>
        </v-col>
        <v-col
          class="d-flex"
          cols="2">
        </v-col>
      </v-row>
  <v-container  class="containerStyle">


<div class="box">  
  <table style="display: table; width:100%">
    <div v-if="matchesVisualizzati.length==0">
         <p> <i>Nessun match trovato per i parametri di ricerca.</i></p>
    </div>

     <div v-for="match in matchesVisualizzati" :key="match.id_anticipo" class="match">
     
      <tr style="display:table; width:100%">
        
        <td style="float:left; width: 35%;">
        <v-icon size="26" >mdi-file</v-icon> 
          <strong style="font-size:15px"></strong>
          <div id="datiMovimento" class="datiStyle" style="width:100%; overflow-wrap:break-word;">
            <strong>
            <p>importo: <i>{{ match.importo}}</i></p>
            <p>data op: <i>{{ match.dataoperazione | formatDateShort}}</i></p>
            <p>valuta: <i>{{match.valuta}}</i></p>
          </strong>
            <p v-if="match.is_sottomovimento_con_supermovimento_aggregato"> Importo supermovimento:   {{importoSuperMovimento[match.iddbmovimento]}} </p> 
            <p style="overflow-wrap:break-word; width:100%">note: <i :title=match.notemovimento>{{match.note_movimento | formatTruncate(250, '...')}} </i></p>
            </div>
        
        </td>
        <td style="float:left; vertical-align:middle; width: 30%">
          <v-row >
            <v-col  align="center" justify="center">
               <p :title=match.note_scoring style="float:none"> <input style="transform: scale(1.4); margin:10px" type="checkbox" :id="match.id_anticipo"  @click="check(match.id_anticipo)">Scoring: {{ match.scoring }} {{ match.note_scoring | formatTruncate(20, '...') }}</p>
               <v-btn :title=match.id_anticipo elevation="0" class="btnChiudiStyle" @click="chiudiAnticipo(match.id_anticipo)">
                    <span style="color: #D91A21">Conferma</span>
                </v-btn>
                &nbsp;&nbsp;
                <v-btn :title=match.id_anticipo elevation="0" class="btnChiudiStyle" @click="dissociaAnticipo(match.id_anticipo)">
                    <span style="color: #D91A21">Dissocia</span>
                </v-btn>
            </v-col>
          </v-row>
        </td>
        <td style="float:right; width: 35%">
           <v-icon size="26">mdi-file</v-icon> 
            <strong style="font-size:15px"> REGISTRAZIONE ANTICIPO SU CLIENTE: {{match.ragionesociale | formatTruncate(30, '...')}} - {{match.sapid}}</strong>
            <div id="datiMovimento" class="datiStyle">
              <strong>
                <p >importo <i>{{ match.importo}}</i></p>
                <p >valuta <i>{{ match.valuta}}</i></p>
              </strong>
            </div>
        </td>
      </tr>
      <hr>
    </div>
   </table>
    
  </div>
  </v-container>
   <div style="width:90%; margin:auto;">
    <p class="countStyle"> Selezionati: {{numero_match}} / Scaricati: {{num_scaricati_match}} / Totali: {{num_tot_match}}</p>
  <v-btn class="pagination_button" @click.prevent="loadMoreMatches()" :disabled="num_scaricati_match===num_tot_match">Carica altri Match</v-btn>
    
  </div>
    <br>
</div>

</template>

<script>
import SelectAnticipi from "@/components/SelectAnticipi.vue";

export default {
  name: "MatchList",
    data(){
      return{
         checkedMatch: [],
         sortCondPag: false,
         importoSuperMovimento: "testo"
      }
    },
    components: {
      SelectAnticipi,
    },
    async beforeCreate() {
      console.log("Creo Anticipi List ")
      let myMatches = this.$store.getters["anticipo/getMatchesFiltrati"];
      this.importoSuperMovimento   = {} 
      console.log("myMatches")
      console.log(myMatches)
      if(myMatches) {
        for(let match of myMatches) {
            if(match.is_sottomovimento_con_supermovimento_aggregato) {
              let superMovimento = await this.$store.dispatch("anticipo/LoadSuperMovimento", match.iddbmovimento);
              this.$set(this.importoSuperMovimento, match.iddbmovimento, superMovimento.valuta_importo);
            }
        }
      } 
      this.$store.commit('auth/setIsLoading', false);
    },
    async mounted() { 
      let myMatches = this.$store.getters["anticipo/getMatchesFiltrati"];
      this.importoSuperMovimento = {}

      if(myMatches) {
        for(let match of myMatches) {
            if(match.is_sottomovimento_con_supermovimento_aggregato) {
              let superMovimento = await this.$store.dispatch("anticipo/LoadSuperMovimento", match.iddbmovimento);
              this.$set(this.importoSuperMovimento, match.iddbmovimento, superMovimento.valuta_importo);
            }
        }
      }
      this.$store.commit('auth/setIsLoading', false);
    },
    computed: {
        matchesVisualizzati: function() {
          let imatch = structuredClone(this.$store.getters["anticipo/getMatchesFiltrati"]);
          console.log("matches da visualizzare: ");
          console.log(imatch);
          if(!imatch) return [];
          return imatch;
        },      
        numero_match: function() {
          return this.matchesVisualizzati.length;
        },
        num_tot_match: function() {
          return (this.$store.getters["anticipo/getMatches"]) ? this.$store.getters["anticipo/getMatches"].total : 0;
        },
        num_scaricati_match: function() {
          return (this.$store.getters["anticipo/getMatches"]) ? this.$store.getters["anticipo/getMatches"].size : 0;
        },
    },
    methods:{
        async loadMoreMatches(){
          console.log("loading more");
          let params = new URLSearchParams();
          await this.$store.dispatch("anticipo/LoadMoreMatches", {params: params, soloAperti:true});
          this.matchesOrdinati = structuredClone(this.$store.getters["anticipo/getMatchesFiltrati"]);
          let myMatches = this.matchesOrdinati;
          if(myMatches) {
          for(let match of myMatches) {
              if(match.is_sottomovimento_con_supermovimento_aggregato) {
                let superMovimento = await this.$store.dispatch("anticipo/LoadSuperMovimento", match.iddbmovimento);
                this.$set(this.importoSuperMovimento, match.iddbmovimento, superMovimento.valuta_importo);
              }
            }
        }

        },
        check(id){
          if(document.getElementById(id).checked){
            console.log("aggiungo tra i match da chiudere:" + id);
            this.checkedMatch.push(id);
            console.log(this.checkedMatch)
          }
          else if(!document.getElementById(id).checked){
            console.log("tolgo tra i match da chiudere:" + id);
            this.checkedMatch.splice(this.checkedMatch.indexOf(id ), 1);
            console.log(this.checkedMatch)
          }
        },
        chiudiAnticipo(idAnticipo) {
          this.$emit('chiudiSelezionate', idAnticipo)
        },
        dissociaAnticipo(idAnticipo) {
          this.$emit('dissociaSelezionate', idAnticipo)
        }        
    }
}
</script>

<style scoped>
p {
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
}
.box{
  padding: 4px;
  overflow:auto;
  height: 520px;
}
.pagination_button {
    float: left;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize;
    background-color: rgba(203, 197, 197, 0.242) !important;
    padding: 0px 5px !important;
    border-radius: 5px;
   /* font-family: "'EB Garamond SC',ebGaramond12SC_WGDI,'Times New Roman',serif";*/
}
.countStyle{
  float:left;
  font-size:14px; 
}


.containerStyle{
    border: 1px solid rgb(0, 0, 0);
    background-color: rgba(203, 197, 197, 0.242);
    margin-top: 10px;
    
}

.cardStyle{
    margin: auto !important;
}
.datiStyle{
    font-size: 14px;
    margin-top:10px;
    margin-bottom: 10px;
}
.imgMatchListStyle{
    max-width: 60%;
    vertical-align: middle ;
}
.btnImgStyle{
    background-color: transparent !important;
    width: 100%;
}
.match{
  margin-top:5px;
  margin-bottom: 15px;
  width:100%;
}

</style>