import { render, staticRenderFns } from "./MatchAssociatedView2.vue?vue&type=template&id=7b497b08&scoped=true&"
import script from "./MatchAssociatedView2.vue?vue&type=script&lang=js&"
export * from "./MatchAssociatedView2.vue?vue&type=script&lang=js&"
import style0 from "./MatchAssociatedView2.vue?vue&type=style&index=0&id=7b497b08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b497b08",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VMain,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/dev/LoroPianaRiconciliatoreFrontEnd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b497b08')) {
      api.createRecord('7b497b08', component.options)
    } else {
      api.reload('7b497b08', component.options)
    }
    module.hot.accept("./MatchAssociatedView2.vue?vue&type=template&id=7b497b08&scoped=true&", function () {
      api.rerender('7b497b08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MatchAssociatedView2.vue"
export default component.exports