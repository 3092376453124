var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          staticStyle: { "margin-left": "5px", "margin-right": "5px" },
          attrs: { color: "primary", dark: "", block: "" },
          on: {
            click: function ($event) {
              return _vm.btnClickedHandler()
            },
          },
        },
        [_vm._v(_vm._s(_vm.prompto))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }