import { render, staticRenderFns } from "./MatchAssociatedView1.vue?vue&type=template&id=7b65aa0a&scoped=true&"
import script from "./MatchAssociatedView1.vue?vue&type=script&lang=js&"
export * from "./MatchAssociatedView1.vue?vue&type=script&lang=js&"
import style0 from "./MatchAssociatedView1.vue?vue&type=style&index=0&id=7b65aa0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b65aa0a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VMain,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/dev/LoroPianaRiconciliatoreFrontEnd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b65aa0a')) {
      api.createRecord('7b65aa0a', component.options)
    } else {
      api.reload('7b65aa0a', component.options)
    }
    module.hot.accept("./MatchAssociatedView1.vue?vue&type=template&id=7b65aa0a&scoped=true&", function () {
      api.rerender('7b65aa0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MatchAssociatedView1.vue"
export default component.exports