var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "box",
          on: {
            drop: function ($event) {
              return _vm.onDrop($event)
            },
            dragenter: function ($event) {
              $event.preventDefault()
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
          },
        },
        _vm._l(_vm.partite, function (partita, index) {
          return _c("div", { key: partita.id }, [
            _c(
              "div",
              {
                class: _vm.idSelected == index ? "cardselected" : "card",
                attrs: { draggable: "true", id: partita.numerodocumento },
                on: {
                  click: function ($event) {
                    return _vm.populateDataP(partita)
                  },
                  dragstart: function ($event) {
                    return _vm.dragStart($event, partita)
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "10px",
                        "text-align": "center",
                        "min-height": "40px",
                        width: "100%",
                      },
                    },
                    [
                      _c("h5", [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatTruncate")(
                                partita.ragionesociale,
                                10,
                                "..."
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "min-height": "33px",
                      },
                    },
                    [
                      _c("div", { staticClass: "importoStyle" }, [
                        _c("p", { staticClass: "dataStyle" }, [
                          _vm._v(_vm._s(partita.valutalordo)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "min-height": "20px",
                      },
                    },
                    [
                      _c("p", { staticClass: "dataStyle" }, [
                        _vm._v("ID Doc: " + _vm._s(partita.numerodocumento)),
                      ]),
                      _c("p", { staticClass: "dataStyle" }, [
                        _vm._v("SapID: " + _vm._s(partita.sapid)),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
      _c("p", { staticClass: "countStyle" }, [
        _vm._v(
          " Selezionati: " +
            _vm._s(_vm.numero_partite) +
            " / Scaricati: " +
            _vm._s(_vm.num_scaricate_partite) +
            " / Totali: " +
            _vm._s(_vm.num_tot_partite)
        ),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "pagination_button",
          attrs: {
            disabled: _vm.num_scaricate_partite === _vm.num_tot_partite,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.loadMorePartite()
            },
          },
        },
        [_vm._v("Carica altre Partite")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }