<template>
  <div>
    <ClosedMatchList>

    </ClosedMatchList>
  </div>
</template>

<script>
import ClosedMatchList from "@/components/ClosedMatch/ClosedMatchList.vue";
export default {
    components: { ClosedMatchList }
}
</script>

<style>
.btnBackStyle{
    margin-bottom: 20px;
}
</style>