<template>
    <v-main>
        <v-row v-if="attivaRicerca" align="center"
      justify="center">

        <v-text-field class="shrink" style="width:500px;margin-right:40px" label="Parola/Frase da cercare" v-model="ricercaLibera"></v-text-field> 

        <v-btn large color="primary" elevation="0"  @click="ricercaMatch">
            Ricerca
        </v-btn>
    </v-row>
            <SuperMatchList ref="theMatchlist"></SuperMatchList>
    </v-main>
</template>

<script>
import SuperMatchList from "../components/SuperMatchList.vue";
import EventBus, { ACTIONS } from '../components/Helpers/EventBus.js';

export default ({
    created(){
        console.log(this.numMatch)
    },
    data(){
        return{
            numMatch: this.$route.params.numMatch,
            ricercaLibera: "",
        }
    },
    components: {
        SuperMatchList,
    },
    mounted() { 
        this.$store.commit('auth/setIsLoading', false);
    },    
    methods:{
    
        async chiudiSelezionate(){
            if(confirm("Sicuro di voler chiudere i match selezionati?")) {
                console.log("Chiudi Selezionate");
                let selected = structuredClone(this.$refs.theMatchlist.checkedMatch); 
                let allMatches = await this.$store.getters["super/getMatches"].items;
                console.log(selected);
                let allZero = true;

                for(let anIdMatch of selected) {
                    let currentMatch = allMatches.find(match => match.idmatch == anIdMatch);
                    if(currentMatch.saldo != 0 && currentMatch.differenzamonetizzatainvalutaconimportoscontato !=0 ) {
                        console.log("trovato saldo diverso da zero per: " + anIdMatch)
                        allZero = false;
                    }
                }
                if(!allZero) {
                        EventBus.$emit(ACTIONS.SNACKBAR_KO, "Selezionato match con saldo diverso da zero.");
                        return;
                }
                await this.$store.commit('auth/setIsLoading', true, { root: true }) 
                for(let anIdMatch of selected) {
                    // chiudi as is.
                    let theMatch = allMatches.find(match => match.idmatch == anIdMatch);
                    try{
                        await this.$store.dispatch("superM/SaveMatch", theMatch);
                        let newMatches = await this.$store.getters["superM/getMatches"].items.filter(match => match.idmatch != theMatch.idmatch);
                        let newMatchesStructure = await this.$store.getters["superM/getMatches"];
                        newMatchesStructure.items = newMatches
                        newMatchesStructure.total = newMatchesStructure -1;
                        await this.$store.commit('superM/setMatches', newMatchesStructure);
                        this.$refs.theMatchlist.checkedMatch.splice(this.$refs.theMatchlist.checkedMatch.indexOf(anIdMatch ), 1);
                        console.log("Match chiuso con successo" + anIdMatch);
                    } catch(error){
                        await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                        console.log(error)
                        EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel salvataggio del match: " + anIdMatch);
                        return;
                    }
                }
                console.log("Tutti i match chiusi con successo");

                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Match chiusi con successo");
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 
            } else {
                console.log("refused")
            }
        },
        async dissociaSelezionate(){
            if(confirm("Sicuro di voler dissociare i match selezionati?")) {
                console.log("Chiudi Selezionate");
                let selected = structuredClone(this.$refs.theMatchlist.checkedMatch); 
                let allMatches = await this.$store.getters["superM/getMatches"].items;
                console.log(selected);

                await this.$store.commit('auth/setIsLoading', true, { root: true }) 
                for(let anIdMatch of selected) {
                    // dissocia as is.
                    let theMatch = allMatches.find(match => match.idmatch == anIdMatch);
                    try{
                        await this.$store.dispatch("superM/DeleteMatch", theMatch);
                        let newMatches = await this.$store.getters["superM/getMatches"].items.filter(match => match.idmatch != theMatch.idmatch);
                        let newMatchesStructure = await this.$store.getters["superM/getMatches"];
                        newMatchesStructure.items = newMatches
                        newMatchesStructure.total = newMatchesStructure -1;
                        await this.$store.commit('superM/setMatches', newMatchesStructure);
                        this.$refs.theMatchlist.checkedMatch.splice(this.$refs.theMatchlist.checkedMatch.indexOf(anIdMatch ), 1);
                        console.log("Match disassociato con successo" + anIdMatch);
                    } catch(error){
                        await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                        console.log(error)
                        EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel dissociare del match: " + anIdMatch);
                        return;
                    }
                }
                console.log("Tutti i match disassociati con successo");

                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Match disassociati con successo");
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 

            } else {
                console.log("refused")

            }
        },
        async ricercaMatch() {
            await this.$store.commit('auth/setIsLoading', true, { root: true }) 

            let params = new URLSearchParams();
            params.append('like_str', this.ricercaLibera);
            await this.$store.dispatch("superM/LoadMatches", {params: params, soloAperti:true});
            await this.$store.commit('auth/setIsLoading', false, { root: true }) 

        }
    },
    computed:{
        attivaRicerca : function() {
            return this.$store.getters["superM/getIsRicercaOn"];
        }
    },
})
</script>

<style scoped>

.btnBackStyle{
    margin-bottom: 0px;
}

.btnChiudiStyle{
    background-color: #FFDFCD !important;
    border: 1px solid rgb(0, 0, 0);
    margin: 15px 45px 5px 5px;
    float: right;
}
.btnOkStyle{
    background-color: transparent !important;
    float: right;
    text-shadow: 1px 1px #3a383850;
}

</style>
