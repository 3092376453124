var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "containerStyle" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("div", { staticClass: "datiMatch" }, [
          _c("strong", [_vm._v("ID Match: " + _vm._s(_vm.theMatch.idmatch))]),
          _c("p", { staticStyle: { "font-size": "13px" } }, [
            _vm._v("Saldo (movimenti - partite - sconto): "),
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.theMatch.differenzamonetizzatainvalutaconimportoscontato
                )
              ),
            ]),
          ]),
          _vm.theMatch.code_stato_trasferimento_match
            ? _c(
                "p",
                {
                  staticStyle: { "font-size": "13px", "margin-bottom": "2px" },
                },
                [
                  _vm._v(
                    "Stato trasferimento su SAP: " +
                      _vm._s(_vm.descrizioneMatch(_vm.theMatch))
                  ),
                ]
              )
            : _vm._e(),
          _vm.theMatch.datachiusuramatch
            ? _c(
                "p",
                {
                  staticStyle: { "font-size": "13px", "margin-bottom": "2px" },
                },
                [
                  _vm._v(
                    "Data Chiusura Match: " +
                      _vm._s(
                        _vm._f("formatDateTime")(_vm.theMatch.datachiusuramatch)
                      )
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "p",
            { staticStyle: { "font-size": "13px", "margin-bottom": "2px" } },
            [
              _vm._v(
                "Percentuale correttezza probabilità: " +
                  _vm._s(_vm.theMatch.score) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            { staticStyle: { "font-size": "13px", "margin-bottom": "2px" } },
            [
              _vm._v("dettagli score: "),
              _c(
                "v-btn",
                {
                  staticClass: "flexcol",
                  staticStyle: { margin: "0px 5px 0px 5px" },
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.showText = !_vm.showText
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "20" } }, [
                    _vm._v("mdi-arrow-down-box"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showText
            ? _c(
                "p",
                {
                  staticStyle: { "font-size": "13px", "margin-bottom": "2px" },
                },
                [_vm._v(_vm._s(_vm.theMatch.scorepartite))]
              )
            : _vm._e(),
          !_vm.showText
            ? _c("div", { staticStyle: { padding: "20px" } }, [
                _c(
                  "table",
                  {
                    staticClass: "datiStyle",
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-right": "auto",
                      "text-align": "left",
                      padding: "20px",
                    },
                  },
                  [
                    _vm._m(0),
                    _c("tr", [
                      _c("td", [_vm._v("Scoring riconoscimento cliente")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.theMatch.gri_scoring_riconoscimento_cliente
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Scoring riconoscimento importo")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.theMatch.gri_scoring_riconoscimento_importo
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Scoring riconoscimento partita")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.theMatch.gri_scoring_riconoscimento_partita
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Premialità")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.theMatch.gri_scoring_premialita)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Rettifiche")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.theMatch.gri_scoring_rettifiche)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Penalizzazioni")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.theMatch.gri_scoring_penalizzazioni)),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c("table", { staticStyle: { width: "100%" } }, [
          _c("tr", [
            _c(
              "td",
              { staticStyle: { "vertical-align": "text-top", width: "35%" } },
              [
                _c("DetailMovimento", {
                  attrs: { idVal: _vm.idVal, origin: _vm.origin },
                  on: {
                    openDoc: function ($event) {
                      return _vm.openDoc($event)
                    },
                    openMovimento: function ($event) {
                      return _vm.openMovimento($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("td", { staticStyle: { "vertical-align": "text-top" } }, [
              _vm._m(1),
              _vm.origin
                ? _c(
                    "div",
                    { staticStyle: { width: "100%", "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btnDissociaStyle",
                          on: { click: _vm.dissocia },
                        },
                        [_vm._v(" Dissocia ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btnDissociaStyle",
                          staticStyle: { "margin-left": "20px" },
                          on: { click: _vm.modificaPartite },
                        },
                        [_vm._v(" Modifica Partite ")]
                      ),
                      _c("br"),
                      _vm.theMatch.movimenti.length == 1
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btnDissociaStyle",
                              staticStyle: {
                                "margin-top": "50px",
                                "margin-left": "20px",
                              },
                              on: { click: _vm.creaAnticipo },
                            },
                            [_vm._v(" Converti in Anticipo ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "td",
              { staticStyle: { "vertical-align": "text-top", width: "35%" } },
              [
                _c("DetailPartita", {
                  attrs: { idVal: _vm.idVal, origin: _vm.origin },
                  on: {
                    openDoc: function ($event) {
                      return _vm.openDoc($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "v-dialog",
          {
            staticClass: "pl-5",
            attrs: { width: "100%", height: "100%" },
            model: {
              value: _vm.show,
              callback: function ($$v) {
                _vm.show = $$v
              },
              expression: "show",
            },
          },
          [
            _c(
              "v-card",
              [
                _c("WizardPartite", {
                  attrs: {
                    partiteInIngresso: _vm.theMatch.partite,
                    ragioneSocialeConfermata: _vm.show,
                    movimentoCorrente: _vm.theMatch.movimenti[0],
                    differenzeInIngresso: _vm.theMatch.partite_di_pareggio,
                  },
                  on: {
                    movimentoChiuso: _vm.movimentoChiuso,
                    dissocia: function ($event) {
                      return _vm.dissocia($event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.theMatch.partite_di_pareggio
          ? _c(
              "v-card",
              {
                staticStyle: {
                  "background-color": "rgba(215, 214, 214, 0.751)",
                  "margin-top": "20px",
                  width: "60%",
                  "margin-left": "auto",
                  "margin-right": "auto",
                },
              },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "line-height": "50px",
                      "margin-bottom": "3px",
                    },
                  },
                  [
                    _vm._v(
                      " Partite di Pareggio " +
                        _vm._s(
                          _vm.theMatch.code_stato_trasferimento_match
                            ? ""
                            : "proposte dal Matcher "
                        ) +
                        ". Saldo +movimenti -partite parziale: " +
                        _vm._s(_vm.theMatch.saldo) +
                        " "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._l(_vm.theMatch.partite_di_pareggio, function (differenzaPartite) {
          return _c(
            "div",
            {
              key: differenzaPartite,
              staticStyle: {
                "border-radius": "3px",
                border: "1px solid rgba(3, 3, 3, 0.17)",
                "background-color": "#ffffffcc",
                width: "60%",
                "margin-left": "auto",
                "margin-right": "auto",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("Conto:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(_vm.nomeConto(differenzaPartite.cod))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("ID Partita:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(
                                  differenzaPartite.id_partita
                                    ? differenzaPartite.id_partita
                                    : "Nessuna Partita Associata"
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("Importo:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.segnoPerCodice(differenzaPartite.cod)
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(differenzaPartite.importo) +
                                  " " +
                                  _vm._s(
                                    differenzaPartite.cod === 2 ||
                                      differenzaPartite.cod === 8
                                      ? "EUR"
                                      : _vm.theMatch.movimenti[0].valuta
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        _c("p", [_vm._v(" ")]),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "50%" },
            model: {
              value: _vm.dialogAnticipo,
              callback: function ($$v) {
                _vm.dialogAnticipo = $$v
              },
              expression: "dialogAnticipo",
            },
          },
          [
            _c(
              "v-card",
              { attrs: { padding: "20px" } },
              [
                _c("v-card-title", { staticClass: "text-h5" }, [
                  _vm._v(" Scegli dettagli anticipo "),
                ]),
                _c("v-select", {
                  staticStyle: {
                    width: "90%",
                    "padding-top": "50px",
                    margin: "auto",
                  },
                  attrs: {
                    items: _vm.direzioniCommerciali,
                    "item-text": "description",
                    "item-value": "code",
                    label: "Direzione Commerciale",
                  },
                  model: {
                    value: _vm.direzione_commerciale,
                    callback: function ($$v) {
                      _vm.direzione_commerciale = $$v
                    },
                    expression: "direzione_commerciale",
                  },
                }),
                _c("v-select", {
                  staticStyle: { width: "90%", margin: "auto" },
                  attrs: {
                    items: _vm.profitCenters,
                    "item-text": "description",
                    "item-value": "code",
                    label: "Profit Center",
                  },
                  model: {
                    value: _vm.profit_center,
                    callback: function ($$v) {
                      _vm.profit_center = $$v
                    },
                    expression: "profit_center",
                  },
                }),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticStyle: { "margin-right": "50px" },
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.completaCreaAnticipo()
                              },
                            },
                          },
                          [_vm._v("Submit")]
                        ),
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogAnticipo = false
                                _vm.direzione_commerciale = ""
                                _vm.profit_center = ""
                              },
                            },
                          },
                          [_vm._v("Annulla")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "300px" } }, [
        _vm._v("Tipo di scoring"),
      ]),
      _c("th", [_vm._v("Valore")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { width: "100%", "text-align": "center" } },
      [
        _c("img", {
          staticClass: "imgStyle",
          attrs: { src: require("../assets/arrow.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }