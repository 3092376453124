<template>
<div>
  <v-container  class="containerStyle">


<div class="box">  
  <table style="display: table; width:100%">
    <div v-if="matchesVisualizzati.length==0">
         <p> <i>Nessun match trovato per i parametri di ricerca.</i></p>
    </div>

     <div v-for="match in matchesVisualizzati" :key="match.idmatch" class="match">
     
      <tr style="display:table; width:100%">
        
        <td style="float:left; width: 35%;">
            <div style="float:left; width:100%">
        <v-icon size="26" >mdi-file</v-icon> 
          <strong style="font-size:15px"></strong>
          <div id="datiMovimento" class="datiStyle" style="width:100%; overflow-wrap:break-word;">
            <strong>
            <p>importo: <i>{{ match.movimenti[0].valuta_importo}}</i></p>
            <p>data op: <i>{{ match.movimenti[0].dataoperazione | formatDateShort}}</i></p>
            <p>valuta: <i>{{match.movimenti[0].valuta}}</i></p>
          </strong>
            <p style="overflow-wrap:break-word; width:100%">note: <i :title=match.movimenti[0].notemovimento>{{match.movimenti[0].notemovimento | formatTruncate(250, '...')}} </i></p>
            </div>
        </div>
        
        </td>
        <td style="float:left; vertical-align:middle; width: 30%">
                <v-btn class="btnImgStyle" elevation="0"  >
            <img class="imgMatchListStyle" src="../assets/ImgMatchAssociatiLista.png">
          </v-btn>
        </td>
        <td style="float:right; width: 35%">
        <v-btn style="margin-left:90%"
          color="red lighten-2"
          dark
          @click="editSubmovimento(match)"
        >
          Edit
        </v-btn>
          <div v-for="partita in match.partite" :key="String(match.idmatch) + String(partita.iddb)" style="float:left;">
           <v-icon size="26">mdi-playlist-check</v-icon> 
            <strong style="font-size:15px"> {{partita.ragionesociale | formatTruncate(30, '...')}} - {{partita.sapid}}</strong>
            <div id="datiMovimento" class="datiStyle">
              <strong>
                <p >importo <i>{{ partita.valutalordo}}</i></p>
                <p >data doc. <i>{{ partita.datadocumento | formatDateShort}}</i></p> 
                <p >valuta <i>{{ partita.valuta}}</i></p>
                <p >ID Partita <i>{{partita.numerodocumento }}</i></p> 
                <p >Stato <i>{{partita.stato }}</i></p> 
              </strong>
            </div>
         </div>
        </td>
        <td style="float:right; width: 35%">
          <div v-for="anticipo in match.anticipi" :key="String(match.idmatch) + String(anticipo.id)" style="float:left;">
           <v-icon size="26">mdi-cash-multiple</v-icon> 
           <strong style="font-size:15px"> {{anticipo.ragionesociale | formatTruncate(30, '...')}} - {{anticipo.sapid}}</strong>
            <div id="datiMovimento" class="datiStyle">
              <strong>
                <p >Importo <i>{{ match.movimenti[0].valuta_importo}}</i></p>
                <p >dir.commerciale <i>{{ anticipo.code_direzione_commerciale_anticipo}}</i></p>
                <p >profit center <i>{{ anticipo.code_profit_center_anticipo}}</i></p> 
              </strong>
            </div>
         </div>
        </td>

      </tr>
  
  

      <hr>
    </div>
   </table>
   <v-dialog
      persistent
      v-model="dialog"
      width="900"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <v-row>
          <v-radio-group
              v-model="isAnticipo"
              row
            >
              <v-radio
                label="Anticipo"
                v-bind:value="true"
              ></v-radio>
              <v-radio
                label="Partita"
                v-bind:value="false"
              ></v-radio>
          </v-radio-group>
          <p style="margin-left:50%;margin-top:15px">Saldo: {{saldoSottoMovimento}}    </p>
        </v-row>
        </v-card-title>

        <v-card-text v-if="isAnticipo && currentSubMatch">
          <v-text-field style="width:90%; padding-top:20px; margin:auto"
          disabled
          label="Importo"
          :value=currentSubMatch.movimenti[0].valuta_importo> </v-text-field>

          <v-autocomplete
                        style="width:90%; margin:auto"
                        :items="stakeholders"
                        :item-text="getItemText"
                        item-value="sapid"
                        label="Cliente"
                        dense
                        v-model="currentSubMatch.anticipi[0].sapid"
                    ></v-autocomplete>
            <v-select style="width:90%; margin:auto" 
            :items="direzioniCommerciali"
            item-text="description"
            item-value="code"
            label="Direzione Commerciale"
            v-model="currentSubMatch.anticipi[0].code_direzione_commerciale_anticipo">                         
        </v-select>
        <v-select style="width:90%; margin:auto" 
            :items="profitCenters"
            item-text="description"
            item-value="code"
            label="Profit Center"
            v-model="currentSubMatch.anticipi[0].code_profit_center_anticipo">                         
        </v-select>

        </v-card-text>
        <v-card-text v-if="!isAnticipo && currentSubMatch.movimenti">
          <v-row style="margin-top:20px">
          <v-autocomplete
                        style="max-width:70%; margin-left:3%"
                        :items="stakeholders"
                        :item-text="getItemText"
                        item-value="sapid"
                        label="Cliente"
                        dense
                        :disabled="cambiaClienteEnabled===0"
                        @change="ricaricaPartite()"
                        v-model="currentSubMatch.sapid"
                    ></v-autocomplete>
           <v-btn style="margin-left:60px"
          color="red lighten-2"
          dark
          @click="cambiaCliente(match)"
        >
          Cambia Cliente
        </v-btn>
      </v-row>
      <v-row v-for="partita in currentSubMatch.partite" :key="partita.numerodocumento">
          <v-text-field style="max-width:30%; padding-top:20px; margin-left:3%"
          disabled
          label="Importo"
          :value=partita.valutalordo> </v-text-field>
          <v-text-field style="max-width:10%; padding-top:20px; margin-left:3%"
          disabled
          label="Valuta"
          :value=partita.valuta> </v-text-field>
          <v-text-field style="max-width:10%; padding-top:20px; margin-left:3%"
          disabled
          label="Data"
          :value= formattaData(partita.datadocumento) > </v-text-field>
          <v-text-field style="max-width:10%; padding-top:20px; margin-left:3%"
          disabled
          label="ID"
          :value= partita.numerodocumento > </v-text-field>
          <v-btn style="margin-top:15px; margin-left:6%"
          color="red lighten-2"
          dark
          @click="rimuoviPartita(partita)"
        >
          Rimuovi
        </v-btn>

        </v-row>
        <v-row>
          <v-autocomplete
                        style="max-width:70%; margin-left:3%"
                        :items="partiteAttive"
                        :item-text="getPartitaText"
                        label="Partita"
                        dense
                        v-model="partitaSelezionata"
                        return-object
                    ></v-autocomplete>          
                    <v-btn style="margin-top:15px; margin-left:6%"
                        color="red lighten-2"
                        dark
                        @click="aggiungiPartita()"
                      >
                        Aggiungi
                      </v-btn>
        </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="cancelModifica()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="finitaModifica()"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
  
  </v-container>
  <v-row >
  <v-card v-if="theSuperMatch.partite_di_pareggio" style="background-color: rgba(215, 214, 214, 0.751);border: 1px solid rgba(3, 3, 3, 0.17); margin-top: 50px;width:60%;margin-left:auto;margin-right:auto">
              <p  style="text-align: center; line-height: 30px;margin-bottom: 3px">
                 Partite di Pareggio {{currentSubMatch.code_stato_trasferimento_match ? "":"proposte dal Matcher "}}.  Saldo +movimenti -partite parziale: {{currentSubMatch.saldo}}
              </p>
          </v-card>
  </v-row>
   <div v-for="differenzaPartite in theSuperMatch.partite_di_pareggio" :key="differenzaPartite" style="border-radius: 3px;border: 1px solid rgba(3, 3, 3, 0.17);background-color: #ffffffcc;width:60%;margin-top:5px;margin-left:auto;margin-right:auto" >
        <v-container >
          <v-row >
            <v-col cols="4">
              <v-row>
               <p class="pStyle1">Conto:</p>
                  <p class="dataStyle1">{{nomeConto(differenzaPartite.cod)}}</p>
              </v-row>
            </v-col>
                  <v-spacer></v-spacer>

            <v-col cols="4">
              <v-row>
               <p class="pStyle1">ID Partita:</p>
                  <p class="dataStyle1">{{differenzaPartite.id_partita ? differenzaPartite.id_partita : "Nessuna Partita Associata"}}</p>
              </v-row>
            </v-col>
                  <v-spacer></v-spacer>

            <v-col cols="2">
              <v-row>
               <p class="pStyle1">Importo:</p>                 
              <p class="dataStyle1">{{segnoPerCodice(differenzaPartite.cod)}}</p>
               <p class="dataStyle1">{{differenzaPartite.importo}} {{((differenzaPartite.cod === 2) || (differenzaPartite.cod === 8)) ? 'EUR' : (theSuperMatch.matches[0].movimenti[0].valuta)}}</p>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
    </div>
    <br>
</div>

</template>

<script>

export default {
  name: "SuperMatchListOverall",
    data(){
      return{
        matchesVisualizzati: [],
        dialog: false,
        theSuperMatch: {},
        currentSubMatch: {},
        differenzePartiteCodici: null,
        partitaSelezionata: null,
        cambiaClienteEnabled: 0,
        savedSub: {}
      }
    },
    props:{
      idVal: null,
    },
    components: {
    },
    async mounted() {
        this.$store.commit('auth/setIsLoading', false);
        let theId = this.idVal;
        console.log("Super Match for id: " + theId)
        this.differenzePartiteCodici = this.$store.getters["match/getContiDifferenzeIncassiPartite"];
        let theSuperMatch = this.$store.getters["superM/getMatches"].items.find(match => match.idmatch === theId);
        console.log("Super Match associato: " + theSuperMatch);
        this.theSuperMatch = theSuperMatch;
        this.matchesVisualizzati = theSuperMatch.matches;
        console.log("Match associati: " + this.matchesVisualizzati);
    },
    methods:{
        differenzaMovimentiMenoPartite(match) {
          let sommaMovimenti = 0;
          match.movimenti.forEach(function(movimento) {
              if (movimento.valuta_importo) {
                  sommaMovimenti += movimento.valuta_importo;
              }
          })
          let sommaPartite = 0;
          match.partite.forEach(function(partita) {
              if (partita.valutalordo) {
                  sommaPartite += partita.valutalordo;
              }
          })
          match.saldo = sommaMovimenti-sommaPartite;
          return sommaMovimenti-sommaPartite;
        },
        editSubmovimento(aMatch) {
          console.log("edit submatch");
          console.log(aMatch);
          this.currentSubMatch = aMatch;
          if(!this.isAnticipo) {
            this.currentSubMatch.sapid = this.currentSubMatch.partite[0].sapid;
            this.ricaricaPartite();
          }
          this.savedSub = {};
          this.savedSub.anticipi = structuredClone(aMatch.anticipi);
          this.savedSub.partite = structuredClone(aMatch.partite);
          
          this.dialog = true;
        },
        async ricaricaPartite() {
            let params = new URLSearchParams();
            params.append("sapid", this.currentSubMatch.sapid);
            params.append("escludi_coinvolti_in_match", true);
            params.append("soloAperte", true);
            await this.$store.dispatch("match/LoadPartite", params);
        },
        rimuoviPartita(aPartita) {
          console.log("rimuovo partita");
          console.log(aPartita);

          const index = this.currentSubMatch.partite.indexOf(aPartita);
          console.log("index: " + index);
          this.currentSubMatch.partite.splice(index, 1);
        },         
        cambiaCliente() {
          console.log("cambia cliente");
          this.cambiaClienteEnabled=1;
          this.currentSubMatch.partite = []
        },         
        aggiungiPartita() {
          console.log("aggiungo partita");
          this.cambiaClienteEnabled=0;
          console.log(this.partitaSelezionata);
          if(this.partitaSelezionata) {
            this.currentSubMatch.partite.push(this.partitaSelezionata);
          } else {
            alert("seleziona partita")
          }

        }, 
        getItemText(item) {
            return `${item.ragionesociale} - ${item.sapid}`;
        },
        getPartitaText(item) {
          let date = this.formattaData(item.datascadenza)
            return `Importo:${item.valutalordo}${item.valuta} - nr:${item.numerodocumento} - ` + "scad:" + date;
        },
        finitaModifica() {
          console.log("finito edit submovimento")
          if(this.isAnticipo) {
            let stakeholder = this.$store.getters["match/getStakeholders"].items.find(stakeholder => {
                  return stakeholder.sapid === this.currentSubMatch.anticipi[0].sapid;
              });
            if(stakeholder) {
              this.currentSubMatch.anticipi[0].ragionesociale = stakeholder.ragionesociale;
            }
            let anticipo = this.currentSubMatch.anticipi[0];
            if(!anticipo.code_direzione_commerciale_anticipo || 
               !anticipo.code_profit_center_anticipo || 
               !anticipo.sapid || 
               !anticipo.ragionesociale) {
                alert("completa dati anticipo")
              } else {
                this.dialog = false;
            }
          } else  {
            let part = this.currentSubMatch.partite
            if(!part || part.length < 1) {
              alert("almeno una partita")
            } else {
              this.dialog = false;
            }
          }
        },
        cancelModifica() {
          console.log("cancel edit submovimento")
          this.currentSubMatch.anticipi = this.savedSub.anticipi;
          this.currentSubMatch.partite = this.savedSub.partite;
          this.dialog = false;
        },
        formattaData(aDateString) {
          if (aDateString) {
            var aDate = new Date(aDateString);
            var curr_date = aDate.getDate();
            var curr_month = aDate.getMonth() + 1; //Months are zero based
            var curr_year = aDate.getFullYear().toString().substring(2,4);
            return curr_date + "/" + curr_month + "/" + curr_year;
          }
        },
        nomeConto(codice) {
          let diff = this.differenzePartiteCodici.find(diff => diff.codiceinternoconto === codice);
          if(diff) {
            return diff.nomeconto;
          } else {
            return "codice non trovato"
          }
        },
        segnoPerCodice (codice) {
          let diff = this.differenzePartiteCodici.find(diff => diff.codiceinternoconto === codice);

            if(diff) {
              if(diff.iscontoinavere) {
                return "-";
              } else {
                  return "+"
              }
            } else {
              return "N/A"
            }
        }
    },
    computed: {
        isAnticipo: {
          get () {
            if(this.currentSubMatch && this.currentSubMatch.anticipi && this.currentSubMatch.anticipi.length > 0){
              console.log("isAnticipo true");
              return true;
            } else {
              console.log("isAnticipo false");
              return false;
            }
          },  
          set (value) {
            if(value) {
              console.log("set isAnticipo true");
              this.currentSubMatch.partite =[];
              this.currentSubMatch.anticipi =[{
                code_direzione_commerciale_anticipo:"",
                code_profit_center_anticipo:""
              }];
            } else {
              console.log("set isAnticipo false");
              this.currentSubMatch.anticipi =[];
              this.currentSubMatch.partite =[];
            }
          }
        },
        stakeholders : function() {
            return structuredClone(this.$store.getters["match/getStakeholders"].items);
        },
        direzioniCommerciali : function() {
          return this.$store.getters["superM/getDirezioniCommerciali"];
        },
        profitCenters : function() {
          return this.$store.getters["superM/getProfitCenters"];
        },
        partiteAttive: function() {
          const partiteHolder =  this.$store.getters["match/getPartite"]

          if(partiteHolder) {
            return partiteHolder.items
          } else {
            return [];
          }
        },
        saldoSottoMovimento: function() {
          if(this.isAnticipo) {
            return 0;
          } else {
            if(this.currentSubMatch && this.currentSubMatch.partite) {
              let totaleSaldo = 0;
              for (const p of this.currentSubMatch.partite) { 
                  console.log("somma partita: " + p.valutalordo); 
                  if(this.currentSubMatch.movimenti[0].valuta === p.valuta) {
                      totaleSaldo += p.valutalordo;
                  } else {
                      totaleSaldo += p.eurlordo;
                  }
              }
              totaleSaldo = Number(this.currentSubMatch.movimenti[0].valuta_importo) - totaleSaldo
              return  this.globalRounderToTwoDecimals(totaleSaldo);
            }
          }
          return 0;
        }
    },
}
</script>

<style scoped>
p {
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
}
.box{
  padding: 4px;
  overflow:auto;
  height: 520px;
}
.pagination_button {
    float: left;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize;
    background-color: rgba(203, 197, 197, 0.242) !important;
    padding: 0px 5px !important;
    border-radius: 5px;
   /* font-family: "'EB Garamond SC',ebGaramond12SC_WGDI,'Times New Roman',serif";*/
}
.countStyle{
  float:left;
  font-size:14px; 
}


.containerStyle{
    border: 1px solid rgb(0, 0, 0);
    background-color: rgba(203, 197, 197, 0.242);
    margin-top: 10px;
    
}

.cardStyle{
    margin: auto !important;
}
.datiStyle{
    font-size: 14px;
    margin-top:10px;
    margin-bottom: 10px;
}
.imgMatchListStyle{
    max-width: 60%;
    vertical-align: middle ;
}
.btnImgStyle{
    background-color: transparent !important;
    width: 100%;
}
.match{
  margin-top:5px;
  margin-bottom: 15px;
  width:100%;
}
.pStyle{
    font-size: 13px ;
    padding: 1px;
    margin-top:5px;
    text-align: left;
}

.pStyle1{
    font-size: 13px ;
    padding: 1px;
    margin-top:8px;
    text-align: left;
}


.dataStyle1{
    font-size: 15px;
    border: 1px solid rgba(3, 3, 3, 0.17);
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: center;
    border-radius: 3px;
    margin-top:5px;
white-space:nowrap;
}


</style>