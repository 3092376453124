var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(
                  " Seleziona differenze. Saldo: " +
                    _vm._s(_vm.saldo) +
                    " " +
                    _vm._s(_vm.valuta) +
                    " "
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.differenze,
                              "hide-default-footer": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.idPartita",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          staticStyle: { "min-width": "100px" },
                                          attrs: {
                                            clearable: "",
                                            "return-value":
                                              props.item.idPartita,
                                            large: "",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "idPartita",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "idPartita",
                                                $event
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "input",
                                                fn: function () {
                                                  return [
                                                    _c("v-select", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        items: _vm.partite,
                                                        "item-text":
                                                          "numerodocumento",
                                                        "item-value": "iddb",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          props.item.idPartita,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "idPartita",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.idPartita",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.item.idPartita) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codice",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          staticStyle: { "min-width": "100px" },
                                          attrs: {
                                            "return-value": props.item.codice,
                                            large: "",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "codice",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "codice",
                                                $event
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "input",
                                                fn: function () {
                                                  return [
                                                    _c("v-select", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        items:
                                                          _vm.differenzePartiteCodici,
                                                        "item-text":
                                                          "nomeconto",
                                                        "item-value":
                                                          "codicesapconto",
                                                        "return-object": "",
                                                      },
                                                      model: {
                                                        value:
                                                          props.item.codice,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "codice",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.codice",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.item.codice.nomeconto
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.importo",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value": props.item.importo,
                                            large: "",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "importo",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                props.item,
                                                "importo",
                                                $event
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "input",
                                                fn: function () {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: "Edit",
                                                        "single-line": "",
                                                        autofocus: "",
                                                      },
                                                      model: {
                                                        value:
                                                          props.item.importo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "importo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.importo",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(props.item.importo)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.dare",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.segnoPerCodice(item)) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm.differenze.length > 1
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { large: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copySaldo(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-content-copy ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { large: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addItem()
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-plus-box-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-title", { staticClass: "text-h7 dense" }, [
                    _vm._v(
                      " Saldo Finale: " +
                        _vm._s(_vm.saldoFinale) +
                        " " +
                        _vm._s(_vm.valuta) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-5",
                          attrs: { outlined: "", text: "", elevation: "0" },
                          on: { click: _vm.cancelModal },
                        },
                        [_vm._v(" Cancella ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            text: "",
                            elevation: "0",
                            disabled: !(_vm.saldoFinale === 0),
                          },
                          on: { click: _vm.chiudiPartita },
                        },
                        [_vm._v(" Chiudi Match ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", {
                staticStyle: { width: "100%" },
                attrs: { id: "newSelect" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }