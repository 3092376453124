<template>
    <div id="BI" style="height:1000px;overflow:hidden">
     
        <iframe src="/superset/dashboard/riconciliazionitoolcontrollo/?standalone=true" style="width:100%;height:100%;"></iframe>
    </div>
         
    </template>
    
    <script>
    
      export default {
        name: 'BiView',
    
        components: {
        },
        async created() {
        }
      }
    </script>
    
    <style>
    </style>
    