var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "divStyle" }, [
      _c(
        "p",
        { staticClass: "filterStyle", staticStyle: { "margin-top": "0px" } },
        [_vm._v(" Filtra movimenti in base ad un documento ")]
      ),
      _c("div", { staticClass: "container" }, [
        _c(
          "table",
          { staticClass: "tableStyle", staticStyle: { margin: "10px" } },
          [
            _c(
              "tr",
              { staticStyle: { "text-align": "center", width: "100%" } },
              [
                _c(
                  "td",
                  { staticClass: "riga" },
                  [
                    _c("p", { staticClass: "titleStyle" }, [
                      _vm._v(" Tipo Documento "),
                    ]),
                    _c("v-select", {
                      staticStyle: {
                        "padding-right": "5px",
                        "padding-left": "5px",
                      },
                      attrs: {
                        clearable: "",
                        items: _vm.tipiDocumento,
                        "item-text": "descrizione",
                        "item-value": "iddb",
                        label: "Scegli Documento",
                      },
                      model: {
                        value: _vm.MM0MovimentoTipoDocumento,
                        callback: function ($$v) {
                          _vm.MM0MovimentoTipoDocumento = $$v
                        },
                        expression: "MM0MovimentoTipoDocumento",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "riga" },
                  [
                    _c("p", { staticClass: "titleStyle" }, [_vm._v(" Anno ")]),
                    _c("v-select", {
                      staticStyle: {
                        "padding-right": "5px",
                        "padding-left": "5px",
                      },
                      attrs: { items: _vm.anni, label: "Scegli anno" },
                      model: {
                        value: _vm.MM0MovimentoDocumentoAnno,
                        callback: function ($$v) {
                          _vm.MM0MovimentoDocumentoAnno = $$v
                        },
                        expression: "MM0MovimentoDocumentoAnno",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "riga" },
                  [
                    _c("p", { staticClass: "titleStyle" }, [_vm._v(" Mese ")]),
                    _c("v-select", {
                      staticStyle: {
                        "padding-right": "5px",
                        "padding-left": "5px",
                      },
                      attrs: {
                        items: _vm.mesi,
                        "item-text": "descrizione",
                        "item-value": "indice",
                        label: "Scegli mese",
                      },
                      model: {
                        value: _vm.MM0MovimentoDocumentoMese,
                        callback: function ($$v) {
                          _vm.MM0MovimentoDocumentoMese = $$v
                        },
                        expression: "MM0MovimentoDocumentoMese",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("p", { staticClass: "titleStyle" }, [
                      _vm._v(" Documento "),
                    ]),
                    _c("v-autocomplete", {
                      staticStyle: {
                        "padding-right": "5px",
                        "padding-left": "5px",
                      },
                      attrs: {
                        items: _vm.documenti,
                        "item-text": "nomefileoriginario",
                        "item-value": "idfile",
                        label: "Scegli",
                        "no-data-text": "Nessun Documento Trovato",
                      },
                      model: {
                        value: _vm.MM0MovimentoIdDocumento,
                        callback: function ($$v) {
                          _vm.MM0MovimentoIdDocumento = $$v
                        },
                        expression: "MM0MovimentoIdDocumento",
                      },
                    }),
                    _c(
                      "p",
                      { staticStyle: { float: "left", "margin-top": "5px" } },
                      [_vm._v("Visualizza Documento")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "flexcol",
                        staticStyle: {
                          margin: "0px 5px 0px 5px",
                          float: "right",
                        },
                        attrs: { icon: "" },
                        on: { click: _vm.openDoc },
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("p", { staticClass: "filterStyle" }, [
        _vm._v(" Filtra Movimenti e sotto movimenti "),
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "table",
          { staticClass: "tableStyle", staticStyle: { margin: "10px" } },
          [
            _c("tr", [
              _c(
                "td",
                { staticClass: "riga", staticStyle: { width: "20%" } },
                [
                  _c("p", { staticClass: "titleStyle" }, [
                    _vm._v(" Ragione Sociale "),
                  ]),
                  _c("v-autocomplete", {
                    staticStyle: {
                      "padding-right": "10px",
                      "padding-left": "15px",
                    },
                    attrs: {
                      items: _vm.stakeholders,
                      "item-text": "ragionesociale",
                      "item-value": "sapid",
                      label: "Scegli",
                      dense: "",
                    },
                    model: {
                      value: _vm.MM0MovimentoRagioneSociale,
                      callback: function ($$v) {
                        _vm.MM0MovimentoRagioneSociale = $$v
                      },
                      expression: "MM0MovimentoRagioneSociale",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticClass: "riga", staticStyle: { width: "25%" } }, [
                _c("p", { staticClass: "titleStyle" }, [
                  _vm._v(" Data Operazione "),
                ]),
                _vm.showSelect_M
                  ? _c("div", [
                      _c(
                        "select",
                        {
                          staticClass: "dataStyle",
                          staticStyle: { "text-align": "center" },
                          on: {
                            click: function ($event) {
                              return _vm.changeSelectMovimenti($event)
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(" - "),
                          ]),
                          _c("option", { attrs: { value: "mese" } }, [
                            _vm._v(" Ultimo mese"),
                          ]),
                          _c("option", { attrs: { value: "6_mesi" } }, [
                            _vm._v(" Ultimi 6 mesi"),
                          ]),
                          _c("option", { attrs: { value: "Scegli" } }, [
                            _vm._v(" Scegli data"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                !_vm.showSelect_M
                  ? _c("div", [
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%", float: "left" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuDA_M",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateDA_Mv,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateDA_Mv = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateDA_Mv = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateDA_M,
                                                  callback: function ($$v) {
                                                    _vm.dateDA_M = $$v
                                                  },
                                                  expression: "dateDA_M",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2973678122
                              ),
                              model: {
                                value: _vm.menuDA_M,
                                callback: function ($$v) {
                                  _vm.menuDA_M = $$v
                                },
                                expression: "menuDA_M",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateDA_M,
                                    callback: function ($$v) {
                                      _vm.dateDA_M = $$v
                                    },
                                    expression: "dateDA_M",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuDA_M = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuDA_M.save(
                                            _vm.dateDA_M
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuA_M",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateA_M,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateA_M = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateA_M = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateA_M,
                                                  callback: function ($$v) {
                                                    _vm.dateA_M = $$v
                                                  },
                                                  expression: "dateA_M",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2013979950
                              ),
                              model: {
                                value: _vm.menuA_M,
                                callback: function ($$v) {
                                  _vm.menuA_M = $$v
                                },
                                expression: "menuA_M",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateA_M,
                                    callback: function ($$v) {
                                      _vm.dateA_M = $$v
                                    },
                                    expression: "dateA_M",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuA_M = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuA_M.save(
                                            _vm.dateA_M
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "td",
                { staticClass: "riga", staticStyle: { width: "15%" } },
                [
                  _c("p", { staticClass: "titleStyle" }, [_vm._v(" Valuta ")]),
                  _c("v-autocomplete", {
                    staticStyle: {
                      "padding-right": "5px",
                      "padding-left": "5px",
                    },
                    attrs: {
                      items: _vm.valute,
                      "item-text": "descrizione",
                      "item-value": "codice_iso",
                      label: "Scegli",
                    },
                    model: {
                      value: _vm.MM0MovimentoValuta,
                      callback: function ($$v) {
                        _vm.MM0MovimentoValuta = $$v
                      },
                      expression: "MM0MovimentoValuta",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticStyle: { width: "20%" } }, [
                _c("p", { staticClass: "titleStyle" }, [
                  _vm._v(" Conto bancario "),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.MM0MovimentoContoBancario,
                      expression: "MM0MovimentoContoBancario",
                    },
                  ],
                  staticClass: "dataStyle",
                  attrs: { type: "text" },
                  domProps: { value: _vm.MM0MovimentoContoBancario },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.MM0MovimentoContoBancario = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
      ]),
      _c("p", { staticClass: "filterStyle" }, [_vm._v(" Filtra Partite ")]),
      _c("div", { staticClass: "container" }, [
        _c(
          "table",
          {
            staticClass: "tableStyle",
            staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
          },
          [
            _c("tr", [
              _c(
                "td",
                { staticClass: "riga", staticStyle: { width: "20%" } },
                [
                  _c("p", { staticClass: "titleStyle" }, [
                    _vm._v(" Ragione Sociale "),
                  ]),
                  _c("v-autocomplete", {
                    staticStyle: {
                      "padding-right": "10px",
                      "padding-left": "15px",
                    },
                    attrs: {
                      items: _vm.stakeholders,
                      "item-text": "ragionesociale",
                      "item-value": "sapid",
                      label: "Scegli",
                      dense: "",
                    },
                    model: {
                      value: _vm.MM0PartitaRagioneSociale,
                      callback: function ($$v) {
                        _vm.MM0PartitaRagioneSociale = $$v
                      },
                      expression: "MM0PartitaRagioneSociale",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticClass: "riga", staticStyle: { width: "20%" } }, [
                _c("p", { staticClass: "titleStyle" }, [
                  _vm._v(" Data creazione partita "),
                ]),
                _vm.showSelect_CP
                  ? _c("div", [
                      _c(
                        "select",
                        {
                          staticClass: "dataStyle",
                          staticStyle: { "text-align": "center" },
                          on: {
                            click: function ($event) {
                              return _vm.changeSelectCreazionePartita($event)
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(" - "),
                          ]),
                          _c("option", { attrs: { value: "mese" } }, [
                            _vm._v(" Ultimo mese"),
                          ]),
                          _c("option", { attrs: { value: "6_mesi" } }, [
                            _vm._v(" Ultimi 6 mesi"),
                          ]),
                          _c("option", { attrs: { value: "Scegli" } }, [
                            _vm._v(" Scegli data"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                !_vm.showSelect_CP
                  ? _c("div", [
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%", float: "left" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuDA_CP",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateDA_CP,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateDA_CP = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateDA_CP = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateDA_CP,
                                                  callback: function ($$v) {
                                                    _vm.dateDA_CP = $$v
                                                  },
                                                  expression: "dateDA_CP",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                963120980
                              ),
                              model: {
                                value: _vm.menuDA_CP,
                                callback: function ($$v) {
                                  _vm.menuDA_CP = $$v
                                },
                                expression: "menuDA_CP",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateDA_CP,
                                    callback: function ($$v) {
                                      _vm.dateDA_CP = $$v
                                    },
                                    expression: "dateDA_CP",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuDA_CP = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuDA_CP.save(
                                            _vm.dateDA_CP
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuA_CP",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateA_CP,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateA_CP = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateA_CP = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateA_CP,
                                                  callback: function ($$v) {
                                                    _vm.dateA_CP = $$v
                                                  },
                                                  expression: "dateA_CP",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3766371024
                              ),
                              model: {
                                value: _vm.menuA_CP,
                                callback: function ($$v) {
                                  _vm.menuA_CP = $$v
                                },
                                expression: "menuA_CP",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateA_CP,
                                    callback: function ($$v) {
                                      _vm.dateA_CP = $$v
                                    },
                                    expression: "dateA_CP",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuA_CP = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuA_CP.save(
                                            _vm.dateA_CP
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("td", { staticClass: "riga", staticStyle: { width: "20%" } }, [
                _c("p", { staticClass: "titleStyle" }, [
                  _vm._v(" Data scadenza pagamento "),
                ]),
                _vm.showSelect_SP
                  ? _c("div", [
                      _c(
                        "select",
                        {
                          staticClass: "dataStyle",
                          staticStyle: { "text-align": "center" },
                          on: {
                            click: function ($event) {
                              return _vm.changeSelectScadenzaPartita($event)
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                          _c("option", { attrs: { value: "mese" } }, [
                            _vm._v(" Ultimo mese"),
                          ]),
                          _c("option", { attrs: { value: "6_mesi" } }, [
                            _vm._v(" Ultimi 6 mesi"),
                          ]),
                          _c("option", { attrs: { value: "Scegli" } }, [
                            _vm._v(" Scegli data"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                !_vm.showSelect_SP
                  ? _c("div", [
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%", float: "left" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuDA_SP",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateDA_SP,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateDA_SP = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateDA_SP = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateDA_SP,
                                                  callback: function ($$v) {
                                                    _vm.dateDA_SP = $$v
                                                  },
                                                  expression: "dateDA_SP",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3309771076
                              ),
                              model: {
                                value: _vm.menuDA_SP,
                                callback: function ($$v) {
                                  _vm.menuDA_SP = $$v
                                },
                                expression: "menuDA_SP",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateDA_SP,
                                    callback: function ($$v) {
                                      _vm.dateDA_SP = $$v
                                    },
                                    expression: "dateDA_SP",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuDA_SP = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuDA_SP.save(
                                            _vm.dateDA_SP
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { "max-width": "50%" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menuA_SP",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dateA_SP,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dateA_SP = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dateA_SP = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  "background-color":
                                                    "rgba(255, 255, 255, 0.505)",
                                                },
                                                model: {
                                                  value: _vm.dateA_SP,
                                                  callback: function ($$v) {
                                                    _vm.dateA_SP = $$v
                                                  },
                                                  expression: "dateA_SP",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4162652352
                              ),
                              model: {
                                value: _vm.menuA_SP,
                                callback: function ($$v) {
                                  _vm.menuA_SP = $$v
                                },
                                expression: "menuA_SP",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.dateA_SP,
                                    callback: function ($$v) {
                                      _vm.dateA_SP = $$v
                                    },
                                    expression: "dateA_SP",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuA_SP = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menuA_SP.save(
                                            _vm.dateA_SP
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "td",
                { staticStyle: { width: "10%" } },
                [
                  _c("p", { staticClass: "titleStyle" }, [_vm._v(" Valuta ")]),
                  _c("v-autocomplete", {
                    staticStyle: {
                      "padding-right": "10px",
                      "padding-left": "15px",
                    },
                    attrs: {
                      items: _vm.valute,
                      "item-text": "descrizione",
                      "item-value": "codice_iso",
                      label: "Scegli",
                    },
                    model: {
                      value: _vm.MM0PartitaValuta,
                      callback: function ($$v) {
                        _vm.MM0PartitaValuta = $$v
                      },
                      expression: "MM0PartitaValuta",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "filterStyle" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btnCercaStyle",
              attrs: { id: "btnCerca" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.cerca.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [_vm._v("mdi-magnify")]),
              _c("span", [_vm._v(" cerca")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm.showModalDoc
      ? _c(
          "div",
          [
            _c(
              "DocOpened",
              {
                attrs: { idDoc: _vm.MM0MovimentoIdDocumento, theme: "" },
                on: { close: _vm.openDoc },
              },
              [
                _c("div"),
                _c(
                  "v-btn",
                  {
                    staticClass: "btnOkStyle",
                    attrs: { elevation: "0" },
                    on: { click: _vm.openDoc },
                  },
                  [_vm._v("ok")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }