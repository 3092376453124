<template>
  <span>
      <v-btn 
        color="primary"
        dark
        block
        style="margin-left:5px; margin-right:5px"
        @click="btnClickedHandler()">{{prompto }}</v-btn>
  </span>
</template>


<script>
  export default {
    data () {
      return {
      }
    },
    methods: {
      btnClickedHandler() {
        this.params.clicked(this.params);
      }
    }, 
    computed: {
      prompto: function () {
        if(this.params.prompt) {
          return this.params.prompt
        } else {
          return (this.params.value) ? "Attiva" : "Disattiva"
        }
      },
    }

  }
</script>
