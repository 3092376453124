var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      false ? _c("InfoDialog", { on: { openDoc: _vm.openDoc } }) : _vm._e(),
      false ? _c("ButtonRenderer") : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "divRigaStyle",
          staticStyle: { "min-height": "300px" },
          attrs: { "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "p",
              {
                staticClass: "text-h6 text--primary",
                staticStyle: { "margin-top": "30px", "margin-left": "30px" },
              },
              [
                _vm._v(" Scegli Movimento "),
                _c(
                  "v-tooltip",
                  {
                    staticStyle: { "max-width": "500px" },
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: { size: "30" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-help-circle-outline")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "body-1",
                        staticStyle: { "max-width": "500px" },
                      },
                      [
                        _vm._v(
                          ' Scegliere movimento selezionando la riga in tabella, poi confermare / modificare la ragione sociale associata al movimento dal Matcher. Quindi selezionare una o piu\' partite e poi chiudere la partia eventualmente aggiungendo degli aggiustamenti di chiusura. Cliccare su "disattiva" sui movimenti gestiti esternamente al matcher. '
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "text-h8 text--primary",
                staticStyle: { "margin-left": "30px" },
              },
              [
                _vm._v(
                  " Filtrati: " +
                    _vm._s(_vm.num_filtrati_movimenti) +
                    " Scaricati: " +
                    _vm._s(_vm.num_scaricati_movimenti) +
                    " / Totali: " +
                    _vm._s(_vm.num_tot_movimenti) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c("v-switch", {
                    staticClass: "text-right mr-9 mt-10",
                    attrs: { label: "anche disattivati" },
                    on: {
                      change: function ($event) {
                        return _vm.changeStateDisattivati()
                      },
                    },
                    model: {
                      value: _vm.ancheDisattivati,
                      callback: function ($$v) {
                        _vm.ancheDisattivati = $$v
                      },
                      expression: "ancheDisattivati",
                    },
                  }),
                  _c("v-switch", {
                    staticClass: "text-right mr-9 mt-10",
                    attrs: { label: "Includi abbinati" },
                    on: {
                      change: function ($event) {
                        return _vm.changeState()
                      },
                    },
                    model: {
                      value: _vm.includiAbbinati,
                      callback: function ($$v) {
                        _vm.includiAbbinati = $$v
                      },
                      expression: "includiAbbinati",
                    },
                  }),
                  _c(
                    "v-tooltip",
                    {
                      staticStyle: { "max-width": "500px" },
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-2 mt-5",
                                      attrs: { size: "30" },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-help-circle-outline")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "body-1",
                          staticStyle: { "max-width": "500px" },
                        },
                        [
                          _vm._v(
                            " La tabella mostra tutti i movimenti validi, associati ad un movimento bancario, che non sono già parte di un match proposto. E' possibile mostrare anche i movimenti disattivati o quelli presenti in match proposti. "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-right ml-5 mr-9 mt-10",
                      on: { click: _vm.reloadMovimenti },
                    },
                    [_vm._v(" Reload Movimenti ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-left": "50px",
                    "margin-right": "30px",
                    "margin-bottom": "30px",
                  },
                },
                [_vm._v(" Tipo Documento ")]
              ),
              _c("v-select", {
                staticStyle: {
                  "padding-right": "5px",
                  "padding-left": "5px",
                  "max-width": "300px",
                },
                attrs: {
                  clearable: "",
                  items: _vm.tipidocumento,
                  "item-text": "descrizione",
                  "item-value": "descrizione",
                  label: "Scegli Documento",
                },
                model: {
                  value: _vm.documentoDaCercare,
                  callback: function ($$v) {
                    _vm.documentoDaCercare = $$v
                  },
                  expression: "documentoDaCercare",
                },
              }),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-left": "50px",
                    "margin-right": "30px",
                    "margin-bottom": "30px",
                  },
                },
                [_vm._v(" Ricerca: ")]
              ),
              _c("v-text-field", {
                staticStyle: {
                  "padding-right": "5px",
                  "padding-left": "5px",
                  "max-width": "400px",
                },
                attrs: { "background-color": "rgba(255, 255, 255, 0.505)" },
                model: {
                  value: _vm.ricercaLibera,
                  callback: function ($$v) {
                    _vm.ricercaLibera = $$v
                  },
                  expression: "ricercaLibera",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "text-right ml-5 mt-4",
                  on: { click: _vm.exportCsv },
                },
                [_vm._v(" Export CSV ")]
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-alpine",
            staticStyle: {
              width: "95%",
              "margin-left": "30px",
              "margin-top": "30px",
              "max-height": "500px",
            },
            attrs: {
              columnDefs: _vm.headersMovimenti,
              rowData: _vm.movimenti,
              rowSelection: "single",
              defaultColDef: _vm.defaultColDef,
              gridOptions: _vm.gridOptions,
              isExternalFilterPresent: _vm.isExternalFilterPresent,
              doesExternalFilterPass: _vm.doesExternalFilterPass,
            },
            on: { "row-selected": _vm.onRowSelected },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { "x-small": "" },
                      on: { click: _vm.saveState },
                    },
                    [_vm._v(" Save State ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { "x-small": "" },
                      on: { click: _vm.loadState },
                    },
                    [_vm._v(" Load State ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { "x-small": "" },
                      on: { click: _vm.resetState },
                    },
                    [_vm._v(" Reset State ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showModalDoc
        ? _c(
            "div",
            [
              _c(
                "DocOpened",
                {
                  attrs: { idDoc: _vm.currentDoc, theme: "" },
                  on: { close: _vm.openDoc },
                },
                [
                  _c("div"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0" },
                      on: { click: _vm.openDoc },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PopUpMovimento", {
        attrs: {
          movimentoId: _vm.movimentoPopup,
          showModal: _vm.showModalMovimento,
        },
        on: {
          openDoc: function ($event) {
            return _vm.openDoc($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.dialogAnticipo,
                callback: function ($$v) {
                  _vm.dialogAnticipo = $$v
                },
                expression: "dialogAnticipo",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { padding: "20px" } },
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" Scegli dettagli anticipo "),
                  ]),
                  _c("v-select", {
                    staticStyle: {
                      width: "90%",
                      "padding-top": "50px",
                      margin: "auto",
                    },
                    attrs: {
                      items: _vm.direzioniCommerciali,
                      "item-text": "description",
                      "item-value": "code",
                      label: "Direzione Commerciale",
                    },
                    model: {
                      value: _vm.direzione_commerciale,
                      callback: function ($$v) {
                        _vm.direzione_commerciale = $$v
                      },
                      expression: "direzione_commerciale",
                    },
                  }),
                  _c("v-select", {
                    staticStyle: { width: "90%", margin: "auto" },
                    attrs: {
                      items: _vm.profitCenters,
                      "item-text": "description",
                      "item-value": "code",
                      label: "Profit Center",
                    },
                    model: {
                      value: _vm.profit_center,
                      callback: function ($$v) {
                        _vm.profit_center = $$v
                      },
                      expression: "profit_center",
                    },
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { "margin-right": "50px" },
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.completaCreaAnticipo()
                                },
                              },
                            },
                            [_vm._v("Submit")]
                          ),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogAnticipo = false
                                  _vm.direzione_commerciale = ""
                                  _vm.profit_center = ""
                                },
                              },
                            },
                            [_vm._v("Annulla")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }