var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-snackbar",
            {
              staticStyle: { "margin-top": "70px" },
              attrs: { top: "", color: "success" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function (ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { color: "white", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.snackbar = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Close ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v(" " + _vm._s(_vm.SnackBarText) + " ")]
          ),
          _c("WizardMovimenti", {
            attrs: {
              generatoreEventi: _vm.ping,
              refreshIt: _vm.refreshMovimento,
            },
            on: { setMovimento: _vm.setMovimento },
          }),
          _c("WizardRagioneSociale", {
            attrs: {
              sapIDCorrente: _vm.ragioneSocialeSelezionata,
              movimentoSelezionato: _vm.movimentoSelezionato,
            },
            on: { setRagioneSociale: _vm.setRagioneSociale },
          }),
          _c("WizardPartite", {
            attrs: {
              ragioneSocialeConfermata: _vm.ragioneSocialeConfermata,
              movimentoCorrente: _vm.movimentoCorrente,
            },
            on: { movimentoChiuso: _vm.movimentoChiuso },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }