var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.ragioneSocialeAttivata,
          expression: "ragioneSocialeAttivata",
        },
      ],
      style: _vm.ragioneSocialeAttivata
        ? "height: 100%;padding-left:10px;padding-right:10px"
        : "height: 1500px",
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("v-row", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.ragioneSocialeAttivata,
                expression: "!ragioneSocialeAttivata",
              },
            ],
            staticStyle: { "min-height": "60px" },
          }),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ragioneSocialeAttivata,
                  expression: "ragioneSocialeAttivata",
                },
              ],
              staticClass: "text-h6 text--primary",
              staticStyle: { "min-height": "30px" },
            },
            [_vm._v(" Aggiungi o togli partite al match. ")]
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ragioneSocialeAttivata,
                  expression: "ragioneSocialeAttivata",
                },
              ],
              staticStyle: { "min-height": "25px" },
            },
            [
              _vm._v(
                " Alla chiusura il match proposto verrà dissociato e sostituito con il nuovo match "
              ),
            ]
          ),
          _c(
            "v-row",
            {
              staticClass: "wizardPartiteStyle",
              staticStyle: { height: "900px" },
              attrs: { id: "scatola" },
            },
            [
              _c(
                "v-col",
                [
                  _c("v-row", [
                    _c(
                      "p",
                      {
                        staticClass: "text-h6 text--primary",
                        staticStyle: { "margin-top": "30px" },
                      },
                      [_vm._v(" Scegli Partita ")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticStyle: { "min-height": "60px" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "text-h8 text--primary",
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _vm._v(
                            " Filtrati: " +
                              _vm._s(_vm.num_scaricate_partite) +
                              " / Totali: " +
                              _vm._s(_vm.num_tot_partite)
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            "margin-top": "10px",
                            "margin-right": "30px",
                            "margin-bottom": "30px",
                          },
                        },
                        [_vm._v(" Ricerca: ")]
                      ),
                      _c("v-text-field", {
                        staticStyle: {
                          "padding-top": "0px",
                          "padding-right": "5px",
                          "padding-left": "5px",
                          "max-width": "400px",
                        },
                        attrs: {
                          "background-color": "rgba(255, 255, 255, 0.505)",
                        },
                        model: {
                          value: _vm.ricercaLibera,
                          callback: function ($$v) {
                            _vm.ricercaLibera = $$v
                          },
                          expression: "ricercaLibera",
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            "margin-top": "10px",
                            "margin-right": "10px",
                            "margin-bottom": "30px",
                            color: "rgba(0, 0, 0, 0.6)",
                          },
                        },
                        [_vm._v(" Non in match proposti: ")]
                      ),
                      _c("v-switch", {
                        staticClass: "text-right mr-2",
                        staticStyle: { "margin-top": "5px" },
                        attrs: { label: "tutte" },
                        model: {
                          value: _vm.changeStateTutteLePartite,
                          callback: function ($$v) {
                            _vm.changeStateTutteLePartite = $$v
                          },
                          expression: "changeStateTutteLePartite",
                        },
                      }),
                      !_vm.partiteInIngresso
                        ? _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                "margin-top": "10px",
                                "margin-right": "10px",
                                "margin-bottom": "30px",
                                color: "rgba(0, 0, 0, 0.6)",
                              },
                            },
                            [_vm._v(" Anche chiuse: ")]
                          )
                        : _vm._e(),
                      !_vm.partiteInIngresso
                        ? _c("v-switch", {
                            staticClass: "text-right mr-2",
                            staticStyle: { "margin-top": "5px" },
                            attrs: { label: "solo aperte" },
                            model: {
                              value: _vm.changeStateSoloAperte,
                              callback: function ($$v) {
                                _vm.changeStateSoloAperte = $$v
                              },
                              expression: "changeStateSoloAperte",
                            },
                          })
                        : _vm._e(),
                      !_vm.partiteInIngresso
                        ? _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                "margin-top": "10px",
                                "margin-right": "10px",
                                "margin-bottom": "30px",
                                color: "rgba(0, 0, 0, 0.6)",
                              },
                            },
                            [_vm._v(" Solo valuta Mov: ")]
                          )
                        : _vm._e(),
                      !_vm.partiteInIngresso
                        ? _c("v-switch", {
                            staticClass: "text-right mr-2",
                            staticStyle: { "margin-top": "5px" },
                            attrs: { label: "tutte valute" },
                            model: {
                              value: _vm.changeStateTutteValute,
                              callback: function ($$v) {
                                _vm.changeStateTutteValute = $$v
                              },
                              expression: "changeStateTutteValute",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-4",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { md: "9" },
                        },
                        [
                          _c("ag-grid-vue", {
                            staticClass: "ag-theme-alpine",
                            style: { width: _vm.width, height: _vm.height },
                            attrs: {
                              columnDefs: _vm.headersPartite,
                              rowData: _vm.partite,
                              rowSelection: "multiple",
                              defaultColDef: _vm.defaultColDef,
                              gridOptions: _vm.gridOptions,
                              pinnedTopRowData: _vm.pinnedTopRowData,
                              getRowStyle: _vm.getRowStyle,
                            },
                            on: { "row-selected": _vm.onRowSelected },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ragioneSocialeAttivata,
                              expression: "ragioneSocialeAttivata",
                            },
                          ],
                          staticStyle: {},
                          attrs: { md: "2" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                "max-width": "300px",
                                "min-width": "300px",
                              },
                            },
                            [
                              _c("v-card-text", [
                                _c(
                                  "p",
                                  { staticClass: "text-h6 text--primary" },
                                  [_vm._v(" Saldo ")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-h7 text--primary" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.saldo) +
                                        " " +
                                        _vm._s(_vm.movimentoCorrente.valuta) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.partiteCorrenti.length == 0 ||
                                          _vm.saldo === 0,
                                        id: "btnchiudiMatch",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.chiudiMatch.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "25" } }, [
                                        _vm._v("mdi-auto-fix"),
                                      ]),
                                      _c("span", [_vm._v(" +- Differenze")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !(_vm.saldo === 0),
                                        id: "btnchiudiMatch",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.chiudiMatch.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "25" } }, [
                                        _vm._v("mdi-checkbox-marked-circle"),
                                      ]),
                                      _c("span", [_vm._v(" Chiudi Match")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto mt-5",
                              attrs: {
                                "max-width": "300px",
                                "min-width": "300px",
                              },
                            },
                            [
                              _c("v-card-text", [
                                _c(
                                  "p",
                                  { staticClass: "text-h6 text--primary" },
                                  [_vm._v(" Note Movimento ")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-h7 text--primary" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.movimentoCorrente.notemovimento
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-h7 text--primary" },
                                  [
                                    _vm._v(
                                      " Importo movimento: " +
                                        _vm._s(
                                          _vm.movimentoCorrente.valuta_importo
                                        ) +
                                        " " +
                                        _vm._s(_vm.movimentoCorrente.valuta) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.movimentoCorrente
                                  .is_sottomovimento_con_supermovimento_aggregato
                                  ? _c(
                                      "p",
                                      { staticClass: "text-h7 text--primary" },
                                      [
                                        _vm._v(
                                          " Importo supermovimento: " +
                                            _vm._s(
                                              _vm.importoSuperMovimento["value"]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("DifferenzeIncassiPartite", {
            attrs: {
              differenzeInIngresso: _vm.differenzeInIngresso,
              showModal: _vm.showModal,
              partite: _vm.partiteCorrenti,
              valuta: _vm.movimentoCorrente.valuta,
              saldo: _vm.saldo,
            },
            on: { setDifferenzeConti: _vm.setDifferenzeConti },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }