var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "", block: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Extra Info ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "600", ripple: false },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("v-card-text", [
                _c(
                  "p",
                  { staticClass: "text-h8 text--primary" },
                  [
                    _c("b", [_vm._v("Note:")]),
                    _vm._v(" " + _vm._s(_vm.params.note) + " "),
                    _c("br"),
                    _c("b", [_vm._v("Conto:")]),
                    _vm._v(" " + _vm._s(_vm.params.conto) + " "),
                    _c("br"),
                    _c("b", [_vm._v("Tipo:")]),
                    _vm._v(" " + _vm._s(_vm.params.tipo) + " "),
                    _c("br"),
                    _c("b", [_vm._v("ID Movimento:")]),
                    _vm._v(" " + _vm._s(_vm.params.idmovimento) + " "),
                    _c("br"),
                    _c("b", [_vm._v("ID Dettaglio Movimento:")]),
                    _vm._v(" " + _vm._s(_vm.params.idmovimentodettaglio) + " "),
                    _c("br"),
                    _c("b", [_vm._v("ID Documento:")]),
                    _vm._v(" " + _vm._s(_vm.params.iddocumento) + " "),
                    _c("br"),
                    _c("b", [_vm._v("Visualizza Documento")]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "flexcol",
                        staticStyle: { margin: "0px 5px 0px 5px" },
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openDoc(_vm.params.iddocumento)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                    _c("br"),
                    _vm.params.movimento
                      .is_sottomovimento_con_supermovimento_aggregato
                      ? _c("b", [
                          _vm._v(" Visualizza Macromovimento Aggregato:"),
                        ])
                      : _vm._e(),
                    _vm.params.movimento
                      .is_sottomovimento_con_supermovimento_aggregato
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "flexcol",
                            staticStyle: { margin: "0px 5px 0px 5px" },
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openMov(_vm.params.movimento)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("mdi-open-in-new"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }