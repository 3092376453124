var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          height: "100%",
          "text-align": "center",
          "margin-top": "10%",
        },
      },
      [
        _c("img", {
          staticClass: "imgStyle",
          attrs: { src: require("../assets/Home.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }