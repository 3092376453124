var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticStyle: { "margin-left": "18px" } }, [
      _c("tr", [
        _c(
          "td",
          [
            _c("v-select", {
              staticStyle: { "margin-top": "10px" },
              attrs: {
                clearable: "",
                multiple: "",
                dense: "",
                items: _vm.valute,
                "item-text": "descrizione",
                "item-value": "codice_iso",
                label: "Valuta",
              },
              model: {
                value: _vm.filtroMAValuta,
                callback: function ($$v) {
                  _vm.filtroMAValuta = $$v
                },
                expression: "filtroMAValuta",
              },
            }),
          ],
          1
        ),
        _c(
          "td",
          [
            _c("v-autocomplete", {
              staticStyle: {
                "margin-top": "10px",
                "padding-right": "30px",
                "padding-left": "15px",
                width: "300px",
                "max-width": "300px",
              },
              attrs: {
                items: _vm.stakeholders,
                "item-text": "ragionesociale",
                "item-value": "sapid",
                label: "Scegli Ragione Sociale",
                dense: "",
                multiple: "",
                clearable: "",
              },
              model: {
                value: _vm.filtroMARagioneSociale,
                callback: function ($$v) {
                  _vm.filtroMARagioneSociale = $$v
                },
                expression: "filtroMARagioneSociale",
              },
            }),
          ],
          1
        ),
        _c(
          "td",
          { staticStyle: { "margin-left": "30px", "max-width": "180px" } },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": false,
                  "nudge-right": 40,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  clearable: "",
                                  "prepend-icon": "mdi-calendar",
                                  hint: "DD/MM/YYYY format",
                                  "persistent-hint": "",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.dataDA = _vm.parseDate(
                                      _vm.filtroMADaData
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8em",
                                              },
                                            },
                                            [_vm._v("Da Data Movimento")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filtroMADaData,
                                  callback: function ($$v) {
                                    _vm.filtroMADaData = $$v
                                  },
                                  expression: "filtroMADaData",
                                },
                              },
                              "v-text-field",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.menu2,
                  callback: function ($$v) {
                    _vm.menu2 = $$v
                  },
                  expression: "menu2",
                },
              },
              [
                _c("v-date-picker", {
                  on: {
                    input: function ($event) {
                      _vm.menu2 = false
                    },
                  },
                  model: {
                    value: _vm.dataDA,
                    callback: function ($$v) {
                      _vm.dataDA = $$v
                    },
                    expression: "dataDA",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "td",
          { staticStyle: { "max-width": "180px" } },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": false,
                  "nudge-right": 40,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  clearable: "",
                                  "prepend-icon": "mdi-calendar",
                                  hint: "DD/MM/YYYY format",
                                  "persistent-hint": "",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.dataA = _vm.parseDate(_vm.filtroMAAData)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8em",
                                              },
                                            },
                                            [_vm._v("A Data Movimento")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filtroMAAData,
                                  callback: function ($$v) {
                                    _vm.filtroMAAData = $$v
                                  },
                                  expression: "filtroMAAData",
                                },
                              },
                              "v-text-field",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.menu1,
                  callback: function ($$v) {
                    _vm.menu1 = $$v
                  },
                  expression: "menu1",
                },
              },
              [
                _c("v-date-picker", {
                  on: {
                    input: function ($event) {
                      _vm.menu1 = false
                    },
                  },
                  model: {
                    value: _vm.dataA,
                    callback: function ($$v) {
                      _vm.dataA = $$v
                    },
                    expression: "dataA",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "td",
          { staticStyle: { "margin-left": "0px", "max-width": "180px" } },
          [
            _c(
              "v-tooltip",
              {
                staticStyle: { "max-width": "500px" },
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "ml-1 mt-4",
                                attrs: { size: "30" },
                              },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v("mdi-help-circle-outline")]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "body-1",
                    staticStyle: { "max-width": "500px" },
                  },
                  [
                    _vm._v(
                      " La data ricercata si riferisce alla data del movimento. "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "td",
          { staticStyle: { "margin-left": "60px", "max-width": "180px" } },
          [
            _c("v-text-field", {
              staticClass: "ml-3",
              attrs: { clearable: "", hint: "importo", label: "Cerca Importo" },
              model: {
                value: _vm.filtroMAImporto,
                callback: function ($$v) {
                  _vm.filtroMAImporto = $$v
                },
                expression: "filtroMAImporto",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }