var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-snackbar",
            {
              staticStyle: { "margin-top": "70px" },
              attrs: { top: "", color: "success" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function (ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { color: "white", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.snackbar = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Close ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v(" " + _vm._s(_vm.SnackBarText) + " ")]
          ),
          _c(
            "div",
            { staticStyle: { height: "1000px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-left": "20px",
                      },
                    },
                    [_vm._v(" Ragione Sociale: ")]
                  ),
                  _c("v-autocomplete", {
                    staticStyle: {
                      "max-width": "450px",
                      "margin-left": "10px",
                      "padding-right": "10px",
                      "padding-left": "15px",
                    },
                    attrs: {
                      items: _vm.stakeholders,
                      "item-text": _vm.getItemText,
                      "item-value": "sapid",
                      label: "Scegli",
                      dense: "",
                    },
                    model: {
                      value: _vm.ragioneSocialeSelezionata,
                      callback: function ($$v) {
                        _vm.ragioneSocialeSelezionata = $$v
                      },
                      expression: "ragioneSocialeSelezionata",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "btnCambiaRS" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.cambiaRS.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "25" } }, [
                        _vm._v("mdi-checkbox-marked-circle"),
                      ]),
                      _c("span", [_vm._v(" Cerca Partite ")]),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-left": "100px",
                        "margin-right": "30px",
                      },
                    },
                    [_vm._v(" Ricerca: ")]
                  ),
                  _c("v-text-field", {
                    staticStyle: {
                      "padding-top": "0px",
                      "padding-right": "5px",
                      "padding-left": "5px",
                      "max-width": "300px",
                    },
                    attrs: { "background-color": "rgba(255, 255, 255, 0.505)" },
                    model: {
                      value: _vm.ricercaLibera,
                      callback: function ($$v) {
                        _vm.ricercaLibera = $$v
                      },
                      expression: "ricercaLibera",
                    },
                  }),
                  _c("v-switch", {
                    staticClass: "text-right mr-9;",
                    staticStyle: {
                      "margin-left": "20px",
                      "margin-top": "10px",
                      "padding-top": "0px",
                    },
                    attrs: { label: "Anche Abbinate" },
                    on: {
                      change: function ($event) {
                        return _vm.changeState()
                      },
                    },
                    model: {
                      value: _vm.ancheAbbinati,
                      callback: function ($$v) {
                        _vm.ancheAbbinati = $$v
                      },
                      expression: "ancheAbbinati",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "wizardPartiteStyle",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("p", { staticClass: "text-h8 text--primary" }, [
                    _vm._v(
                      " Filtrate: " +
                        _vm._s(_vm.num_filtrate_partite) +
                        " / Scaricati: " +
                        _vm._s(_vm.num_scaricate_partite) +
                        " / Totali: " +
                        _vm._s(_vm.num_tot_partite)
                    ),
                  ]),
                  _c("ag-grid-vue", {
                    staticClass: "ag-theme-alpine",
                    staticStyle: { width: "100%", "min-height": "500px" },
                    attrs: {
                      columnDefs: _vm.headersPartite,
                      rowData: _vm.partite,
                      defaultColDef: _vm.defaultColDef,
                      gridOptions: _vm.gridOptions,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }