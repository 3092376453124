var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", [
      _c("tr", [
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "Ragione Sociale" },
                model: {
                  value: _vm.filtroPartitaRagioneSociale,
                  callback: function ($$v) {
                    _vm.filtroPartitaRagioneSociale = $$v
                  },
                  expression: "filtroPartitaRagioneSociale",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 3 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "SapId" },
                model: {
                  value: _vm.filtroPartitaSAPId,
                  callback: function ($$v) {
                    _vm.filtroPartitaSAPId = $$v
                  },
                  expression: "filtroPartitaSAPId",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 3 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "Importo" },
                model: {
                  value: _vm.filtroPartitaImporto,
                  callback: function ($$v) {
                    _vm.filtroPartitaImporto = $$v
                  },
                  expression: "filtroPartitaImporto",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 2 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "PartitaID" },
                model: {
                  value: _vm.filtroPartitaPartitaID,
                  callback: function ($$v) {
                    _vm.filtroPartitaPartitaID = $$v
                  },
                  expression: "filtroPartitaPartitaID",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 3 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }