var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ml-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [_c("Select", { attrs: { chiusi: false } })],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "2" } },
            [
              _c("v-checkbox", {
                attrs: { label: "Sort Condizioni Pagamento" },
                model: {
                  value: _vm.sortCondPag,
                  callback: function ($$v) {
                    _vm.sortCondPag = $$v
                  },
                  expression: "sortCondPag",
                },
              }),
              _c(
                "v-tooltip",
                {
                  staticStyle: { "max-width": "500px" },
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-2 mr-4",
                                  attrs: { size: "40" },
                                },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("mdi-help-circle-outline")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "body-1",
                      staticStyle: { "max-width": "500px" },
                    },
                    [
                      _vm._v(
                        " Ordina i match in base al saldo (utilizza il saldo semplice se la checkbox e' vuota, mentre il saldo con le condizioni di pagamento se la checkbox e' selezionata). "
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cambiaSort.apply(null, arguments)
                    },
                  },
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.sortDirection) + " ")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-container", { staticClass: "containerStyle" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "table",
            { staticStyle: { display: "table", width: "100%" } },
            [
              _vm.matchesVisualizzati.length == 0
                ? _c("div", [
                    _c("p", [
                      _c("i", [
                        _vm._v(
                          "Nessun match trovato per i parametri di ricerca."
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.matchesVisualizzati, function (match) {
                return _c("div", { key: match.idmatch, staticClass: "match" }, [
                  _c(
                    "tr",
                    { staticStyle: { display: "table", width: "100%" } },
                    [
                      _c(
                        "td",
                        { staticStyle: { float: "left", width: "35%" } },
                        _vm._l(match.movimenti, function (movimento) {
                          return _c(
                            "div",
                            {
                              key:
                                String(match.idmatch) +
                                String(movimento.artificial_id_movimento),
                              staticStyle: { float: "left", width: "100%" },
                            },
                            [
                              _c("v-icon", { attrs: { size: "26" } }, [
                                _vm._v("mdi-file"),
                              ]),
                              _c("strong", {
                                staticStyle: { "font-size": "15px" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "datiStyle",
                                  staticStyle: {
                                    width: "100%",
                                    "overflow-wrap": "break-word",
                                  },
                                  attrs: { id: "datiMovimento" },
                                },
                                [
                                  _c("strong", [
                                    _c("p", [
                                      _vm._v("importo: "),
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(movimento.valuta_importo)
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v("data op: "),
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateShort")(
                                              movimento.dataoperazione
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v("valuta: "),
                                      _c("i", [
                                        _vm._v(_vm._s(movimento.valuta)),
                                      ]),
                                    ]),
                                  ]),
                                  movimento.is_sottomovimento_con_supermovimento_aggregato
                                    ? _c("p", [
                                        _vm._v(
                                          " Importo supermovimento: " +
                                            _vm._s(
                                              _vm.importoSuperMovimento[
                                                movimento.iddbmovimento
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "overflow-wrap": "break-word",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm._v("note: "),
                                      _c(
                                        "i",
                                        {
                                          attrs: {
                                            title: movimento.notemovimento,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatTruncate")(
                                                movimento.notemovimento,
                                                250,
                                                "..."
                                              )
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            float: "left",
                            "vertical-align": "middle",
                            width: "30%",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "matchassociated2",
                                  params: {
                                    id: match.idmatch,
                                    saldo: match.saldo,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btnImgStyle",
                                  attrs: {
                                    title:
                                      "score: " +
                                      match.score +
                                      " \nNote match: " +
                                      match.scorepartite,
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "imgMatchListStyle",
                                    attrs: {
                                      src: require("../assets/ImgMatchAssociatiLista.png"),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("p", { staticStyle: { float: "none" } }, [
                                    match.saldo == 0
                                      ? _c("input", {
                                          staticStyle: {
                                            transform: "scale(1.4)",
                                            margin: "10px",
                                          },
                                          attrs: {
                                            type: "checkbox",
                                            id: match.idmatch,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.check(match.idmatch)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v("Saldo: " + _vm._s(match.saldo)),
                                  ]),
                                  _c("p", { staticStyle: { float: "none" } }, [
                                    match.differenzamonetizzatainvalutaconimportoscontato ==
                                      0 && match.saldo != 0
                                      ? _c("input", {
                                          staticStyle: {
                                            transform: "scale(1.4)",
                                            margin: "10px",
                                          },
                                          attrs: {
                                            type: "checkbox",
                                            id: match.idmatch,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.check(match.idmatch)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "Saldo Cond.Pag.: " +
                                        _vm._s(
                                          match.differenzamonetizzatainvalutaconimportoscontato
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { float: "right", width: "35%" } },
                        _vm._l(match.partite, function (partita) {
                          return _c(
                            "div",
                            {
                              key: String(match.idmatch) + String(partita.iddb),
                              staticStyle: { float: "left" },
                            },
                            [
                              _c("v-icon", { attrs: { size: "26" } }, [
                                _vm._v("mdi-file"),
                              ]),
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "15px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatTruncate")(
                                          partita.ragionesociale,
                                          30,
                                          "..."
                                        )
                                      ) +
                                      " - " +
                                      _vm._s(partita.sapid)
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "datiStyle",
                                  attrs: { id: "datiMovimento" },
                                },
                                [
                                  _c("strong", [
                                    _c("p", [
                                      _vm._v("importo "),
                                      _c("i", [
                                        _vm._v(_vm._s(partita.valutalordo)),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v("data doc. "),
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateShort")(
                                              partita.datadocumento
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v("valuta "),
                                      _c("i", [_vm._v(_vm._s(partita.valuta))]),
                                    ]),
                                    _c("p", [
                                      _vm._v("ID Partita "),
                                      _c("i", [
                                        _vm._v(_vm._s(partita.numerodocumento)),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v("Stato "),
                                      _c("i", [_vm._v(_vm._s(partita.stato))]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("hr"),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "90%", margin: "auto" } },
        [
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Numero saldi 0: " +
                _vm._s(_vm.numero_zeri) +
                " / Numero saldi cond.pag 0: " +
                _vm._s(_vm.numero_zeri_scontati)
            ),
          ]),
          _c("br"),
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Selezionati: " +
                _vm._s(_vm.numero_match) +
                " / Scaricati: " +
                _vm._s(_vm.num_scaricati_match) +
                " / Totali: " +
                _vm._s(_vm.num_tot_match)
            ),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "pagination_button",
              attrs: {
                disabled: _vm.num_scaricati_match === _vm.num_tot_match,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadMoreMatches()
                },
              },
            },
            [_vm._v("Carica altri Match")]
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }