var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _vm.attivaRicerca
        ? _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c("v-text-field", {
                staticClass: "shrink",
                staticStyle: { width: "500px", "margin-right": "40px" },
                attrs: { label: "Parola/Frase da cercare" },
                model: {
                  value: _vm.ricercaLibera,
                  callback: function ($$v) {
                    _vm.ricercaLibera = $$v
                  },
                  expression: "ricercaLibera",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: { large: "", color: "primary", elevation: "0" },
                  on: { click: _vm.ricercaMatch },
                },
                [_vm._v(" Ricerca ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("SuperMatchList", { ref: "theMatchlist" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }