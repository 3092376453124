<template>
    <v-main>
        <div id="datiSuperMatch" class="datiStyle">
            <strong >
                <p style="margin-left:30%; font-size:30px">Saldo (incluse partite di pareggio): {{ saldoSMatch }} EURO</p>
              </strong>
              <strong >
                <p style="margin-left:10%">differenzamonetizzatainvalutaconimportoscontato: <i>{{ superMatch.differenzamonetizzatainvalutaconimportoscontato}}</i></p>
                <p style="margin-left:10%">score: <i> {{ superMatch.score}}</i></p>
                <p style="margin-left:10%">gri_scoring_penalizzazioni: <i>{{ superMatch.gri_scoring_penalizzazioni}}</i></p> 
              </strong>
              <SuperMatchListOverall :idVal="this.$route.params.id"></SuperMatchListOverall>
            </div>

        <v-btn elevation="0" class="btnChiudiStyle" @click="chiudiSMatch(false)">
            <span style="color: #D91A21">Chiudi Super Match</span>
        </v-btn>
        <br>
        <br>
        <br>
        <v-btn elevation="0" class="btnChiudiStyle" @click="dissociaSMatch">
            <span style="color: #D91A21">Dissocia Super Match</span>
        </v-btn>
        <DifferenzeIncassiPartite :partite="tuttePartite" :valuta="'EUR'" :showModal="showModalPartite" :saldo="saldoSMatch" :differenzeInIngresso="superMatch.partite_di_pareggio" @setDifferenzeConti="setDifferenzeConti"> </DifferenzeIncassiPartite>    
    </v-main>
</template>

<script>
import SuperMatchListOverall from "../components/SuperMatchListOverall.vue";
import EventBus, { ACTIONS } from '../components/Helpers/EventBus.js';
import DifferenzeIncassiPartite from '@/components/DifferenzeIncassiPartite.vue'

export default ({
    created(){
    },
    data(){
        return{
            superMatch: {},
            showModalPartite: 0,
        }
    },
    components: {
        SuperMatchListOverall,
        DifferenzeIncassiPartite
    },
    mounted() { 
        let theId = this.$route.params.id;
        console.log("Super Match for id: " + theId)
        this.superMatch = this.$store.getters["superM/getMatches"].items.find(match => match.idmatch === theId);
        console.log("Match associato: " + this.superMatch);
        this.$store.commit('auth/setIsLoading', false);
    },    
    methods:{
        async chiudiSMatch(done){
            console.log("chiudi match");
            console.log(this.superMatch);

            if(!done && this.saldoSMatch != 0) {
                // lancia differenza partite
                this.showModalPartite++;
            } else {
                try{
                    await this.$store.commit('auth/setIsLoading', true, { root: true }) 

                    await this.$store.dispatch("superM/CreaESalvaSMatch", this.superMatch);
                    let newMatches = await this.$store.getters["superM/getMatches"].items.filter(match => match.idmatch != this.superMatch.idmatch);
                    let newMatchesStructure = await this.$store.getters["superM/getMatches"];
                    newMatchesStructure.items = newMatches
                    newMatchesStructure.total = newMatchesStructure -1;
                    await this.$store.commit('superM/setMatches', newMatchesStructure);
                    EventBus.$emit(ACTIONS.SNACKBAR_OK, "Supermatch salvato");
                    this.$router.push({name: "supermatchassociated1"});
                } catch(error){
                    await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                    console.log(error)
                    EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel salvataggio del match");
                    return
                }
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 

                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Supermatch salvato");
            }
        },
        async dissociaSMatch(){
            console.log("cancella match: " + this.superMatch.idmatch);
            try{
                await this.$store.commit('auth/setIsLoading', true, { root: true }) 
                await this.$store.dispatch("superM/DeleteMatch", this.superMatch);

                // RIMUOVI MATCH DALLO STATO
                let newMatches = await this.$store.getters["superM/getMatches"].items.filter(match => match.idmatch != this.superMatch.idmatch);
                let newMatchesStructure = await this.$store.getters["superM/getMatches"];
                newMatchesStructure.items = newMatches
                newMatchesStructure.total = newMatchesStructure.total -1;
                await this.$store.commit('superM/setMatches', newMatchesStructure);
                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Supermatch rimosso");
                this.$router.push({name: "supermatchassociated1"});
            } catch(error){
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                console.log(error)
                EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nella cancellazione del super match");
            }
            await this.$store.commit('auth/setIsLoading', false, { root: true }) 
        },
        setDifferenzeConti(leDifferenze){
            console.log("set  differenze conti con: " + leDifferenze.differenze + " e tot differenze: " + leDifferenze.differenzaTotale);
            this.superMatch.saldo = this.globalRounderToTwoDecimals(this.saldoSMatch + leDifferenze.differenzaTotale);
            this.superMatch.differenze = leDifferenze.differenze;
            console.log("nuovo saldo: " + this.superMatch.saldo );
            this.chiudiSMatch(true);
        },

    },
    computed:{
        saldoSMatch: function() {
            console.log("chiamat saldoSMatch con: " + this.superMatch);             
            if(this.superMatch.matches) {
                let totaleSaldo = 0;
                for (const m of this.superMatch.matches) { 
                    console.log("movimento: " + m.movimenti[0].valuta_importo); 
                    totaleSaldo += m.movimenti[0].eur_importo;
                    if(m.anticipi && m.anticipi.length > 0) {
                        console.log("anticipo: " + m.movimenti[0].eur_importo); 
                        totaleSaldo -= m.movimenti[0].eur_importo;
                    } else {
                        for (const p of m.partite) { 
                            if(m.movimenti[0].valuta === p.valuta) {
                                console.log("somma partita: " + p.eurlordo); 
                                totaleSaldo -= p.eurlordo;
                            } else {
                                console.log("somma partita: " + p.eurlordo); 
                                totaleSaldo = p.eurlordo;
                            }
                        }
                    }
                }
                if(this.superMatch.differenze && this.superMatch.differenze.differenzaTotale) {
                    return  this.globalRounderToTwoDecimals(totaleSaldo + this.superMatch.differenze.differenzaTotale);
                } else {
                    return  this.globalRounderToTwoDecimals(totaleSaldo);
                }
            } else {
                return 0;
            }
        },
        tuttePartite: function() {
            console.log("tuttePartite");
            let tuttePartite=[];          
            if(this.superMatch.matches) {
                for (const m of this.superMatch.matches) { 
                    if(m.anticipi && m.anticipi.length > 0) {
                        console.log("ignora anticipo");
                    } else {
                        for (const p of m.partite) { 
                            tuttePartite.push(p)
                        }
                    }
                }
                return  tuttePartite;
            } else {
                return [];
            }
        }
    },
})
</script>

<style scoped>

.datiStyle{
    margin:auto;
    float:center;
    border: 1px solid rgb(0, 0, 0);
    background-color: rgba(203, 197, 197, 0.242);
    margin-top: 10px;
    width:80%;
}

.btnBackStyle{
    margin-bottom: 0px;
}

.btnChiudiStyle{
    background-color: #FFDFCD !important;
    border: 1px solid rgb(0, 0, 0);
    margin: 15px 45px 5px 5px;
    float: right;
}
.btnOkStyle{
    background-color: transparent !important;
    float: right;
    text-shadow: 1px 1px #3a383850;
}

</style>
