<template>
    <v-main>
        <v-row v-if="attivaRicerca" align="center"
      justify="center">

        <v-text-field class="shrink" style="width:500px;margin-right:40px" label="Parola/Frase da cercare" v-model="ricercaLibera"></v-text-field> 

        <v-btn large color="primary" elevation="0"  @click="ricercaMatch">
            Ricerca
        </v-btn>
    </v-row>
            <AnticipiList  @chiudiSelezionate="chiudiSelezionate" @dissociaSelezionate="dissociaSelezionate" ref="theMatchlist"></AnticipiList>
        <v-btn elevation="0" class="btnChiudiStyle" @click="chiudiSelezionate(false)">
            <span style="color: #D91A21">Chiudi Anticipi Selezionati</span>
        </v-btn>
        <br>
        <br>
        <br>
        <v-btn elevation="0" class="btnChiudiStyle" @click="dissociaSelezionate(false)">
            <span style="color: #D91A21">Dissocia Anticipi Selezionati</span>
        </v-btn>

        <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="50%"
    >
      <v-card padding="20px">
        <v-card-title class="text-h5">
            Scegli dettagli anticipo
        </v-card-title>
        <v-select style="width:90%; padding-top:50px; margin:auto" 
            :items="direzioniCommerciali"
            item-text="description"
            item-value="code"
            label="Direzione Commerciale"
            v-model="direzione_commerciale">                         
        </v-select>
        <v-select style="width:90%; margin:auto" 
            :items="profitCenters"
            item-text="description"
            item-value="code"
            label="Profit Center"
            v-model="profit_center">                         
        </v-select>
        <v-card-actions>
            <v-col class="text-right">
            <v-btn style="margin-right: 50px" color="primary"  @click="continuaChiudiSelezionate()">Submit</v-btn>
            <v-btn   @click="dialog = false; direzione_commerciale = ''; profit_center = ''">Annulla</v-btn>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    </v-main>

</template>

<script>
import AnticipiList from "../components/AnticipiList.vue";
import EventBus, { ACTIONS } from '../components/Helpers/EventBus.js';

export default ({
    created(){
        console.log(this.numMatch)
    },
    data(){
        return{
            numMatch: this.$route.params.numMatch,
            ricercaLibera: "",
            dialog: false,
            direzione_commerciale: "",
            profit_center: "",
            singolo: 0,
            direzioniCommerciali: [],
            profitCenters:[]
        }
    },
    components: {
        AnticipiList,
    },
    async beforeMount() {
        this.direzioniCommerciali = this.$store.getters["anticipo/getDirezioniCommerciali"];
        this.profitCenters = this.$store.getters["anticipo/getProfitCenters"];
    },
    mounted() { 
        this.$store.commit('auth/setIsLoading', false);
    },    
    methods:{
        async chiudiSelezionate(singolo){
            this.singolo = singolo;
            this.dialog = true
        },       
        async continuaChiudiSelezionate(){
            this.dialog = false;
            console.log("il singolo" + this.singolo)
            console.log("Chiudi Selezionate");
            let selected = null;
            if(this.singolo) {
                selected = [this.singolo]
            } else {
                selected = structuredClone(this.$refs.theMatchlist.checkedMatch); 
            }
            let allMatches = await this.$store.getters["anticipo/getMatches"].items;
            console.log(selected);

            await this.$store.commit('auth/setIsLoading', true, { root: true }) 
            for(let anIdMatch of selected) {
                // chiudi as is.
                let theMatch = allMatches.find(match => match.id_anticipo == anIdMatch);
                theMatch.code_direzione_commerciale_anticipo = this.direzione_commerciale
                theMatch.code_profit_center_anticipo = this.profit_center
                try{
                    await this.$store.dispatch("anticipo/SaveMatch", theMatch);
                    let newMatches = await this.$store.getters["anticipo/getMatches"].items.filter(match => match.id_anticipo != theMatch.id_anticipo);
                    let newMatchesStructure = await this.$store.getters["anticipo/getMatches"];
                    newMatchesStructure.items = newMatches
                    newMatchesStructure.total = newMatchesStructure -1;
                    await this.$store.commit('anticipo/setMatches', newMatchesStructure);
                    if(!this.singolo) {
                        this.$refs.theMatchlist.checkedMatch.splice(this.$refs.theMatchlist.checkedMatch.indexOf(anIdMatch ), 1);
                    }
                    console.log("Match chiuso con successo " + anIdMatch);
                } catch(error){
                    await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                    console.log(error)
                    EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel salvataggio del match: " + anIdMatch);
                    return;
                }
            }
            console.log("Tutti gli anticipi chiusi con successo");
            this.direzione_commerciale = ""
            this.profit_center = ""

            EventBus.$emit(ACTIONS.SNACKBAR_OK, "Anticipi chiusi con successo");
            await this.$store.commit('auth/setIsLoading', false, { root: true }) 
        },
        async dissociaSelezionate(singolo){
            this.singolo = singolo;
            console.log("il singolo" + this.singolo)

            if(confirm("Sicuro di voler dissociare l'anticipo selezionato?")) {
                console.log("Chiudi Selezionate");
                let selected = null;
                if(this.singolo) {
                    selected = [this.singolo]
                } else {
                    selected = structuredClone(this.$refs.theMatchlist.checkedMatch); 
                }
                let allMatches = await this.$store.getters["anticipo/getMatches"].items;
                console.log(selected);

                await this.$store.commit('auth/setIsLoading', true, { root: true }) 
                for(let anIdMatch of selected) {
                    // dissocia as is.
                    let theMatch = allMatches.find(match => match.id_anticipo == anIdMatch);
                    try{
                        await this.$store.dispatch("anticipo/DeleteMatch", theMatch);
                        let newMatches = await this.$store.getters["anticipo/getMatches"].items.filter(match => match.id_anticipo != theMatch.id_anticipo);
                        let newMatchesStructure = await this.$store.getters["anticipo/getMatches"];
                        newMatchesStructure.items = newMatches
                        newMatchesStructure.total = newMatchesStructure -1;
                        await this.$store.commit('anticipo/setMatches', newMatchesStructure);
                        if(!this.singolo) {
                            this.$refs.theMatchlist.checkedMatch.splice(this.$refs.theMatchlist.checkedMatch.indexOf(anIdMatch ), 1);
                        }
                        console.log("Anticipo disassociato con successo" + anIdMatch);
                    } catch(error){
                        await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                        console.log(error)
                        EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel dissociare l'anticipo: " + anIdMatch);
                        return;
                    }
                }
                console.log("Tutti gli anticipi disassociati con successo");

                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Anticipi disassociati con successo");
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 
            } else {
                console.log("refused")

            }
        },
        async ricercaMatch() {
            await this.$store.commit('auth/setIsLoading', true, { root: true }) 

            let params = new URLSearchParams();
            params.append('like_str', this.ricercaLibera);
            await this.$store.dispatch("anticipo/LoadMatches", {params: params, soloAperti:true});
            await this.$store.commit('auth/setIsLoading', false, { root: true }) 

        }
    },
    computed:{
        attivaRicerca : function() {
            return this.$store.getters["anticipo/getIsRicercaOn"];
        }
    },
})
</script>

<style scoped>

.btnBackStyle{
    margin-bottom: 0px;
}

.btnChiudiStyle{
    background-color: #FFDFCD !important;
    border: 1px solid rgb(0, 0, 0);
    margin: 15px 45px 5px 5px;
    float: right;
}
.btnOkStyle{
    background-color: transparent !important;
    float: right;
    text-shadow: 1px 1px #3a383850;
}

</style>
