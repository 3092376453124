var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { margin: "auto", width: "90%" } }, [
        _c("div", { staticClass: "divRigaStyle", attrs: { id: "riga1" } }, [
          _c(
            "div",
            { staticClass: "divMovimentiStyle", attrs: { id: "Movimenti" } },
            [
              _c("h3", [_vm._v("Movimenti e Sotto-Movimenti")]),
              _c("SelectMovimenti"),
              _c("ListMovimenti", {
                on: { setDataMovimento: _vm.setDataMovimento },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "divPartiteStyle", attrs: { id: "Partite" } },
            [
              _c("h3", [_vm._v("Partite")]),
              _c("SelectPartite"),
              _c("ListPartite", { on: { setDataPartita: _vm.setDataPartita } }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("div", { staticClass: "divRigaStyle", attrs: { id: "riga2" } }, [
          _c("div", { staticClass: "datiStyle" }, [
            _c(
              "table",
              { staticStyle: { width: "100%", "table-layout": "fixed" } },
              [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "riga",
                      staticStyle: { border: "transparent" },
                      attrs: { id: "primo blocco" },
                    },
                    [
                      _c("tr", [
                        _vm._m(0),
                        _c("td", { staticStyle: { width: "60%" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Movimento.ragionesociale,
                                    23,
                                    "..."
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(1),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateLong")(
                                    _vm.Movimento.dataoperazione
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(2),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateLong")(
                                    _vm.Movimento.dataregistrazione
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(3),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Movimento.codice_piteco,
                                    23,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(4),
                        _c(
                          "td",
                          {
                            staticClass: "CellWithAevoTooltip",
                            attrs: { rowspan: 2 },
                          },
                          [
                            _c("span", { staticClass: "AevoTooltip" }, [
                              _vm._v(_vm._s(_vm.Movimento.notemovimento)),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass: "dataStyle",
                                staticStyle: { height: "60px !important" },
                                attrs: { type: "text" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      _vm.Movimento.notemovimento,
                                      23,
                                      "..."
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    { staticClass: "riga", attrs: { id: "secondo blocco" } },
                    [
                      _c("tr", [
                        _vm._m(5),
                        _c(
                          "td",
                          {
                            attrs: {
                              title: _vm.Movimento.artificial_id_movimento,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "dataStyle",
                                attrs: { type: "text" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.Movimento.artificial_id_movimento)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("tr", [
                        _vm._m(6),
                        _c("td", { staticStyle: { width: "50%" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Movimento.conto,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(7),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Movimento.tipo,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(8),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.nomeDocumento,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(9),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Movimento.valuta,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: 2 } },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  float: "left",
                                  "margin-top": "6px",
                                  "vertical-align": "middle",
                                },
                              },
                              [_vm._v("Visualizza Documento")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "flexcol",
                                staticStyle: {
                                  margin: "0px 5px 0px 5px",
                                  float: "right",
                                },
                                attrs: { icon: "" },
                                on: { click: _vm.openDoc },
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("mdi-open-in-new"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      staticClass: "riga",
                      staticStyle: { border: "transparent" },
                      attrs: { id: "terzo blocco" },
                    },
                    [
                      _c("tr", [
                        _vm._m(10),
                        _c("td", { staticStyle: { width: "50%" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Partita.ragionesociale,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(11),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Partita.sapid,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(12),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatTruncate")(
                                    _vm.Partita.numerodocumento,
                                    25,
                                    "..."
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(13),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDateLong")(
                                      _vm.Partita.datascadenza
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(14),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateLong")(
                                    _vm.Partita.datadocumento
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      staticClass: "riga",
                      staticStyle: { border: "transparent" },
                      attrs: { id: "quarto blocco" },
                    },
                    [
                      _c("tr", [
                        _vm._m(15),
                        _c("td", { staticStyle: { width: "50%" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      _vm.Partita.valutalordo,
                                      25,
                                      "..."
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(16),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass: "dataStyle",
                              attrs: { type: "text" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      _vm.Partita.valuta,
                                      25,
                                      "..."
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: 2 } },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  float: "left",
                                  "margin-top": "5px",
                                },
                              },
                              [_vm._v("Visualizza Documento")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "flexcol",
                                staticStyle: {
                                  margin: "0px 5px 0px 5px",
                                  float: "right",
                                },
                                attrs: { icon: "" },
                                on: { click: _vm.openDocPartite },
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("mdi-open-in-new"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "divRigaStyle", attrs: { id: "riga3" } }, [
          _c("div", { staticStyle: { height: "20px" } }, [
            _vm.visibility
              ? _c(
                  "p",
                  {
                    staticStyle: {
                      "vertical-align": "middle",
                      width: "65%",
                      "text-align": "center",
                      "margin-top": "15px",
                    },
                  },
                  [_vm._v("Drop in the box below ")]
                )
              : _vm._e(),
          ]),
          _c("div", {
            staticClass: "dropStyle",
            class: _vm.saldo == 0 ? "dropsaldoZero" : "dropStyle",
            attrs: { id: "dropArea" },
            on: {
              drop: function ($event) {
                return _vm.onDrop($event)
              },
              dragenter: function ($event) {
                $event.preventDefault()
              },
              dragover: [
                function ($event) {
                  $event.preventDefault()
                },
                function ($event) {
                  $event.stopPropagation()
                },
              ],
              dragstart: function ($event) {
                return _vm.dragStart($event, _vm.movimento)
              },
            },
          }),
          _c("div", { staticClass: "saldoStyle" }, [
            _c("span", [_vm._v("Saldo = " + _vm._s(_vm.saldo))]),
          ]),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "btnChiudiStyle",
                  on: { click: _vm.chiudiMatch },
                },
                [
                  _c("span", { staticStyle: { color: "#D91A21" } }, [
                    _vm._v("Chiudi Match"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("DifferenzeIncassiPartite", {
        attrs: {
          partite: _vm.partiteDroppate,
          valuta: _vm.movimentiDroppati ? _vm.movimentiDroppati[0].valuta : "",
          showModal: _vm.showModal,
          saldo: _vm.saldo,
        },
        on: { setDifferenzeConti: _vm.setDifferenzeConti },
      }),
      _vm.showModal1
        ? _c(
            "div",
            [
              _c(
                "PopUpClose",
                { attrs: { theme: "" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            color: "rgb(14, 153, 33)",
                            float: "left: margin-left: 10px",
                          },
                          attrs: { size: "29" },
                        },
                        [_vm._v("mdi-checkbox-marked-circle")]
                      ),
                      _c("h2", [_vm._v("Congratulazioni!")]),
                    ],
                    1
                  ),
                  _c("p", [_vm._v("Match effettuato con successo")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0", exact: "" },
                      on: {
                        click: function ($event) {
                          _vm.showModal1 = false
                        },
                      },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showModalDoc
        ? _c(
            "div",
            [
              _c(
                "DocOpened",
                {
                  attrs: {
                    idDoc: _vm.idDoc,
                    riferimento: _vm.riferimento,
                    theme: "",
                  },
                  on: { close: _vm.openDoc },
                },
                [
                  _c("div"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0" },
                      on: { click: _vm.openDoc },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "20%" } }, [
      _c("p", [_vm._v("Rag. Sociale")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Data Op.")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Data registr.")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Riferimento Piteco.")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Note")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("IDMovimento")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "30%" } }, [
      _c("p", [_vm._v("Conto")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Tipo Doc.")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Nome Doc.")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Valuta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "30%" } }, [
      _c("p", [_vm._v("Rag. Sociale")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "30%" } }, [
      _c("p", [_vm._v("SAP ID")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Numero doc")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Data Scad")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Data Doc")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { width: "30%" } }, [
      _c("p", [_vm._v("Importo Valuta")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("p", [_vm._v("Valuta")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }