var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.matches.items, function (match) {
      return _c("div", { key: match.idmatch, staticClass: "match" }, [
        match.idmatch == _vm.idVal
          ? _c(
              "div",
              _vm._l(match.movimenti, function (movimento) {
                return _c(
                  "div",
                  {
                    key:
                      String(match.idmatch) +
                      String(movimento.artificial_id_movimento),
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: {
                          "background-color": "rgba(215, 214, 214, 0.751)",
                          "margin-top": "20px",
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "line-height": "50px",
                              "margin-bottom": "3px",
                            },
                          },
                          [_vm._v(" " + _vm._s(movimento.ragionesociale) + " ")]
                        ),
                      ]
                    ),
                    _c("v-card", { staticClass: "cardStyle" }, [
                      _c("table", { staticStyle: { width: "100%" } }, [
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Data operazione:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateShort")(
                                    movimento.dataoperazione
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Data di registrazione:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateShort")(
                                    movimento.dataregistrazione
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Riferimento Piteco:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.codice_piteco)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Tipo"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.tipo)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Divisa/Cambio"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.valuta)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v(
                                "Importo in valuta (" +
                                  _vm._s(movimento.valuta) +
                                  "): "
                              ),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.valuta_importo)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Importo in EUR: "),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.eur_importo)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { attrs: { colspan: 2 } }, [
                            _c("hr", {
                              staticStyle: {
                                "margin-bottom": "5px",
                                width: "100%",
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Conto:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.conto)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Data valuta:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.datavaluta)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { attrs: { colspan: 2 } }, [
                            _c("hr", {
                              staticStyle: {
                                "margin-bottom": "5px",
                                width: "100%",
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("ID Movimento"),
                            ]),
                          ]),
                          _c("td", [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.artificial_id_movimento)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("ID Documento"),
                            ]),
                          ]),
                          _c("td", [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(_vm._s(movimento.iddocumento)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v("Sap ID cliente:"),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "dataStyle" }, [
                              _vm._v(" " + _vm._s(movimento.sapid)),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "cell" }, [
                            _c("p", { staticClass: "pStyle" }, [
                              _vm._v(" Note Movimento:"),
                            ]),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "cell CellWithAevoTooltip",
                              attrs: { colspan: 2 },
                            },
                            [
                              _c("span", { staticClass: "AevoTooltip" }, [
                                _vm._v(_vm._s(movimento.notemovimento)),
                              ]),
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      movimento.notemovimento,
                                      15,
                                      "..."
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        movimento.is_sottomovimento_con_supermovimento_aggregato
                          ? _c("tr", [
                              _c("td", { staticClass: "cell" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "pStyle",
                                    staticStyle: {
                                      border: "4px solid rgba(3, 3, 3, 0.17)",
                                    },
                                  },
                                  [_vm._v(" Super-Movimento:")]
                                ),
                              ]),
                              _c("td", { staticClass: "cell" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "dataStyle strong",
                                    staticStyle: {
                                      "margin-top": "3px",
                                      border: "4px solid rgba(3, 3, 3, 0.17)",
                                    },
                                  },
                                  [
                                    _vm._v(" Visualizza Aggregato: "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "flexcol",
                                        staticStyle: {
                                          margin: "0px 5px 0px 5px",
                                        },
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openMovimento(movimento)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [_vm._v("mdi-open-in-new")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: { float: "left", "margin-top": "7px" },
                          },
                          [_vm._v("Visualizza Documento")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "flexcol",
                            staticStyle: { margin: "0px 5px 0px 5px" },
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openDoc(movimento.iddocumento)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("mdi-open-in-new"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }