<template>
  <div>
    <ClosedAnticipiList  @dissociaSelezionate="dissociaSelezionate" ref="theMatchlist"></ClosedAnticipiList>
  </div>
</template>

<script>
import EventBus, { ACTIONS } from '../../components/Helpers/EventBus.js';

import ClosedAnticipiList from "../../components/ClosedMatch/ClosedAnticipiList.vue";
export default {
    components: { ClosedAnticipiList },
    methods:{
      async dissociaSelezionate(singolo){
            this.singolo = singolo;
            console.log("il singolo" + this.singolo)

            if(confirm("Sicuro di voler dissociare l'anticipo selezionato?")) {
                console.log("Chiudi Selezionate");
                let selected = null;
                if(this.singolo) {
                    selected = [this.singolo]
                } else {
                    selected = structuredClone(this.$refs.theMatchlist.checkedMatch); 
                }
                let allMatches = await this.$store.getters["anticipo/getMatches"].items;
                console.log(selected);

                await this.$store.commit('auth/setIsLoading', true, { root: true }) 
                for(let anIdMatch of selected) {
                    // dissocia as is.
                    let theMatch = allMatches.find(match => match.id_anticipo == anIdMatch);
                    try{
                        await this.$store.dispatch("anticipo/DeleteMatch", theMatch);
                        let newMatches = await this.$store.getters["anticipo/getMatches"].items.filter(match => match.id_anticipo != theMatch.id_anticipo);
                        let newMatchesStructure = await this.$store.getters["anticipo/getMatches"];
                        newMatchesStructure.items = newMatches
                        newMatchesStructure.total = newMatchesStructure -1;
                        await this.$store.commit('anticipo/setMatches', newMatchesStructure);
                        if(!this.singolo) {
                            this.$refs.theMatchlist.checkedMatch.splice(this.$refs.theMatchlist.checkedMatch.indexOf(anIdMatch ), 1);
                        }
                        console.log("Anticipo disassociato con successo" + anIdMatch);
                    } catch(error){
                        await this.$store.commit('auth/setIsLoading', false, { root: true }) 
                        console.log(error)
                        EventBus.$emit(ACTIONS.SNACKBAR_KO, "Problema nel dissociare l'anticipo: " + anIdMatch);
                        return;
                    }
                }
                console.log("Tutti gli anticipi disassociati con successo");

                EventBus.$emit(ACTIONS.SNACKBAR_OK, "Anticipi disassociati con successo");
                await this.$store.commit('auth/setIsLoading', false, { root: true }) 
            } else {
                console.log("refused")

            }
        },
    }
}
</script>

<style>
.btnBackStyle{
    margin-bottom: 20px;
}
</style>