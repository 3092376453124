<template>
  <div>
    <ClosedSuperMatchList>

    </ClosedSuperMatchList>
  </div>
</template>

<script>
import ClosedSuperMatchList from "@/components/ClosedMatch/ClosedSuperMatchList.vue";
export default {
    components: { ClosedSuperMatchList }
}
</script>

<style>
.btnBackStyle{
    margin-bottom: 20px;
}
</style>