<template>
  <div>
    <v-container  >
  <table style="margin-top:20px;width:100%">
    <tr style="width:100%">
      <td style="width:70%">

        <SelectAnticipi :chiusi="true"></SelectAnticipi>
      </td>
</tr>
</table>




  <table style="margin-left:18px;margin-top:20px">
    <tr>
      <td>
        <v-select
            style="margin-top:10px;min-width:300px"
            clearable
            multiple
            dense
            :items="statiProcesso"
            item-text="description"
            item-value="code"
            label="Stato"
            v-model="statoProcesso"
        ></v-select>
      </td>
      <td style="min-width:100px">
      </td>
      <td style="max-width: 180px">
     <v-menu style="margin-left: 30px;"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            clearable
            v-model="dataDAvalue"

            prepend-icon="mdi-calendar"
            hint="DD/MM/YYYY format"
            persistent-hint
             @blur="dataDA = parseDate(dataDAvalue)"
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:label>
              <span style="font-size: 0.8em">Da Data Chiusura</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dataDA"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>      
      </td>
      <td style="max-width: 180px">
     <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            clearable
            v-model="dataAvalue"
            label="A Data Chiusura"
            prepend-icon="mdi-calendar"
            hint="DD/MM/YYYY format"
            persistent-hint
            @blur="dataA = parseDate(dataAvalue)"
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:label>
              <span style="font-size: 0.8em">A Data Chiusura</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dataA"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>      
      </td>
        <v-tooltip bottom style="max-width:500px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3 mt-4"  size="30"
                v-bind="attrs"
                v-on="on"
                >mdi-help-circle-outline</v-icon>
            </template>
            <span class="body-1" style="max-width:500px">
              La data ricercata si riferisce alla data del movimento.
            </span>
        </v-tooltip>
        <v-btn medium class="text-right ml-5 mr-9 mt-5"
                @click="reloadMatches"
        >
            Reload Match
        </v-btn>

     </tr>
  </table>

  
  
  <div class="box, containerStyle">  
    <table style="display: table; width:100%">
    <div v-if="!matchesVisualizzati || matchesVisualizzati.length==0">
           <p> <i>Nessun match trovato per i parametri di ricerca.</i></p>
      </div>
  
       <div v-for="match in matchesVisualizzati" :key="match.idmatch" class="match">
       
        <tr style="display:table; width:100%">
          
          <td style="float:left; width: 35%;">
          <v-icon size="26" >mdi-file</v-icon> 
            <strong style="font-size:15px"></strong>
            <div id="datiMovimento" class="datiStyle" style="width:100%; overflow-wrap:break-word;">
              <strong>
              <p>importo: <i>{{ match.importo}}</i></p>
              <p>data op: <i>{{ match.dataoperazione | formatDateShort}}</i></p>
              <p>valuta: <i>{{match.valuta}}</i></p>
            </strong>
              <p v-if="match.is_sottomovimento_con_supermovimento_aggregato"> Importo supermovimento:   {{importoSuperMovimento[match.iddbmovimento]}} </p> 
              <p style="overflow-wrap:break-word; width:100%">note: <i :title=match.notemovimento>{{match.note_movimento | formatTruncate(250, '...')}} </i></p>
              </div>
          
          </td>
          <td style="float:left; vertical-align:middle; width: 30%">
            <v-row >
              <v-col  align="center" justify="center">
                <p  :title=match.id_anticipo style="float:none"> Stato SAP: {{descrizioneMatch(match)}}</p>
                <p  :title=match.id_anticipo style="float:none"> Data Chiusura: {{match.data_conferma | formatDateShort}}</p>
                <p  :title=match.note_scoring style="float:none"> Scoring: {{ match.scoring }} {{ match.note_scoring | formatTruncate(30, '...') }}</p>
                <p  style="float:none"> Cod Dir Com: {{ match.code_direzione_commerciale_anticipo }} Cod Prof Ctr: {{ match.code_profit_center_anticipo | formatTruncate(30, '...') }}</p>
              </v-col>
            </v-row>
          </td>
          <td style="float:right; width: 35%">
             <v-icon size="26">mdi-file</v-icon> 
              <strong style="font-size:15px"> REGISTRAZIONE ANTICIPO SU CLIENTE: {{match.ragionesociale | formatTruncate(30, '...')}} - {{match.sapid}}</strong>
              <div id="datiMovimento" class="datiStyle">
                <strong>
                  <p >importo <i>{{ match.importo}}</i></p>
                  <p >valuta <i>{{ match.valuta}}</i></p>
                </strong>
              </div>
          </td>
        </tr>
        <hr>
      </div>
     </table>
      
    </div>
    </v-container>
     <div style="width:90%; margin:auto;">
      <p class="countStyle"> Selezionati: {{numero_match}} / Scaricati: {{num_scaricati_match}} / Totali: {{num_tot_match}}</p>
    <v-btn class="pagination_button" @click.prevent="loadMoreMatches()" :disabled="num_scaricati_match===num_tot_match">Carica altri Match</v-btn>
      
    </div>
      <br>
  </div>
  
  </template>
  
  <script>
  import SelectAnticipi from "@/components/SelectAnticipi.vue";
  import EventBus, { ACTIONS } from '../../components/Helpers/EventBus.js';

  export default {
    name: "MatchList",
      data(){
        return{
          checkedMatch: [],
          sortCondPag: false,
          dataDAvalue: null,
          dataAvalue: null,
          dataDocumentoMovimentiValue: null,
          dataDA: null,
          dataA: null,
          dataDocumentoMovimenti: null,
          menu1: false,
          menu2: false,
          menu3: false,
          statoProcesso: null,
          importoSuperMovimento:{}

        }
      },
      components: {
        SelectAnticipi,
      },
      async created () {
        let params = new URLSearchParams();
        await this.$store.dispatch("anticipo/LoadMatches", {params: params, soloAperti:false});
        let myMatches = this.$store.getters["anticipo/getMatchesChiusiFiltrati"];
        if(myMatches) {
          for(let match of myMatches) {
              if(match.is_sottomovimento_con_supermovimento_aggregato) {
                let superMovimento = await this.$store.dispatch("anticipo/LoadSuperMovimento", match.iddbmovimento);
                this.$set(this.importoSuperMovimento, match.iddbmovimento, superMovimento.valuta_importo);
              }
          }
        } 
        this.$store.commit('auth/setIsLoading', false);
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        // This arrangement can be altered based on how we want the date's format to appear.
        let currentDate = `${day}/${month}/${year}`;
        this.dataDocumentoMovimentiValue = currentDate;
        this.dataDocumentoMovimenti = this.parseDate(this.dataDocumentoMovimentiValue)
    },    
      computed: {
          matchesVisualizzati: function() {
            let imatch = structuredClone(this.$store.getters["anticipo/getMatchesChiusiFiltrati"]);
            console.log("matches da visualizzare: ");
            console.log(imatch);
            if(imatch) {
              if(this.statoProcesso) {
                return structuredClone(imatch.filter(match => {
                  let statoTrovato = false;
                  for(const stato of this.statoProcesso) {
                    statoTrovato = statoTrovato || (match.code_stato_trasferimento_anticipo === stato);
                  }
                  return statoTrovato;
                }));
              } else {
                return structuredClone(imatch);
              }
            } else {
              return [];
            }
          },     
          statiProcesso() {
            return this.$store.getters["match/getStatiProcessoMatch"];
          },
          numero_match: function() {
            return this.matchesVisualizzati.length;
          },
          num_tot_match: function() {
            return (this.$store.getters["anticipo/getMatchesChiusi"]) ? this.$store.getters["anticipo/getMatchesChiusi"].total : 0;
          },
          num_scaricati_match: function() {
            return (this.$store.getters["anticipo/getMatchesChiusi"]) ? this.$store.getters["anticipo/getMatchesChiusi"].size : 0;
          },
      },
      methods:{
          async reloadMatches() {
              console.log("reload matches, con: daData: " + this.dataDA + " aData: " + this.dataA )
              EventBus.$emit(ACTIONS.SNACKBAR_OK, "Ricarico Match");
              this.$store.commit('auth/setIsLoading', false);
              let params = new URLSearchParams();
              await this.$store.dispatch("anticipo/LoadMatches", {params: params, soloAperti:false, chiusiDa: this.parseDate(this.dataDAvalue), chiusiA: this.parseDate(this.dataAvalue)});
          },
          async loadMoreMatches(){
            console.log("loading more");
            let params = new URLSearchParams();
            await this.$store.dispatch("anticipo/LoadMoreMatches", {params: params, soloAperti:true});
            this.matchesOrdinati = structuredClone(this.$store.getters["anticipo/getMatchesFiltrati"]);
            let myMatches = this.matchesOrdinati;
            if(myMatches) {
            for(let match of myMatches) {
                if(match.is_sottomovimento_con_supermovimento_aggregato) {
                  let superMovimento = await this.$store.dispatch("anticipo/LoadSuperMovimento", match.iddbmovimento);
                  this.$set(this.importoSuperMovimento, match.iddbmovimento, superMovimento.valuta_importo);
                }
              }
          }
  
          },
          check(id){
            if(document.getElementById(id).checked){
              console.log("aggiungo tra i match da chiudere:" + id);
              this.checkedMatch.push(id);
              console.log(this.checkedMatch)
            }
            else if(!document.getElementById(id).checked){
              console.log("tolgo tra i match da chiudere:" + id);
              this.checkedMatch.splice(this.checkedMatch.indexOf(id ), 1);
              console.log(this.checkedMatch)
            }
          },
          cancellaAnticipo(idAnticipo) {
            this.$emit('dissociaSelezionate', idAnticipo)
          },
          formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
          },
          parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
          },

          descrizioneMatch(match) {
            console.log("trova descrizione per codice stato:  " + match.code_stato_trasferimento_anticipo)
            let state = this.$store.getters["match/getStatiProcessoMatch"].find(stato => {
              return stato.code === match.code_stato_trasferimento_anticipo;
            })
            if(state) {return state.description}
            return "Stato Non Trovato per codice: " +match.code_stato_trasferimento_anticipo;
          }
      },
      watch: {
        dataDA () {
          console.log("dateDA changed")
          this.dataDAvalue = this.formatDate(this.dataDA)
        },
        dataA () {
          console.log("dateA changed")
          this.dataAvalue = this.formatDate(this.dataA)
        }
      }
  }
  </script>
  
  <style scoped>
  p {
    float: left;
    margin-right: 15px;
    margin-bottom: 3px;
  }
  .box{
    padding: 4px;
    overflow:auto;
    height: 520px;
  }
  .pagination_button {
      float: left;
      font-size: 11px;
      text-align: center;
      text-transform: capitalize;
      background-color: rgba(203, 197, 197, 0.242) !important;
      padding: 0px 5px !important;
      border-radius: 5px;
     /* font-family: "'EB Garamond SC',ebGaramond12SC_WGDI,'Times New Roman',serif";*/
  }
  .countStyle{
    float:left;
    font-size:14px; 
  }
  
  
  .containerStyle{
      border: 1px solid rgb(0, 0, 0);
      background-color: rgba(203, 197, 197, 0.242);
      margin-top: 10px;
      
  }
  
  .cardStyle{
      margin: auto !important;
  }
  .datiStyle{
      font-size: 14px;
      margin-top:10px;
      margin-bottom: 10px;
  }
  .imgMatchListStyle{
      max-width: 60%;
      vertical-align: middle ;
  }
  .btnImgStyle{
      background-color: transparent !important;
      width: 100%;
  }
  .match{
    margin-top:5px;
    margin-bottom: 15px;
    width:100%;
  }
  
  </style>