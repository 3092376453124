var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "filtroStyle" },
          [
            _c("v-autocomplete", {
              attrs: {
                items: _vm.valute,
                "item-text": "descrizione",
                "item-value": "codice_iso",
                label: "Scegli valuta",
              },
              model: {
                value: _vm.filtroMMMovimentiValuta,
                callback: function ($$v) {
                  _vm.filtroMMMovimentiValuta = $$v
                },
                expression: "filtroMMMovimentiValuta",
              },
            }),
          ],
          1
        ),
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "Importo" },
                model: {
                  value: _vm.filtroMMMovimentiImporto,
                  callback: function ($$v) {
                    _vm.filtroMMMovimentiImporto = $$v
                  },
                  expression: "filtroMMMovimentiImporto",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 3 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("td", { staticClass: "filtroStyle" }, [
          _c(
            "div",
            { staticClass: "tooltip" },
            [
              _c("v-text-field", {
                attrs: { label: "Ragione Sociale" },
                model: {
                  value: _vm.filtroMMMovimentiRagioneSociale,
                  callback: function ($$v) {
                    _vm.filtroMMMovimentiRagioneSociale = $$v
                  },
                  expression: "filtroMMMovimentiRagioneSociale",
                },
              }),
              _vm.isOk
                ? _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v("Inserisci almeno 3 caratteri per la ricerca"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }