var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "div",
        { staticClass: "datiStyle", attrs: { id: "datiSuperMatch" } },
        [
          _c("strong", [
            _c(
              "p",
              { staticStyle: { "margin-left": "30%", "font-size": "30px" } },
              [
                _vm._v(
                  "Saldo (incluse partite di pareggio): " +
                    _vm._s(_vm.saldoSMatch) +
                    " EURO"
                ),
              ]
            ),
          ]),
          _c("strong", [
            _c("p", { staticStyle: { "margin-left": "10%" } }, [
              _vm._v("differenzamonetizzatainvalutaconimportoscontato: "),
              _c("i", [
                _vm._v(
                  _vm._s(
                    _vm.superMatch
                      .differenzamonetizzatainvalutaconimportoscontato
                  )
                ),
              ]),
            ]),
            _c("p", { staticStyle: { "margin-left": "10%" } }, [
              _vm._v("score: "),
              _c("i", [_vm._v(" " + _vm._s(_vm.superMatch.score))]),
            ]),
            _c("p", { staticStyle: { "margin-left": "10%" } }, [
              _vm._v("gri_scoring_penalizzazioni: "),
              _c("i", [
                _vm._v(_vm._s(_vm.superMatch.gri_scoring_penalizzazioni)),
              ]),
            ]),
          ]),
          _c("SuperMatchListOverall", {
            attrs: { idVal: this.$route.params.id },
          }),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "btnChiudiStyle",
          attrs: { elevation: "0" },
          on: {
            click: function ($event) {
              return _vm.chiudiSMatch(false)
            },
          },
        },
        [
          _c("span", { staticStyle: { color: "#D91A21" } }, [
            _vm._v("Chiudi Super Match"),
          ]),
        ]
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c(
        "v-btn",
        {
          staticClass: "btnChiudiStyle",
          attrs: { elevation: "0" },
          on: { click: _vm.dissociaSMatch },
        },
        [
          _c("span", { staticStyle: { color: "#D91A21" } }, [
            _vm._v("Dissocia Super Match"),
          ]),
        ]
      ),
      _c("DifferenzeIncassiPartite", {
        attrs: {
          partite: _vm.tuttePartite,
          valuta: "EUR",
          showModal: _vm.showModalPartite,
          saldo: _vm.saldoSMatch,
          differenzeInIngresso: _vm.superMatch.partite_di_pareggio,
        },
        on: { setDifferenzeConti: _vm.setDifferenzeConti },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }