var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "backdrop",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal",
          class: {
            sale: _vm.theme === "sale",
            Dissocia: _vm.theme === "Dissocia",
          },
        },
        [
          _vm._t("default"),
          _c("h2", [_vm._v(_vm._s(_vm.header))]),
          _c("p", [_vm._v(_vm._s(_vm.text))]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }