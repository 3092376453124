var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "btnChiudiStyle",
              staticStyle: { "margin-bottom": "30px", "margin-right": "20px" },
              attrs: { elevation: "0" },
              on: { click: _vm.closeMatch },
            },
            [
              _c("span", { staticStyle: { color: "#D91A21" } }, [
                _vm._v("Chiudi Match"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("DetailMatch", {
        attrs: { origin: true, idVal: this.$route.params.id },
        on: {
          openDoc: function ($event) {
            return _vm.openDoc($event)
          },
          openMovimento: function ($event) {
            return _vm.openMovimento($event)
          },
          dissocia: function ($event) {
            return _vm.deleteMatch($event)
          },
          confermaChiuso: function ($event) {
            return _vm.confermaChiuso()
          },
          convertiAnticipo: function ($event) {
            return _vm.convertiAnticipo($event)
          },
        },
      }),
      _vm.showModal
        ? _c(
            "div",
            [
              _c(
                "PopUpClose",
                { attrs: { theme: "" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            color: "rgb(14, 153, 33)",
                            float: "left",
                            "margin-left": "10px",
                          },
                          attrs: { size: "29" },
                        },
                        [_vm._v("mdi-checkbox-marked-circle")]
                      ),
                      _c("h2", [_vm._v("Congratulazioni!")]),
                    ],
                    1
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.popupPrompt))]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0", exact: "" },
                      on: { click: _vm.confermaChiuso },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showModalDoc
        ? _c(
            "div",
            [
              _c(
                "DocOpened",
                {
                  attrs: { idDoc: _vm.idDoc, riferimento: _vm.riferimento },
                  on: { close: _vm.openDoc },
                },
                [
                  _c("div"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0" },
                      on: { click: _vm.openDoc },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PopUpMovimento", {
        attrs: {
          movimentoId: _vm.movimentoPopup,
          showModal: _vm.showModalMovimento,
        },
        on: {
          openDoc: function ($event) {
            return _vm.openDoc($event)
          },
        },
      }),
      _c("DifferenzeIncassiPartite", {
        attrs: {
          partite: _vm.theMatch.partite,
          valuta: _vm.theMatch.movimenti
            ? _vm.theMatch.movimenti[0].valuta
            : "",
          showModal: _vm.showModalPartite,
          saldo: _vm.theMatch.saldo,
          differenzeInIngresso: _vm.theMatch.partite_di_pareggio,
        },
        on: { setDifferenzeConti: _vm.setDifferenzeConti },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }