var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center", staticStyle: { height: "30%" } },
    [
      _c(
        "v-row",
        [
          _c(
            "p",
            {
              staticStyle: { "margin-left": "200px", "font-size": "30px" },
              attrs: { align: "left" },
            },
            [_vm._v("Match:")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "btnSyle",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.caricaMatch(0)
                },
              },
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: -90,
                        size: 140,
                        width: 15,
                        value: 80,
                        color: "#00d640",
                      },
                    },
                    [
                      _c("p", { staticClass: "pStyle" }, [
                        _vm._v(_vm._s(_vm.match90) + " "),
                      ]),
                    ]
                  ),
                  _c("p", { staticStyle: { color: "forestgreen" } }, [
                    _vm._v(" Score: > 60 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "btnSyle",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.caricaMatch(1)
                },
              },
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: -90,
                        size: 140,
                        width: 15,
                        value: 60,
                        color: "amber darken-4",
                      },
                    },
                    [
                      _c("p", { staticClass: "pStyle" }, [
                        _vm._v(_vm._s(_vm.match60) + " "),
                      ]),
                    ]
                  ),
                  _c("p", { staticStyle: { color: "#FF6F00" } }, [
                    _vm._v(" Score: <=60 e >40 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "btnSyle",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.caricaMatch(2)
                },
              },
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: -90,
                        size: 140,
                        width: 15,
                        value: 40,
                        color: "#ff0000",
                      },
                    },
                    [
                      _c("p", { staticClass: "pStyle" }, [
                        _vm._v(_vm._s(_vm.match40) + " "),
                      ]),
                    ]
                  ),
                  _c("p", { staticStyle: { color: "#ff0000" } }, [
                    _vm._v(" Score: <= 40"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "btnSyle",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.caricaMatch(3)
                },
              },
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: -90,
                        size: 140,
                        width: 15,
                        value: 360,
                        color: "blue",
                      },
                    },
                    [
                      _c("p", { staticClass: "pStyle" }, [
                        _vm._v(
                          _vm._s(_vm.match40 + _vm.match60 + _vm.match90) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c("p", { staticStyle: { color: "#ff0000" } }, [
                    _vm._v(" Ricerca libera su tutti i match"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }