import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import match from './modules/match';
import superM from './modules/super';
import anticipo from './modules/anticipo';


// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    match,
    anticipo,
    superM
  },
  plugins: [createPersistedState()]
});

