var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticStyle: { "min-height": "30px" } }),
      _vm.movimentoSelezionato
        ? _c(
            "v-row",
            { staticClass: "datiStyle", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h6 text--primary",
                      staticStyle: { "min-height": "100px" },
                    },
                    [_vm._v(" Scegli Ragione Sociale ")]
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: { "margin-top": "30px", height: "80px" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { id: "btnConfermRS" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confermaRS.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "25" } }, [
                            _vm._v("mdi-checkbox-marked-circle"),
                          ]),
                          _c("span", [_vm._v(" Conferma Attuale")]),
                        ],
                        1
                      ),
                      _c("v-autocomplete", {
                        staticStyle: {
                          "margin-left": "100px",
                          "padding-right": "10px",
                          "padding-left": "15px",
                        },
                        attrs: {
                          items: _vm.stakeholders,
                          "item-text": _vm.getItemText,
                          "item-value": "sapid",
                          label: "Scegli",
                          dense: "",
                        },
                        model: {
                          value: _vm.ragioneSocialeSelezionata,
                          callback: function ($$v) {
                            _vm.ragioneSocialeSelezionata = $$v
                          },
                          expression: "ragioneSocialeSelezionata",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { id: "btnCambiaRS" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.cambiaRS.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "25" } }, [
                            _vm._v("mdi-checkbox-marked-circle"),
                          ]),
                          _c("span", [_vm._v(" Scegli Nuova ")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }