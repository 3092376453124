var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c("br"),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            "text-align": "right",
            margin: "10px",
            "padding-right": "100px",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "btnDissociaSAPStyle",
              attrs: { elevation: "0" },
              on: { click: _vm.toggleModal },
            },
            [
              _c("span", { staticStyle: { color: "white" } }, [
                _vm._v("Cancella Match"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("DetailMatch", {
        attrs: { origin: false, idVal: this.$route.params.id },
        on: {
          openDoc: function ($event) {
            return _vm.openDoc($event)
          },
          openMovimento: function ($event) {
            return _vm.openMovimento($event)
          },
        },
      }),
      _vm.showModal
        ? _c(
            "div",
            [
              _c(
                "PopUpClose",
                { attrs: { theme: "Dissocia" } },
                [
                  _c("div", [_c("h3", [_vm._v("Attenzione!")])]),
                  _c("p", [
                    _vm._v(
                      "Per Dissociare il match occorre effettuare la procedura su SAP"
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0", exact: "" },
                      on: { click: _vm.deleteMatch },
                    },
                    [_vm._v("ok")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnAnnullaStyle",
                      attrs: { elevation: "0" },
                      on: { click: _vm.toggleModal },
                    },
                    [_vm._v("annulla")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showModalDoc
        ? _c(
            "div",
            [
              _c(
                "DocOpened",
                {
                  attrs: { idDoc: _vm.idDoc, riferimento: _vm.riferimento },
                  on: { close: _vm.openDoc },
                },
                [
                  _c("div", [_vm._v(" prova ")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnOkStyle",
                      attrs: { elevation: "0" },
                      on: { click: _vm.openDoc },
                    },
                    [_vm._v("ok")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PopUpMovimento", {
        attrs: {
          movimentoId: _vm.movimentoPopup,
          showModal: _vm.showModalMovimento,
        },
        on: {
          openDoc: function ($event) {
            return _vm.openDoc($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }