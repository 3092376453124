var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-container", [
        _c("table", { staticStyle: { "margin-top": "20px", width: "100%" } }, [
          _c("tr", { staticStyle: { width: "100%" } }, [
            _c(
              "td",
              { staticStyle: { width: "70%" } },
              [_c("SelectAnticipi", { attrs: { chiusi: true } })],
              1
            ),
          ]),
        ]),
        _c(
          "table",
          { staticStyle: { "margin-left": "18px", "margin-top": "20px" } },
          [
            _c(
              "tr",
              [
                _c(
                  "td",
                  [
                    _c("v-select", {
                      staticStyle: {
                        "margin-top": "10px",
                        "min-width": "300px",
                      },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        dense: "",
                        items: _vm.statiProcesso,
                        "item-text": "description",
                        "item-value": "code",
                        label: "Stato",
                      },
                      model: {
                        value: _vm.statoProcesso,
                        callback: function ($$v) {
                          _vm.statoProcesso = $$v
                        },
                        expression: "statoProcesso",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", { staticStyle: { "min-width": "100px" } }),
                _c(
                  "td",
                  { staticStyle: { "max-width": "180px" } },
                  [
                    _c(
                      "v-menu",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          clearable: "",
                                          "prepend-icon": "mdi-calendar",
                                          hint: "DD/MM/YYYY format",
                                          "persistent-hint": "",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.dataDA = _vm.parseDate(
                                              _vm.dataDAvalue
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "0.8em",
                                                      },
                                                    },
                                                    [_vm._v("Da Data Chiusura")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.dataDAvalue,
                                          callback: function ($$v) {
                                            _vm.dataDAvalue = $$v
                                          },
                                          expression: "dataDAvalue",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.menu2,
                          callback: function ($$v) {
                            _vm.menu2 = $$v
                          },
                          expression: "menu2",
                        },
                      },
                      [
                        _c("v-date-picker", {
                          on: {
                            input: function ($event) {
                              _vm.menu2 = false
                            },
                          },
                          model: {
                            value: _vm.dataDA,
                            callback: function ($$v) {
                              _vm.dataDA = $$v
                            },
                            expression: "dataDA",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticStyle: { "max-width": "180px" } },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          clearable: "",
                                          label: "A Data Chiusura",
                                          "prepend-icon": "mdi-calendar",
                                          hint: "DD/MM/YYYY format",
                                          "persistent-hint": "",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.dataA = _vm.parseDate(
                                              _vm.dataAvalue
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "0.8em",
                                                      },
                                                    },
                                                    [_vm._v("A Data Chiusura")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.dataAvalue,
                                          callback: function ($$v) {
                                            _vm.dataAvalue = $$v
                                          },
                                          expression: "dataAvalue",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.menu1,
                          callback: function ($$v) {
                            _vm.menu1 = $$v
                          },
                          expression: "menu1",
                        },
                      },
                      [
                        _c("v-date-picker", {
                          on: {
                            input: function ($event) {
                              _vm.menu1 = false
                            },
                          },
                          model: {
                            value: _vm.dataA,
                            callback: function ($$v) {
                              _vm.dataA = $$v
                            },
                            expression: "dataA",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tooltip",
                  {
                    staticStyle: { "max-width": "500px" },
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-3 mt-4",
                                    attrs: { size: "30" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-help-circle-outline")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "body-1",
                        staticStyle: { "max-width": "500px" },
                      },
                      [
                        _vm._v(
                          " La data ricercata si riferisce alla data del movimento. "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-right ml-5 mr-9 mt-5",
                    attrs: { medium: "" },
                    on: { click: _vm.reloadMatches },
                  },
                  [_vm._v(" Reload Match ")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "box, containerStyle" }, [
          _c(
            "table",
            { staticStyle: { display: "table", width: "100%" } },
            [
              !_vm.matchesVisualizzati || _vm.matchesVisualizzati.length == 0
                ? _c("div", [
                    _c("p", [
                      _c("i", [
                        _vm._v(
                          "Nessun match trovato per i parametri di ricerca."
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.matchesVisualizzati, function (match) {
                return _c("div", { key: match.idmatch, staticClass: "match" }, [
                  _c(
                    "tr",
                    { staticStyle: { display: "table", width: "100%" } },
                    [
                      _c(
                        "td",
                        { staticStyle: { float: "left", width: "35%" } },
                        [
                          _c("v-icon", { attrs: { size: "26" } }, [
                            _vm._v("mdi-file"),
                          ]),
                          _c("strong", {
                            staticStyle: { "font-size": "15px" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "datiStyle",
                              staticStyle: {
                                width: "100%",
                                "overflow-wrap": "break-word",
                              },
                              attrs: { id: "datiMovimento" },
                            },
                            [
                              _c("strong", [
                                _c("p", [
                                  _vm._v("importo: "),
                                  _c("i", [_vm._v(_vm._s(match.importo))]),
                                ]),
                                _c("p", [
                                  _vm._v("data op: "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateShort")(
                                          match.dataoperazione
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _vm._v("valuta: "),
                                  _c("i", [_vm._v(_vm._s(match.valuta))]),
                                ]),
                              ]),
                              match.is_sottomovimento_con_supermovimento_aggregato
                                ? _c("p", [
                                    _vm._v(
                                      " Importo supermovimento: " +
                                        _vm._s(
                                          _vm.importoSuperMovimento[
                                            match.iddbmovimento
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "overflow-wrap": "break-word",
                                    width: "100%",
                                  },
                                },
                                [
                                  _vm._v("note: "),
                                  _c(
                                    "i",
                                    { attrs: { title: match.notemovimento } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTruncate")(
                                            match.note_movimento,
                                            250,
                                            "..."
                                          )
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            float: "left",
                            "vertical-align": "middle",
                            width: "30%",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: { float: "none" },
                                      attrs: { title: match.id_anticipo },
                                    },
                                    [
                                      _vm._v(
                                        " Stato SAP: " +
                                          _vm._s(_vm.descrizioneMatch(match))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: { float: "none" },
                                      attrs: { title: match.id_anticipo },
                                    },
                                    [
                                      _vm._v(
                                        " Data Chiusura: " +
                                          _vm._s(
                                            _vm._f("formatDateShort")(
                                              match.data_conferma
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: { float: "none" },
                                      attrs: { title: match.note_scoring },
                                    },
                                    [
                                      _vm._v(
                                        " Scoring: " +
                                          _vm._s(match.scoring) +
                                          " " +
                                          _vm._s(
                                            _vm._f("formatTruncate")(
                                              match.note_scoring,
                                              30,
                                              "..."
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                  _c("p", { staticStyle: { float: "none" } }, [
                                    _vm._v(
                                      " Cod Dir Com: " +
                                        _vm._s(
                                          match.code_direzione_commerciale_anticipo
                                        ) +
                                        " Cod Prof Ctr: " +
                                        _vm._s(
                                          _vm._f("formatTruncate")(
                                            match.code_profit_center_anticipo,
                                            30,
                                            "..."
                                          )
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { float: "right", width: "35%" } },
                        [
                          _c("v-icon", { attrs: { size: "26" } }, [
                            _vm._v("mdi-file"),
                          ]),
                          _c(
                            "strong",
                            { staticStyle: { "font-size": "15px" } },
                            [
                              _vm._v(
                                " REGISTRAZIONE ANTICIPO SU CLIENTE: " +
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      match.ragionesociale,
                                      30,
                                      "..."
                                    )
                                  ) +
                                  " - " +
                                  _vm._s(match.sapid)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "datiStyle",
                              attrs: { id: "datiMovimento" },
                            },
                            [
                              _c("strong", [
                                _c("p", [
                                  _vm._v("importo "),
                                  _c("i", [_vm._v(_vm._s(match.importo))]),
                                ]),
                                _c("p", [
                                  _vm._v("valuta "),
                                  _c("i", [_vm._v(_vm._s(match.valuta))]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("hr"),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "90%", margin: "auto" } },
        [
          _c("p", { staticClass: "countStyle" }, [
            _vm._v(
              " Selezionati: " +
                _vm._s(_vm.numero_match) +
                " / Scaricati: " +
                _vm._s(_vm.num_scaricati_match) +
                " / Totali: " +
                _vm._s(_vm.num_tot_match)
            ),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "pagination_button",
              attrs: {
                disabled: _vm.num_scaricati_match === _vm.num_tot_match,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadMoreMatches()
                },
              },
            },
            [_vm._v("Carica altri Match")]
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }