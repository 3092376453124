var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "containerStyle" }, [
      _c("table", { staticStyle: { width: "100%", margin: "10px" } }, [
        _c("h4", [_vm._v("Documenti da verificare")]),
        _c("tr", [
          _c(
            "td",
            {
              staticStyle: {
                width: "100%",
                "border-right": "1px solid rgba(0, 0, 0, 0.342)",
              },
            },
            [
              _c("div", { staticClass: "box" }, [
                _c(
                  "table",
                  { staticStyle: { "margin-top": "30px" } },
                  _vm._l(_vm.documentiNonValidi, function (documento) {
                    return _c("div", { key: documento.id }, [
                      _c("tr", { staticStyle: { width: "100%" } }, [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "8%",
                              "vertical-align": "top",
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "100" } }, [
                              _vm._v("mdi-text-box-remove"),
                            ]),
                          ],
                          1
                        ),
                        _c("td", { staticStyle: { width: "92%" } }, [
                          _c("tr", [
                            _c("h4", [
                              _vm._v(
                                " " + _vm._s(documento.nomefileoriginario) + " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _vm._v(
                              " Data Contenuto: " +
                                _vm._s(
                                  _vm._f("formatDateLong")(
                                    documento.datacontenutodocumento
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("tr", [
                            _vm._v(
                              " Tipo File: " +
                                _vm._s(documento.tipoDocTesto) +
                                " "
                            ),
                          ]),
                          _c("tr", [
                            _vm._v(
                              " Id File: " + _vm._s(documento.idfile) + " "
                            ),
                          ]),
                          documento.notecheck.length > 0
                            ? _c("tr", [
                                _c("span", [
                                  _vm._v(
                                    " Ragione Richiesta: " +
                                      _vm._s(documento.notecheck) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c(
                            "tr",
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "margin-top": "0px",
                                  },
                                },
                                [_vm._v(" Visualizza Documento ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "flexcol",
                                  staticStyle: {
                                    margin: "-5px 5px 0px 5px",
                                    float: "left",
                                  },
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDoc(documento.idfile)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "20" } }, [
                                    _vm._v("mdi-open-in-new"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("td", { staticStyle: { width: "100px" } }, [
                          _c(
                            "tr",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    medium: "",
                                    color: "primary",
                                    dark: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.accetta(documento.idfile)
                                    },
                                  },
                                },
                                [_vm._v(" Accetta ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "tr",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    medium: "",
                                    color: "primary",
                                    dark: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.scarta(documento.idfile)
                                    },
                                  },
                                },
                                [_vm._v(" Scarta ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm.showModalDoc
      ? _c(
          "div",
          [
            _c(
              "DocOpened",
              {
                attrs: { idDoc: _vm.idDoc, theme: "" },
                on: { close: _vm.openDoc },
              },
              [
                _c("div"),
                _c(
                  "v-btn",
                  {
                    staticClass: "btnOkStyle",
                    attrs: { elevation: "0" },
                    on: { click: _vm.openDoc },
                  },
                  [_vm._v("ok")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }