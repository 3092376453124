<template>
<div>
      <v-row class="ml-5">
        <v-col cols="9">
        <SelectSuper :chiusi="false"></SelectSuper>
        </v-col>
        <v-col
          class="d-flex"
          cols="2">
        </v-col>
      </v-row>
  <v-container  class="containerStyle">


<div class="box">  
  <table style="display: table; width:100%">
    <div v-if="matchesVisualizzati.length==0">
         <p> <i>Nessun match trovato per i parametri di ricerca.</i></p>
    </div>

     <div v-for="match in matchesVisualizzati" :key="match.idmatch" class="match">
     
      <tr style="display:table; width:100%">
        
        <td style="float:left; width: 35%;">
            <div style="float:left; width:100%">
              <div v-for="sottomatch in match.matches" :key="'sottomatcho' + String(sottomatch.movimenti[0].iddbmovimento)  + String(sottomatch.movimenti[0].iddbdettagliomovimento)" style="float:left;">
            <div v-for="movimento in sottomatch.movimenti" :key="String(movimento.iddbmovimento)+String(movimento.iddbdettagliomovimento)" id="datiMovimento" class="datiStyle">
          <div id="datiMovimento" class="datiStyle" style="width:100%; overflow-wrap:break-word;margin-left:10px">
            <v-row>
            <v-icon size="26" >mdi-file</v-icon> 
            <strong>
            <p>importo: <i>{{ movimento.valuta_importo}}</i></p>
            <p>data op: <i>{{ movimento.dataoperazione | formatDateShort}}</i></p>
            <p>valuta: <i>{{movimento.valuta}}</i></p>
          </strong>
        </v-row>  
        <v-row>
            <p style="overflow-wrap:break-word; width:100%">note: <i :title=movimento.notemovimento>{{movimento.notemovimento | formatTruncate(250, '...')}} </i></p>
          </v-row>  
            </div>
        </div>
      </div>
    </div>
        
        </td>
        <td style="float:left; vertical-align:middle; width: 30%">
            <router-link :to="{name: 'supermatchassociatedoverall', params: {id: match.idmatch, saldo: match.saldo} }">
                <v-btn :title="`ID: ${match.idmatch} \nscore: ${match.score} \nNote match: ${match.scorepartite}`" class="btnImgStyle" elevation="0"  >
            <img class="imgMatchListStyle" src="../assets/ImgMatchAssociatiLista.png">
          </v-btn>
            </router-link>
          <v-row >
            <v-col  align="center" justify="center">
               <p  style="float:none"> Saldo Cond.Pag.: {{match.differenzamonetizzatainvalutaconimportoscontato}}</p>
            </v-col>
          </v-row>
        </td>
        <td style="float:right; width: 30%">
          <div v-for="sottomatch in match.matches" :key="'altro'+String(sottomatch.movimenti[0].iddbmovimento)+String(sottomatch.movimenti[0].iddbdettagliomovimento)" style="float:left;">
            <div v-for="partita in sottomatch.partite" :key="String(partita.iddb)" id="datiMovimento" class="datiStyle">
              <v-row>
              <v-icon size="26">mdi-playlist-check</v-icon> 
              <strong style="font-size:15px"> {{partita.ragionesociale | formatTruncate(30, '...')}} - {{partita.sapid}}</strong>
            </v-row>
              <v-row>
                <strong>
                <p >importo <i>{{ partita.valutalordo}}</i></p>
                <p >data doc. <i>{{ partita.datadocumento | formatDateShort}}</i></p> 
                <p >valuta <i>{{ partita.valuta}}</i></p>
                <p >ID Partita <i>{{partita.numerodocumento }}</i></p> 
                <p >Stato <i>{{partita.stato }}</i></p> 
              </strong>
            </v-row>
            </div>
            <div v-for="anticipo in sottomatch.anticipi" :key="String(sottomatch.idmatch) + String(anticipo.id)" id="datiMovimento" class="datiStyle">
              <v-row>
              <v-icon size="26">mdi-cash-multiple</v-icon> 
              <strong style="font-size:15px"> {{anticipo.ragionesociale | formatTruncate(30, '...')}} - {{anticipo.sapid}}</strong>
            </v-row>
              <v-row>
                <strong>
                  <p >Importo <i>{{ sottomatch.movimenti[0].valuta_importo}}</i></p>
                  <p >dir.commerciale <i>{{ anticipo.code_direzione_commerciale_anticipo}}</i></p>
                  <p >profit center <i>{{ anticipo.code_profit_center_anticipo}}</i></p> 
              </strong>
            </v-row>
            </div>

         </div>
        </td>
      </tr>
      <hr>
    </div>
   </table>
    
  </div>
  </v-container>
   <div style="width:90%; margin:auto;">
    <p class="countStyle"> Numero saldi 0: {{numero_zeri}} / Numero saldi cond.pag 0: {{numero_zeri_scontati}}</p>
    <br/>
    <p class="countStyle"> Selezionati: {{numero_match}} / Scaricati: {{num_scaricati_match}} / Totali: {{num_tot_match}}</p>
  <v-btn class="pagination_button" @click.prevent="loadMoreMatches()" :disabled="num_scaricati_match===num_tot_match">Carica altri Match</v-btn>
    
  </div>
    <br>
</div>

</template>

<script>
import SelectSuper from "@/components/SelectSuper.vue";

export default {
  name: "SuperMatchList",
    data(){
      return{
         checkedMatch: [],
         sortCondPag: false,
         importoSuperMovimento: "testo"
      }
    },
    components: {
      SelectSuper,
    },
    async mounted() { 
      this.importoSuperMovimento = {}

      this.$store.commit('auth/setIsLoading', false);
    },
    computed: {
        matchesVisualizzati: function() {
          let imatch = structuredClone(this.$store.getters["superM/getMatchesFiltrati"]);
          console.log("matches da visualizzare: ");
          console.log(imatch);
          if(!imatch) return [];
          let _that = this;
          if(this.$store.getters["superM/getIsSorted"]) {
            imatch.sort(function compareFn(a, b) { 
                if(!_that.sortCondPag) {return b.saldo - a.saldo}
                else {return b.differenzamonetizzatainvalutaconimportoscontato - a.differenzamonetizzatainvalutaconimportoscontato}
              });
          } else {
            imatch.sort(function compareFn(a, b) { 
              if(!_that.sortCondPag) {return a.saldo - b.saldo}
              else {return a.differenzamonetizzatainvalutaconimportoscontato - b.differenzamonetizzatainvalutaconimportoscontato}
              });
          }
          return imatch;
        },      
        numero_match: function() {
          return this.matchesVisualizzati.length;
        },
        numero_zeri: function() {
          if(this.matchesVisualizzati) {
            return this.matchesVisualizzati.filter(match => (Number(match.saldo) === 0)).length;
          } else {
            return 0;
          }
        },
        numero_zeri_scontati: function() {
          if(this.matchesVisualizzati) {
            return this.matchesVisualizzati.filter(match => (Number(match.differenzamonetizzatainvalutaconimportoscontato) === 0)).length;
          } else {
            return 0;
          }
        },
        num_tot_match: function() {
          return (this.$store.getters["superM/getMatches"]) ? this.$store.getters["superM/getMatches"].total : 0;
        },
        num_scaricati_match: function() {
          return (this.$store.getters["superM/getMatches"]) ? this.$store.getters["superM/getMatches"].size : 0;
        },
        sortDirection: function() {
          if(this.$store.getters["superM/getIsSorted"]) {
            return "Sort Discendente";
          } else {
            return "Sort Crescente";
          }
        }
    },
    methods:{
        async loadMoreMatches(){
          console.log("loading more");
          let params = new URLSearchParams();
          await this.$store.dispatch("superM/LoadMoreMatches", {params: params, soloAperti:true});
          this.matchesOrdinati = structuredClone(this.$store.getters["superM/getMatchesFiltrati"]);
        },
        cambiaSort() {
          let sortUp = this.$store.getters["superM/getIsSorted"];
          console.log("cambia sort da: " + sortUp);
          this.$store.commit('superM/setIsSorted', !sortUp);
        },
        differenzaMovimentiMenoPartite(match) {
          let sommaMovimenti = 0;
          match.movimenti.forEach(function(movimento) {
              if (movimento.valuta_importo) {
                  sommaMovimenti += movimento.valuta_importo;
              }
          })
          let sommaPartite = 0;
          match.partite.forEach(function(partita) {
              if (partita.valutalordo) {
                  sommaPartite += partita.valutalordo;
              }
          })
          match.saldo = sommaMovimenti-sommaPartite;
          return sommaMovimenti-sommaPartite;
        },
        check(id){
          if(document.getElementById(id).checked){
            console.log("aggiungo tra i match da chiudere:" + id);
            this.checkedMatch.push(id);
            console.log(this.checkedMatch)
          }
          else if(!document.getElementById(id).checked){
            console.log("tolgo tra i match da chiudere:" + id);
            this.checkedMatch.splice(this.checkedMatch.indexOf(id ), 1);
            console.log(this.checkedMatch)
          }
        }
    }
}
</script>

<style scoped>
p {
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
}
.box{
  padding: 4px;
  overflow:auto;
  height: 520px;
}
.pagination_button {
    float: left;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize;
    background-color: rgba(203, 197, 197, 0.242) !important;
    padding: 0px 5px !important;
    border-radius: 5px;
   /* font-family: "'EB Garamond SC',ebGaramond12SC_WGDI,'Times New Roman',serif";*/
}
.countStyle{
  float:left;
  font-size:14px; 
}


.containerStyle{
    border: 1px solid rgb(0, 0, 0);
    background-color: rgba(203, 197, 197, 0.242);
    margin-top: 10px;
    
}

.cardStyle{
    margin: auto !important;
}
.datiStyle{
    font-size: 14px;
    margin-top:10px;
    margin-bottom: 10px;
}
.imgMatchListStyle{
    max-width: 60%;
    vertical-align: middle ;
}
.btnImgStyle{
    background-color: transparent !important;
    width: 100%;
}
.match{
  margin-top:5px;
  margin-bottom: 15px;
  width:100%;
}

</style>