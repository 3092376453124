var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loginView" }, [
    _c("div", { staticClass: "center" }, [
      _c("img", {
        staticStyle: { margin: "auto", width: "90%" },
        attrs: { src: require("../assets/Logo_Loro-Piana.png") },
      }),
      _c(
        "p",
        {
          staticStyle: {
            "text-align": "center",
            "margin-top": "20px",
            "font-style": "italic",
            "font-size": "14px",
          },
        },
        [_vm._v("LOGIN")]
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c("v-icon", { staticClass: "icoStyle" }, [
                  _vm._v("mdi-account"),
                ]),
                _c(
                  "label",
                  {
                    staticStyle: { "font-size": "18px" },
                    attrs: { for: "username" },
                  },
                  [_vm._v("Username:")]
                ),
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.username,
                  expression: "form.username",
                },
              ],
              staticClass: "input",
              attrs: { value: "prova", type: "text", name: "username" },
              domProps: { value: _vm.form.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "username", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c("v-icon", { staticClass: "icoStyle" }, [_vm._v("mdi-lock")]),
                _c(
                  "label",
                  {
                    staticStyle: { "font-size": "18px" },
                    attrs: { for: "password" },
                  },
                  [_vm._v("Password:")]
                ),
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.password,
                  expression: "form.password",
                },
              ],
              staticClass: "input",
              attrs: { type: "password", name: "password" },
              domProps: { value: _vm.form.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "password", $event.target.value)
                },
              },
            }),
          ]),
          _vm._m(0),
        ]
      ),
      _vm.showError
        ? _c("p", { attrs: { id: "error" } }, [
            _vm._v("Username or Password is incorrect"),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "20px" } },
      [
        _c(
          "button",
          { staticClass: "btnSubmitStyle", attrs: { type: "submit" } },
          [_vm._v("Submit")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }