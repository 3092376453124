var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            width: "800",
            padding: "10",
            color: "grey",
            "overlay-color": "grey",
            "overlay-opacity": "0.5",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            {
              staticStyle: {
                background: "white",
                "background-color": "rgba(215, 214, 214)",
              },
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "text-align": "center",
                    "line-height": "50px",
                    "margin-bottom": "0px",
                  },
                },
                [
                  _vm._v(
                    " Codice Piteco Super Movimento: " +
                      _vm._s(_vm.movimento.codice_piteco) +
                      " "
                  ),
                ]
              ),
            ]
          ),
          _c("v-card", { staticClass: "cardStyle" }, [
            _c(
              "table",
              {
                staticStyle: {
                  width: "90%",
                  "margin-left": "5%",
                  "margin-right": "5%",
                },
              },
              [
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [_vm._v("Data:")]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateShort")(_vm.movimento.data))
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("Riferimento Piteco:"),
                    ]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.codice_piteco)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("Divisa/Cambio"),
                    ]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.valuta)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v(
                        "Importo in " + _vm._s(_vm.movimento.valuta) + ": "
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.valuta_importo)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("Importo in EUR: "),
                    ]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.eur_importo)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [_vm._v("Conto:")]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.conto)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: 2 } }, [
                    _c("hr", {
                      staticStyle: { "margin-bottom": "5px", width: "100%" },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("ID Movimento"),
                    ]),
                  ]),
                  _c("td", [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.id_movimento_bancario)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("ID Documento"),
                    ]),
                  ]),
                  _c("td", [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(_vm._s(_vm.movimento.id_documento)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v("Sap ID cliente:"),
                    ]),
                  ]),
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "dataStyle" }, [
                      _vm._v(" " + _vm._s(_vm.movimento.sapid)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "cell" }, [
                    _c("p", { staticClass: "pStyle" }, [
                      _vm._v(" Note Movimento:"),
                    ]),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "cell CellWithAevoTooltip",
                      attrs: { colspan: 2 },
                    },
                    [
                      _c("span", { staticClass: "AevoTooltip" }, [
                        _vm._v(_vm._s(_vm.movimento.note)),
                      ]),
                      _c("p", { staticClass: "dataStyle" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatTruncate")(
                              _vm.movimento.note,
                              15,
                              "..."
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      { staticStyle: { float: "left", "margin-top": "7px" } },
                      [_vm._v("Visualizza Documento")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "flexcol",
                        staticStyle: { margin: "0px 5px 0px 5px" },
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openDoc(_vm.movimento.id_documento)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("mdi-open-in-new"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }