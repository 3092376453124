var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.matches.items, function (match) {
      return _c("div", { key: match.idmatch, staticClass: "match" }, [
        match.idmatch == _vm.idVal
          ? _c(
              "div",
              _vm._l(match.partite, function (partita) {
                return _c(
                  "div",
                  { key: String(match.idmatch) + String(partita.iddb) },
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: {
                          "background-color": "rgba(215, 214, 214, 0.751)",
                          "margin-top": "20px",
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "line-height": "50px",
                              "margin-bottom": "3px",
                            },
                          },
                          [
                            _vm._v(
                              " Numero Doc. " +
                                _vm._s(partita.numerodocumento) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("v-card", { staticClass: "cardStyle" }, [
                      _c("table", { staticStyle: { width: "100%" } }, [
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Data scadenza:"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateShort")(
                                      partita.datascadenza
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Data documento:"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateShort")(
                                      partita.datadocumento
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Divisa:"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.valuta)),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Sap ID"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.sapid)),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Ragione Sociale"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.ragionesociale)),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Stato"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.stato)),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v(
                                  "Importo in valuta (" +
                                    _vm._s(partita.valuta) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.valutalordo)),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Importo in EUR"),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncateDecimal")(
                                      partita.eurlordo,
                                      2
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Sconto in valuta "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(_vm.sconto(match, partita))),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Sconto in valuta% "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncateDecimal")(
                                      (100 * _vm.sconto(match, partita)) /
                                        partita.valutalordo,
                                      2
                                    )
                                  ) + " % "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Note SAP "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "cell CellWithAevoTooltip",
                              attrs: { colspan: 3 },
                            },
                            [
                              _c("span", { staticClass: "AevoTooltip" }, [
                                _vm._v(_vm._s(partita.testo_partita)),
                              ]),
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      partita.testo_partita
                                        ? partita.testo_partita
                                        : " ",
                                      20,
                                      "..."
                                    )
                                  ) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Codice CondPag "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(partita.codice_condizioni_pagamento) +
                                    "  "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("Desc CondPag "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "cell CellWithAevoTooltip",
                              attrs: { colspan: 3 },
                            },
                            [
                              _c("span", { staticClass: "AevoTooltip" }, [
                                _vm._v(
                                  _vm._s(
                                    partita.descrizione_condizioni_pagamento
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTruncate")(
                                      partita.descrizione_condizioni_pagamento,
                                      20,
                                      "..."
                                    )
                                  ) + "  "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "pStyle" }, [
                                _vm._v("ID Partita "),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "cell", attrs: { colspan: 3 } },
                            [
                              _c("p", { staticClass: "dataStyle" }, [
                                _vm._v(_vm._s(partita.iddb)),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: { float: "left", "margin-top": "7px" },
                          },
                          [_vm._v("Visualizza Documento")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "flexcol",
                            staticStyle: { margin: "0px 5px 0px 5px" },
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openDoc(
                                  partita.numerodocumento,
                                  partita.iddocumento
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("mdi-open-in-new"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }