var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "topnav", attrs: { id: "myTopnav" } },
        [
          _vm._m(0),
          _c(
            "a",
            {
              staticClass: "icon",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.myFunction()
                },
              },
            },
            [_vm._v("☰")]
          ),
          _vm._l(_vm.menu, function (item) {
            return _c(
              "v-btn",
              {
                key: item.icon,
                staticClass: "menulink",
                attrs: {
                  to: { name: item.route },
                  text: "",
                  title: item.title,
                },
              },
              [
                _c("p", { staticClass: "menunameStyle" }, [
                  _vm._v(_vm._s(item.title) + " "),
                ]),
              ]
            )
          }),
          _c(
            "v-btn",
            {
              staticClass: "menulink",
              attrs: { text: "", title: "Run Matcher" },
              on: { click: _vm.runMatcher },
            },
            [
              _c("p", { staticClass: "menunameStyle" }, [
                _vm._v("Run Matcher "),
              ]),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "flexcol",
              staticStyle: { margin: "10px 10px 5px 0px", float: "right" },
              on: {
                click: function ($event) {
                  return _vm.logOut()
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("mdi-logout")]),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-bottom": "0px !important",
                    "font-size": "10px",
                  },
                },
                [_vm._v("Logout")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flexcol",
              staticStyle: { margin: "10px 10px 5px 0px", float: "right" },
            },
            [
              _c("v-icon", { attrs: { size: "35" } }, [
                _vm._v("mdi-account-circle"),
              ]),
              _c("span", [
                _vm._v(
                  " " + _vm._s(this.$store.getters["auth/StateUser"]) + " "
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("v-breadcrumbs", {
        staticClass: "breadcrumbs",
        attrs: { items: _vm.$route.meta.items, divider: "|" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/home", title: "Home v0.5.0" } }, [
      _c("img", {
        staticClass: "logoStyle",
        attrs: { src: require("../assets/Logo_Loro-Piana.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }