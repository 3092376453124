import { render, staticRenderFns } from "./TuttePartiteView.vue?vue&type=template&id=6c6dc8e2&scoped=true&"
import script from "./TuttePartiteView.vue?vue&type=script&lang=js&"
export * from "./TuttePartiteView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6dc8e2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VContainer,VIcon,VRow,VSnackbar,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/dev/LoroPianaRiconciliatoreFrontEnd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c6dc8e2')) {
      api.createRecord('6c6dc8e2', component.options)
    } else {
      api.reload('6c6dc8e2', component.options)
    }
    module.hot.accept("./TuttePartiteView.vue?vue&type=template&id=6c6dc8e2&scoped=true&", function () {
      api.rerender('6c6dc8e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/TuttePartiteView.vue"
export default component.exports