var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-container", { staticClass: "containerStyle" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "table",
              { staticStyle: { display: "table", width: "100%" } },
              [
                _vm.matchesVisualizzati.length == 0
                  ? _c("div", [
                      _c("p", [
                        _c("i", [
                          _vm._v(
                            "Nessun match trovato per i parametri di ricerca."
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.matchesVisualizzati, function (match) {
                  return _c(
                    "div",
                    { key: match.idmatch, staticClass: "match" },
                    [
                      _c(
                        "tr",
                        { staticStyle: { display: "table", width: "100%" } },
                        [
                          _c(
                            "td",
                            { staticStyle: { float: "left", width: "35%" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { float: "left", width: "100%" },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "26" } }, [
                                    _vm._v("mdi-file"),
                                  ]),
                                  _c("strong", {
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "datiStyle",
                                      staticStyle: {
                                        width: "100%",
                                        "overflow-wrap": "break-word",
                                      },
                                      attrs: { id: "datiMovimento" },
                                    },
                                    [
                                      _c("strong", [
                                        _c("p", [
                                          _vm._v("importo: "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                match.movimenti[0]
                                                  .valuta_importo
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("data op: "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateShort")(
                                                  match.movimenti[0]
                                                    .dataoperazione
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("valuta: "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(match.movimenti[0].valuta)
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "overflow-wrap": "break-word",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _vm._v("note: "),
                                          _c(
                                            "i",
                                            {
                                              attrs: {
                                                title:
                                                  match.movimenti[0]
                                                    .notemovimento,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatTruncate")(
                                                    match.movimenti[0]
                                                      .notemovimento,
                                                    250,
                                                    "..."
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                float: "left",
                                "vertical-align": "middle",
                                width: "30%",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btnImgStyle",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c("img", {
                                    staticClass: "imgMatchListStyle",
                                    attrs: {
                                      src: require("../assets/ImgMatchAssociatiLista.png"),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { float: "right", width: "35%" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "margin-left": "90%" },
                                  attrs: { color: "red lighten-2", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSubmovimento(match)
                                    },
                                  },
                                },
                                [_vm._v(" Edit ")]
                              ),
                              _vm._l(match.partite, function (partita) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      String(match.idmatch) +
                                      String(partita.iddb),
                                    staticStyle: { float: "left" },
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "26" } }, [
                                      _vm._v("mdi-playlist-check"),
                                    ]),
                                    _c(
                                      "strong",
                                      { staticStyle: { "font-size": "15px" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatTruncate")(
                                                partita.ragionesociale,
                                                30,
                                                "..."
                                              )
                                            ) +
                                            " - " +
                                            _vm._s(partita.sapid)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "datiStyle",
                                        attrs: { id: "datiMovimento" },
                                      },
                                      [
                                        _c("strong", [
                                          _c("p", [
                                            _vm._v("importo "),
                                            _c("i", [
                                              _vm._v(
                                                _vm._s(partita.valutalordo)
                                              ),
                                            ]),
                                          ]),
                                          _c("p", [
                                            _vm._v("data doc. "),
                                            _c("i", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDateShort")(
                                                    partita.datadocumento
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", [
                                            _vm._v("valuta "),
                                            _c("i", [
                                              _vm._v(_vm._s(partita.valuta)),
                                            ]),
                                          ]),
                                          _c("p", [
                                            _vm._v("ID Partita "),
                                            _c("i", [
                                              _vm._v(
                                                _vm._s(partita.numerodocumento)
                                              ),
                                            ]),
                                          ]),
                                          _c("p", [
                                            _vm._v("Stato "),
                                            _c("i", [
                                              _vm._v(_vm._s(partita.stato)),
                                            ]),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "td",
                            { staticStyle: { float: "right", width: "35%" } },
                            _vm._l(match.anticipi, function (anticipo) {
                              return _c(
                                "div",
                                {
                                  key:
                                    String(match.idmatch) + String(anticipo.id),
                                  staticStyle: { float: "left" },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "26" } }, [
                                    _vm._v("mdi-cash-multiple"),
                                  ]),
                                  _c(
                                    "strong",
                                    { staticStyle: { "font-size": "15px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatTruncate")(
                                              anticipo.ragionesociale,
                                              30,
                                              "..."
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(anticipo.sapid)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "datiStyle",
                                      attrs: { id: "datiMovimento" },
                                    },
                                    [
                                      _c("strong", [
                                        _c("p", [
                                          _vm._v("Importo "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                match.movimenti[0]
                                                  .valuta_importo
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("dir.commerciale "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                anticipo.code_direzione_commerciale_anticipo
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v("profit center "),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                anticipo.code_profit_center_anticipo
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c("hr"),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", width: "900" },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "text-h5 grey lighten-2" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.isAnticipo,
                                  callback: function ($$v) {
                                    _vm.isAnticipo = $$v
                                  },
                                  expression: "isAnticipo",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: { label: "Anticipo", value: true },
                                }),
                                _c("v-radio", {
                                  attrs: { label: "Partita", value: false },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-left": "50%",
                                  "margin-top": "15px",
                                },
                              },
                              [
                                _vm._v(
                                  "Saldo: " +
                                    _vm._s(_vm.saldoSottoMovimento) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isAnticipo && _vm.currentSubMatch
                      ? _c(
                          "v-card-text",
                          [
                            _c("v-text-field", {
                              staticStyle: {
                                width: "90%",
                                "padding-top": "20px",
                                margin: "auto",
                              },
                              attrs: {
                                disabled: "",
                                label: "Importo",
                                value:
                                  _vm.currentSubMatch.movimenti[0]
                                    .valuta_importo,
                              },
                            }),
                            _c("v-autocomplete", {
                              staticStyle: { width: "90%", margin: "auto" },
                              attrs: {
                                items: _vm.stakeholders,
                                "item-text": _vm.getItemText,
                                "item-value": "sapid",
                                label: "Cliente",
                                dense: "",
                              },
                              model: {
                                value: _vm.currentSubMatch.anticipi[0].sapid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentSubMatch.anticipi[0],
                                    "sapid",
                                    $$v
                                  )
                                },
                                expression: "currentSubMatch.anticipi[0].sapid",
                              },
                            }),
                            _c("v-select", {
                              staticStyle: { width: "90%", margin: "auto" },
                              attrs: {
                                items: _vm.direzioniCommerciali,
                                "item-text": "description",
                                "item-value": "code",
                                label: "Direzione Commerciale",
                              },
                              model: {
                                value:
                                  _vm.currentSubMatch.anticipi[0]
                                    .code_direzione_commerciale_anticipo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentSubMatch.anticipi[0],
                                    "code_direzione_commerciale_anticipo",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentSubMatch.anticipi[0].code_direzione_commerciale_anticipo",
                              },
                            }),
                            _c("v-select", {
                              staticStyle: { width: "90%", margin: "auto" },
                              attrs: {
                                items: _vm.profitCenters,
                                "item-text": "description",
                                "item-value": "code",
                                label: "Profit Center",
                              },
                              model: {
                                value:
                                  _vm.currentSubMatch.anticipi[0]
                                    .code_profit_center_anticipo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentSubMatch.anticipi[0],
                                    "code_profit_center_anticipo",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentSubMatch.anticipi[0].code_profit_center_anticipo",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isAnticipo && _vm.currentSubMatch.movimenti
                      ? _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              { staticStyle: { "margin-top": "20px" } },
                              [
                                _c("v-autocomplete", {
                                  staticStyle: {
                                    "max-width": "70%",
                                    "margin-left": "3%",
                                  },
                                  attrs: {
                                    items: _vm.stakeholders,
                                    "item-text": _vm.getItemText,
                                    "item-value": "sapid",
                                    label: "Cliente",
                                    dense: "",
                                    disabled: _vm.cambiaClienteEnabled === 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.ricaricaPartite()
                                    },
                                  },
                                  model: {
                                    value: _vm.currentSubMatch.sapid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.currentSubMatch,
                                        "sapid",
                                        $$v
                                      )
                                    },
                                    expression: "currentSubMatch.sapid",
                                  },
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { "margin-left": "60px" },
                                    attrs: { color: "red lighten-2", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cambiaCliente(_vm.match)
                                      },
                                    },
                                  },
                                  [_vm._v(" Cambia Cliente ")]
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.currentSubMatch.partite,
                              function (partita) {
                                return _c(
                                  "v-row",
                                  { key: partita.numerodocumento },
                                  [
                                    _c("v-text-field", {
                                      staticStyle: {
                                        "max-width": "30%",
                                        "padding-top": "20px",
                                        "margin-left": "3%",
                                      },
                                      attrs: {
                                        disabled: "",
                                        label: "Importo",
                                        value: partita.valutalordo,
                                      },
                                    }),
                                    _c("v-text-field", {
                                      staticStyle: {
                                        "max-width": "10%",
                                        "padding-top": "20px",
                                        "margin-left": "3%",
                                      },
                                      attrs: {
                                        disabled: "",
                                        label: "Valuta",
                                        value: partita.valuta,
                                      },
                                    }),
                                    _c("v-text-field", {
                                      staticStyle: {
                                        "max-width": "10%",
                                        "padding-top": "20px",
                                        "margin-left": "3%",
                                      },
                                      attrs: {
                                        disabled: "",
                                        label: "Data",
                                        value: _vm.formattaData(
                                          partita.datadocumento
                                        ),
                                      },
                                    }),
                                    _c("v-text-field", {
                                      staticStyle: {
                                        "max-width": "10%",
                                        "padding-top": "20px",
                                        "margin-left": "3%",
                                      },
                                      attrs: {
                                        disabled: "",
                                        label: "ID",
                                        value: partita.numerodocumento,
                                      },
                                    }),
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: {
                                          "margin-top": "15px",
                                          "margin-left": "6%",
                                        },
                                        attrs: {
                                          color: "red lighten-2",
                                          dark: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rimuoviPartita(partita)
                                          },
                                        },
                                      },
                                      [_vm._v(" Rimuovi ")]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-autocomplete", {
                                  staticStyle: {
                                    "max-width": "70%",
                                    "margin-left": "3%",
                                  },
                                  attrs: {
                                    items: _vm.partiteAttive,
                                    "item-text": _vm.getPartitaText,
                                    label: "Partita",
                                    dense: "",
                                    "return-object": "",
                                  },
                                  model: {
                                    value: _vm.partitaSelezionata,
                                    callback: function ($$v) {
                                      _vm.partitaSelezionata = $$v
                                    },
                                    expression: "partitaSelezionata",
                                  },
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "margin-top": "15px",
                                      "margin-left": "6%",
                                    },
                                    attrs: { color: "red lighten-2", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.aggiungiPartita()
                                      },
                                    },
                                  },
                                  [_vm._v(" Aggiungi ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelModifica()
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.finitaModifica()
                              },
                            },
                          },
                          [_vm._v(" I accept ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-row",
        [
          _vm.theSuperMatch.partite_di_pareggio
            ? _c(
                "v-card",
                {
                  staticStyle: {
                    "background-color": "rgba(215, 214, 214, 0.751)",
                    border: "1px solid rgba(3, 3, 3, 0.17)",
                    "margin-top": "50px",
                    width: "60%",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "line-height": "30px",
                        "margin-bottom": "3px",
                      },
                    },
                    [
                      _vm._v(
                        " Partite di Pareggio " +
                          _vm._s(
                            _vm.currentSubMatch.code_stato_trasferimento_match
                              ? ""
                              : "proposte dal Matcher "
                          ) +
                          ". Saldo +movimenti -partite parziale: " +
                          _vm._s(_vm.currentSubMatch.saldo) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(
        _vm.theSuperMatch.partite_di_pareggio,
        function (differenzaPartite) {
          return _c(
            "div",
            {
              key: differenzaPartite,
              staticStyle: {
                "border-radius": "3px",
                border: "1px solid rgba(3, 3, 3, 0.17)",
                "background-color": "#ffffffcc",
                width: "60%",
                "margin-top": "5px",
                "margin-left": "auto",
                "margin-right": "auto",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("Conto:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(_vm.nomeConto(differenzaPartite.cod))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("ID Partita:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(
                                  differenzaPartite.id_partita
                                    ? differenzaPartite.id_partita
                                    : "Nessuna Partita Associata"
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-row", [
                            _c("p", { staticClass: "pStyle1" }, [
                              _vm._v("Importo:"),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.segnoPerCodice(differenzaPartite.cod)
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "dataStyle1" }, [
                              _vm._v(
                                _vm._s(differenzaPartite.importo) +
                                  " " +
                                  _vm._s(
                                    differenzaPartite.cod === 2 ||
                                      differenzaPartite.cod === 8
                                      ? "EUR"
                                      : _vm.theSuperMatch.matches[0]
                                          .movimenti[0].valuta
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
      _c("br"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }